.wha-container {
	width: 85vw;
	#chart-wha1 {
		md-card {
			md-card-content {
				padding: 0;
			}
		}
	}

#chart-wha1{

	padding-bottom: 10px;
	md-content {overflow-y: visible; }
}	
	md-card {
		md-toolbar {
			text-align: center;
		}
		table.table {
			margin-bottom: 0;
			.table-heading {
					text-transform: capitalize;
					text-align: center !important;
					span.unit {text-transform: lowercase;}
			}
			tr {
				text-align: center;
			}
		}
	}
}