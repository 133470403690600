// .md-select-value {
//     margin: 11px 0 0 6px;
//     font-style: normal;
//     /* letter-spacing: 1px; */
//     color: lightblue !important;
//     /* text-shadow: 1px 1px 1px whitesmoke; */
//     font-weight: bolder;
//     border-color: white;
//     padding: 0;
// }

.as-dashboard-container {
    margin: 0;
    // height: 100%;
    md-toolbar {
        margin: 0;
        // height:40px;
    }
    md-card {
        overflow: hidden;
        max-height: 35vh;
    }
    .md-off {
        background-color: $white;
        color: $white;
    }
    .md-on {
        // background-color: $white;
        color: $white;
        border-color: $blue;
    }
    .chart-filled {
        fill: steelblue;
    }
    .chart-empty {
        fill: green;
    }
    .dc_current {
        h4,
        .flow-unit {
            background-color: $orange !important;
        }
    }
    md-card {
        // box-shadow: none;
        // float: le
    }
    md-card iframe {
        height: 44vh; // margin-top: -18px;
    }
    md-card iframe.controlled-frame {
        height: 44vh;
        width: 100%;
        margin-top: 0px;
    }
    .unit-symbol {
        vertical-align: -webkit-baseline-middle; // color:black;
    }
    .positive-symbol {
        font-size: calc(0.8rem + 1vw);
        vertical-align: middle;
    }
    .single-icon {
        position: absolute;
        color: white;
        left: 85%;
        top: 8.4vmin;
        font-size: 16px;
    }
    .as-card {
        text-align: center;
        .bg-0,
        .bg-7 {
            background-color: #4f575a;
            opacity: .95;
        }
        .bg-2,
        .bg-5 {
            background-color: #4f575a;
            opacity: .95;
        }
        .bg-3,
        .bg-4 {
            background-color: #4f575a;
            opacity: .95;
        }
        .bg-6,
        .bg-1 {
            background-color: #4f575a;
            opacity: .95;
        }
        &.short {
            .bg-0 {
                background-color: #4f575a;
                opacity: .95;
            }
            .bg-1 {
                background-color: #4f575a;
                opacity: .95;
            }
            .bg-2 {
                background-color: #4f575a;
                opacity: .95;
            }
            .bg-3 {
                background-color: #4f575a;
                opacity: .95;
            }
        } // .bg-0,
        //  .bg-7 {
        //      background-color: #4CB5F5;
        //  }
        //  .bg-2,
        //  .bg-5 {
        //      background-color: #B7B8B6;
        //  }
        //  .bg-3,
        //  .bg-4 {
        //      background-color: #34675C;
        //  }
        //  .bg-6,
        //  .bg-1 {
        //      background-color: #B3C100;
        //  }
        // .bg-0,
        //  .bg-7 {
        //      background-color: #f4cc70;
        //  }
        //  .bg-2,
        //  .bg-5 {
        //      background-color: #d37a22;
        //  }
        //  .bg-3,
        //  .bg-4 {
        //      background-color: #20948b;
        //  }
        //  .bg-6,
        //  .bg-1 {
        //      background-color: #6ab187;
        //  }
        .flow-text,
        .flow-unit {
            // background-color: #187399;
            color: $white;
            text-align: center;
            margin: 0; // padding: 0 0 8% 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.5vmin);
            font-weight: bolder;
        }
        .flow-unit {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        h4.flow-text {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            font-size: calc(0.35rem + 1.95vmin);
        }
        .pressure-text,
        .pressure-unit {
            color: $white;
            background-color: $orange;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.15vmin);
            font-weight: bolder;
        }
        .flow-value,
        .pressure-value {
            font-size: calc(0.8rem + 1.74vw);
            letter-spacing: 2px;
            color: #4d5322;
            .odometer.odometer-theme-minimal
                {
                .odometer-value {
                    font-size: calc(1.4rem + 5.45vmin);
                }
            }
        }
        .value-box {
            background-color: $blue-tint;
            .color-positive .flow-value {
                color: red !important;
            }
            .color-negitive .flow-value {
                color: #1cd200 !important;
            }
        }
    }
    .middle {
        text-align: center;
    }
    .last {
        margin: 0;
        margin-left: 2%;
        text-align: center;
    }
    .gauge {
        width: 100%;
    }
    .gauge-container {
        // background-color: $blue;
        height: 100%;
        .chart-gauge {
            width: 400px;
            margin: 1% auto
        }
        .chart-first {
            fill: #9FBD35;
        }
        .chart-second {
            fill: #D7FF48;
        }
        .chart-third {
            fill: #FFFF1F;
        }
        .chart-fourth {
            fill: #FC7C7E;
        }
        .chart-fifth {
            fill: #FB3033;
        }
        .needle,
        .needle-center {
            fill: #3A3B46;
        }
        .text {
            color: "#112864";
            font-size: 24px;
        }
        svg {
            font: 10px sans-serif;
        }
        .flow-value {
            font-size: calc(2rem + 4vw);
        }
    }
    .card-title {
        // border-bottom: 1px solid $blue;
        background-color: #3A3B46;
        color: $white;
        font-size: 24px;
        padding: 0;
        text-transform: uppercase;
        width: 100%;
        max-height: 32px;
        .toggle-icon {
            margin-left: auto;
            vertical-align: middle;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            -webkit-transition: -webkit-transform 0.6s ease-in-out;
            transition: -webkit-transform 0.6s ease-in-out;
            transition: transform 0.6s ease-in-out;
            transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
        }
        h2 {
            margin: 0;
            font-weight: 400;
            font-size: 24px;
        }
        .card-expand {
            margin: 4px;
            color: $white;
        }
        .right {
            float: right;
            font-size: 12px;
            padding-right: 20px;
            md-checkbox {
                box-sizing: border-box;
                display: inline-block;
                margin-bottom: 7px;
                white-space: nowrap;
                cursor: pointer;
                outline: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                position: relative;
                min-width: 20px;
                min-height: 20px;
                margin-left: 0;
                margin-right: 16px;
                margin-top: 7px;
            }
            .motor-load {
                // margin: 3px 0 0 50px;
            }
        }
        .left {
            float: left;
            height: 100%;
            md-radio-button .md-label {
                // margin-top: -5px;
                font-size: 12px;
            } // h2 {
            //     margin-top: 0;
            //     font-size: 12px;
            // }
        }
        &:focus {
            outline: none;
        }
    }
    .dashboard-tab {
        width: 100vw;
        display: block;
    }
    .dc-savings-card {
        .unit-symbol {
            height: 100%; // padding-top: 18%;
            font-size: 1.5em;
            vertical-align: -webkit-baseline-middle;
        }
        .value-box {
            // height: 197px;
        }
        .flow-value {
            height: 100%;
            padding-top: 3%;
            font-size: 4em;
        }
        .as-list {
            margin: 4vh !important;
            margin-right: 80px !important;
        }
    }
    tackle-meter {
        .ani-value {
            font-size: 30px;
            overflow: hidden;
        }
        #ani {
            height: 310px;
            margin: 0 auto;
            overflow: hidden;
        }
    }
    .motor-btn {
        background-color: grey;
        -webkit-border-radius: 10px;
        border-radius: 50%;
        border: none;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-family: Arial;
        font-size: calc(0.5rem + 1.1vmin);
        font-weight: 800;
        text-shadow: 3px 3px 1px black;
        text-transform: capitalize;
        /*   padding: 5px 10px; */
        // text-align: center;
        text-decoration: none;
        width: 60px;
        height: 60px;
        padding: 2px 1px; // margin: 15px 2.5%;
        .motor-title {
            font-style: calc(0.25rem + .55vmin);
            display: block;
        }
    } // .motor-on {
    //     background-color: green !important;
    // }
    // .motor-on {
    //     -webkit-animation: glowing 2000ms infinite;
    //     -moz-animation: glowing 2000ms infinite;
    //     -o-animation: glowing 2000ms infinite;
    //     animation: glowing 2000ms infinite;
    // }
    .color-green {
        background-color: green;
    }
    .color-green-blink {
        background-color: green;
    }
    .color-green-blink {
        -webkit-animation: blinking 2000ms infinite;
        -moz-animation: blinking 2000ms infinite;
        -o-animation: blinking 2000ms infinite;
        animation: blinking 2000ms infinite;
    }
    @keyframes blinking {
        0% {
            // background-color: #FF0000;
            box-shadow: 0 0 -1.5px #FF0000;
        }
        50% {
            // background-color: #FF0000;
            box-shadow: 0 0 30px #FF0000;
        }
        100% {
            // background-color: #FF0000;
            box-shadow: 0 0 -1.5px #FF0000;
        }
    }
}

.ilp-gauge-sync-container {
    overflow-y: hidden;
        span {
            color:$white;
        }
    .md-active {
        background-image: linear-gradient(to right bottom, #4964ae, #395b93, #325077, #31455b, #313941);
        color: $white;
        // padding-top: 20px;
        font-weight: 700;
        letter-spacing: 0.4px;
        span {
            color: $white !important;
        }

    }
    md-tabs.md-dynamic-height md-tab-content{
        padding-top: 20px;
    }
    md-tabs-canvas {
        background-color: $darkblue;
        border: none;
    }
}

.unit-list {
    color: $black;
    background-color: $white;
}

.livechart-select {
    padding: 2px;
    line-height: 1;
    span {
        // font-size: 16px;
    }
}

.ani-info-icon {
    font-size: 18px !important;
    float: right;
    margin: 6px 5px 0 0;
}

.adv-time {
    background-color: $logo-orange !important;
    color: white !important;
    border-radius: 5px;
    font-size: 14px !important;
}

.o2-signal,
.vent-signal {
    text-shadow: 2px 2px 5px red;
}

// @keyframes signal {
//     from {
//       text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
//     }
//     to {
//       text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
//     }
//   }
// .o2-signal {
//     -webkit-animation: signal 1.5s ease-in-out infinite alternate;
//   -moz-animation: signal 1.5s ease-in-out infinite alternate;
//   animation: signal 1.5s ease-in-out infinite alternate;
// }
// @keyframes vent-signal {
//     from {
//       text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
//     }
//     to {
//       text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
//     }
//   }
// .vent-signal {
//     -webkit-animation: vent-signal 1.5s ease-in-out infinite alternate;
//     -moz-animation: vent-signal 1.5s ease-in-out infinite alternate;
//     animation: vent-signal 1.5s ease-in-out infinite alternate;
// }
// @keyframes signal {
//     0% {
//         box-shadow: 0 0 1.5px black;
//     }
//     50% {
//         box-shadow: 0 0 20px black;
//     }
//     100% {
//         box-shadow: 0 0 1.5px black;
//     }
// }
// .o2-signal {
//     -webkit-animation: signal 2000ms infinite;
//     -moz-animation: signal 2000ms infinite;
//     -o-animation: signal 2000ms infinite;
//     animation: signal 2000ms infinite;
// }
// @keyframes vent-signal {
//     0% {
//         box-shadow: 0 0 1.5px black;
//     }
//     50% {
//         box-shadow: 0 0 20px black;
//     }
//     100% {
//         box-shadow: 0 0 1.5px black;
//     }
// }
// .vent-signal {
//     -webkit-animation: vent-signal 2000ms infinite;
//     -moz-animation: vent-signal 2000ms infinite;
//     -o-animation: vent-signal 2000ms infinite;
//     animation: vent-signal 2000ms infinite;
// }
.live-title {
    // border-bottom: 1px solid $blue;
    background-color: #3A3B46;
    color: $white;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
    h2 {
        margin: 0;
        font-weight: 400;
        font-size: 24px;
    }
    .card-expand {
        margin: 4px;
        color: $white;
    }
    .multi-expand {
        margin: 5px 20px 0 0;
    }
    .right {
        float: right;
        font-size: 18px;
        font-weight: 500;
        padding-right: 20px;
        md-checkbox {
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 7px;
            white-space: nowrap;
            cursor: pointer;
            outline: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            position: relative;
            min-width: 20px;
            min-height: 20px;
            margin-left: 0;
            margin-right: 16px;
            margin-top: 7px;
        }
    }
    .left {
        padding-left: 10px;
        font-weight: 500;
        md-radio-button .md-label {
            // margin-top: -5px;
            font-size: 18px;
        } // h2 {
        //     margin-top: 0;
        //     font-size: 12px;
        // }
    }
    &:focus {
        outline: none;
    }
}

.tg-list {
    display: inline-block;
    font-size: 16px;
    margin: 7px;
    margin-right: 50px;
}

.tg-list-item {
    list-style: none;
}

.tgl {
    display: none;
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+.tgl-btn {
        box-sizing: border-box;
        &::selection {
            background: none;
        }
    }
    +.tgl-btn {
        outline: 0;
        display: block;
        width: 10.5em;
        height: 30px;
        position: relative;
        cursor: pointer;
        user-select: none;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        span {
            letter-spacing: 0.5px;
            text-align: center;
            font-weight: bold;
            color: $white;
            text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
        }
        &:after,
        &:before {
            position: relative;
            display: block;
            content: "";
            width: 16%;
            height: 100%;
        }
        &:after {
            left: 0;
        }
        &:before {
            display: none;
        }
    }
    &:checked+.tgl-btn:after {
        left: 85%;
    }
}

.tgl-light {
    padding: 0;
    +.tgl-btn {
        background: green;
        overflow: hidden;
        border-radius: 0.5em;
        padding: 2px;
        transition: all .4s ease;
        span {
            // margin-right: 20px;
            margin-top: 8px;
            width: 59%;
            text-align: left;
            font-size: 11px;
            letter-spacing: 0px;
            float: right;
            vertical-align: middle;
            line-height: 9px;
        }
        &:after {
            top: 0;
            left: 0;
            width: 15%;
            border-radius: 6px;
            background: #fff;
            transition: all .2s ease;
        }
    }
    &:checked+.tgl-btn {
        background: red;
        span {
            margin-left: 5px;
            margin-top: 7px;
            display: block;
            text-align: left;
        }
    }
}

md-tabs-wrapper md-next-button md-icon, md-tabs-wrapper md-prev-button md-icon{
    display: none;
}


.all-deck-content {

    padding-top:5px;
    md-toolbar{
        height:40px;
        background-image: linear-gradient(to top, #2d3133, #2f414a, #2d5263, #26637e, #17749a) !important;
        // border-radius:4px;
    }
    .deck-header{
        .deck-title{
            font-size: calc(0.35rem + 1.85vmin);
            color: $white;
            font-weight:500;
            // text-shadow: 2px 2px 5px Black;
        }
        .deck-icon{
            text-align: right;
            display: block !important;
            .toggle-icon{
                margin-left: auto;
                vertical-align: middle;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition: -webkit-transform 0.6s ease-in-out;
                transition: -webkit-transform 0.6s ease-in-out;
                transition: transform 0.6s ease-in-out;
                transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
                }
                &:focus{
                    outline: none;
                }
        }
                    
    }

    .hour-card-param {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: bolder;
        display: inline-block;
        letter-spacing: 0.5px;
        .info-icon {
            float: right;
            font-size: 18px;
            margin-right: 8px;
        }
    }
    .hour-card-value {
        font-size: calc(1.4rem + 3vh); // letter-spacing: 2px;
        text-align: center;
        .value-symbol {
            margin: 25px 7px 0 0;
            font-size: calc(0.45rem + 1.3vmin);
        }
    }
    .hour-card-unit {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: bolder;
    }
    .block-unit {
        color: $white;
        text-align: center;
        margin: 0;
       
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: bolder;
    }
    .unit-symbol {
        font-size: calc(0.45rem + 1.3vmin);
    }

    .deck-block{
        background-color: $darkblue;
        padding: 0.45vmin 0;
    }
    .deck-block-1{
        background-color: $orange;
        padding: 0.45vmin 0;
    }

    .d-0{
        background-color: $black;
    }
    .d-1{
        background-color: $logo-primary;
    }
    .d-2{
        background-color: $darkblue;
    }
    .d-3{
        background-color: $logo-secondary;
    }
    .d-4 {
        background-color: $logo-blue;
    }
}