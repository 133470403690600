$bg: #3E3665;
$yellow: #FFCF1E;
$white: #fff;
$duration: 5s;
$text-color: rgba(255, 255, 255, 0.3);

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    // background-color: $bg;
    display: flex;
    align-items: center;
    justify-content: center;
}
.system-dashboard-container{
    padding: 0 1em;
    height: 100vh;
    display: block;
    padding-top: 5.4vh;
    background-color: #758185 !important;

    .blue-bg {
        background-image: linear-gradient(to bottom, #56c1ff, #9ccdff, #ffffff);
        .unit {
            font-weight: 500 !important;
        }
    }

    .tick {
      display: none;
    }


    .odometer{
        // width: 100%;
    }

    .has-dmg .odometer-inside {
        text-align: center;
        padding-right: 4vmin;
        &:first-of-type:before {
            content: '$';
            font-size: 22px;
            font-weight: bold;
        }
    }
    .has-polarity .odometer-inside {
        text-align: center;
        padding-right:4vmin;
        &:first-of-type:before {
            content: '+';
            font-size: 22px;
            font-weight: bold;
        }
    }
    .odometer-formatting-mark {
        // vertical-align: super;
        font-weight: bolder;
    }
    .odometer-digit{
        // margin-left: 3px;
        // margin-right: 3px;
        // font-weight: bold;
        font-size: 3vmax;
        // margin-top: -1.5rem;
    }
    md-fab-trigger{
        height: 65px;
        margin: -.60vh 1vw 0vh 0vw;
    }

    md-fab-trigger > button{
        margin: 0;
        padding: 0;
        margin-top: 1vh;
        max-width: 20px !important;
        min-height: 5px !important;
        max-height: 20px !important;
        i.material-icons{
            font-size: 1.1em;
            display: block;
            margin: 0; 
            padding: 0;
        }
    }

    figure {
        padding-top: 2vh;
        height: 100%;
        width: 100%;
        border: 1px solid #758185;
        background-color:#758185;
        background-color: $white;
        border-radius: 4px;
    }
    figcaption{
        width: 100%;
    }

    .aq-tile-container{
        // background-color: white;

        background-image: linear-gradient(to bottom, #56c1ff, #9ccdff, #ffffff, #ffffff, #ffffff);
        height: 100%;
        padding-top: 1%;
        &:hover {
            .blue-bg {
                background-image: linear-gradient(to bottom, #9ccdff, #56c1ff, #ffffff);
                .unit {
                    font-weight: 500 !important;
                }
            }
        }

        // height: 30%;
        // margin-top: 3vh;
    }
}

// .view-container {
//     overflow: hidden;
// }


.gray {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

// .aq-tile-container:nth-child(even) {
//     background-color: #CCD2D5;
//     opacity: .8
// }
// .widget-block:nth-child(even) {
//     background-color: rgba(255, 255, 255,1);
//     // opacity: .8
// }
.widget-block {

    // border: 1px solid grey; 

    // height: 15% !important;
        // margin-top: 3vh;
        // max-height: 8vh !important;
        height: 50%;
        width: 100% !important;
        // padding-top: 2vh;
        // background-color: #D5d6d2;

    
    // overflow: hidden;

    .subtitle{
        font-size: 2rem;
    }
    .title{
        // white-space: nowrap;
        text-align: center;
        // margin-bottom: 1vh;
        span {
            // height: 100%;
            // display: block;
            // font-size: 1.3em;
            font-weight: 600;
            vertical-align: middle;
            padding: 0 1vw;

        }
        // @media screen and (min-width: 768px) {
        //   span {
        //     font-size: calc(1em + 6 * ((100vw - 1280px) / 680));
        //   }
        // }
        // @media screen and (min-width: 1400px) {
        //   span {
        //     font-size: calc(1.2em + 6 * ((100vw - 1280px) / 680));
        //   }
        // }

        .unit {
            font-size: calc(0.6rem + 1vmin);
            // padding:0;
            // margin:0;
            font-weight: normal;
            margin-left: -1vw;
            font-weight: 500 !important;
            // vertical-align: baseline;
        }

}
    &:hover{
        .title, .counter {
            // color: #fd6811;
        // background-color: lightgrey;
        color: #fd6811;
        }
    }

// .nv-x text{
//   font-size: 10px;
//   font-weight: normal !important;
//   // fill: blue;
// }
// .nv-y text{
//   font-size: 10px;
//   font-weight: normal !important;
//   // fill:red;
// }

// .nvd3 .nv-group.nv-series-0 {
//   fill: #758185 !important; /* color for area */
// }

//     nvd3 {
//         display: inline-block;
//         position: relative;
//         width: 100%;
//         height: 100%;
//         // padding-bottom: 100%; /* aspect ratio */
//         vertical-align: top;
//         overflow: visible;
//     }
//     svg {
//         padding-bottom:100%;
//         margin-top: -3px; 
//         width: 100%;
//         display: inline-block;
//         position: absolute;
//         z-index: 0;
//         top: -2.5vmin;
//         left: 0;
//         min-height: 100vmin !important;

//     }
  .flatten {
    height: 0 !important;
    display: none;
  } 

    .title,.chart,.counter,.switch{
        // height: 100%;
        font-size: 2.4rem;
        // font-weight: 600;
        max-height: 15vh;
        padding-top: 10px;
        height: 55%;
        height: auto;
    }
    .switch {
        // height: 15vh;
        span {
        // height: 14vh;
            display: block;
            // height: 5.5vmin;
            text-align: center;
            vertical-align: middle;
            // background-color: grey;
            // text-shadow: 0.3rem 0.3rem 0.3rem black;
            height: 90%;
            // padding: 1vmin 0% 0 0%;
            // font-weight: bold;
            font-size: 4rem;
            // overflow: hidden;
        }

        &.red-text { 
            span { color: red; }
        }
    }
    .counter {
        text-align: center;
        padding: 0;
        min-height: 4vmin;


        // border-bottom: 1px solid lightgrey;
        .odometer-digit-inner {
            // line-height: 1 !important;
        }
        
    }
    .chart {
        // max-height: 8vh;
        // padding-right: 2em;
        padding-top: 0vmin;
        margin: 0;
        min-height: 7vh;
        // padding-top: 0vh;
        svg.nvd3-svg {
            // height: 30vh !important;
        }
        div.chart-counter {
            // padding-top: 3vh;
            // margin-top: -5vmin;
            font-size: 1.5rem;
        }
    }

}
md-menu {
    .subicon {
        margin-top: -3px; 
    }
}
md-menu-item > .md-button p {
    text-transform: capitalize;
}

    // .widget-block-header:hover {
    //     background-color: #fd6811 !important;
    //     // opacity: 1;
    // }
    .widget-block-header {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        // padding: 1.9vmin;
        h3 {
            display: inline-block;
            // padding: 15px;
            font-size: large;
            // line-height: 0px;
            font-weight: bolder;
            // margin-top: 5.3vh;
        }
    }
    .widget-block-footer {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 0.5vmin;
        // height: 2vh;
        h3 {
            margin: 0;
            padding: 0;
            font-size: 1em !important ;
        }
    }
    .widget-block-footer, .widget-block-header {
        max-height: 4vh !important;
        // opacity: .8;

        .fab-container {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 9999;
        }
        background-color: #313941;
        // opacity: .95;
        color: $white;
        text-transform: uppercase;
        // padding: 10px;
        // padding-bottom: 1.9vh;
        // .time {
        //     float: left;
        // }
    }
.fab {
    // width: 35px;
    // height: 35px;
    // border-radius: 50%;
    // position: relative;
    // background-color: #BD7903;
    // cursor: pointer;
    // // position: fixed;
    // float: right;
    // transition: .3s cubic-bezier(.41,-0.86,.76,1.89);
    // margin: 0 5vw 0vh 0;
    
    // &.open {
    //     width: 40px;
    //     height: 40px;
    //     transition: .3s cubic-bezier(.41,-0.86,.76,1.89);
    // }
    
    // .option {
    //     width: 3px;
    //     height: 3px;
    //     overflow: hidden;
    //     background-color: $white;
    //     border-radius: 50%;
    //     position: absolute;
    //     top: calc(50% - 3px);
    //     transition: .3s cubic-bezier(.41,-0.86,.76,1.89);
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     z-index: 10;
        
        
    //     &.key6 {
    //         left: calc(80% - 6px);
    //     }
        
    //     &.key5 {
    //         left: calc(60% - 6px);
    //     }
        
    //     &.key4 {
    //         left: calc(40% - 6px);
    //     }        
    //     &.key3 {
    //         left: calc(80% - 6px);
    //         top: 60%;
    //     }
        
    //     &.key2 {
    //         left: calc(60% - 6px);
    //         top: 60%;
    //     }
        
    //     &.key1 {
    //         left: calc(40% - 6px);
    //         top: 60%;
    //     }
        
    //     i {
    //         font-size: 2em;
    //         padding-top:0.09em; 
    //         color: $white;
    //         transform: scale(0);
    //         transition: .5s cubic-bezier(.41,-2,.76,2);
    //         cursor: pointer;
    //         &.text {
    //             font-size: 1.5em;
    //             padding: 0;
    //             margin-top: 0;
    //             max-height: 10px;

    //         }
    //     }
        
    //     &.open {
    //         width: 40px;
    //         height: 40px;
    //         background-color: rgba(0,0,0,.3);
    //         &.key6 {
    //             transform: translate(40px, -35px);
    //         }

    //         &.key5 {
    //             transform: translate(-15px, -60px);
    //         }

    //         &.key4 {
    //             transform: translate(-50px, -35px);
    //         }
            
    //         &.key3 {
    //             transform: translate(40px, 15px);
    //         }

    //         &.key2 {
    //             transform: translate(-15px, 30px);
    //         }

    //         &.key1 {
    //             transform: translate(-50px, 15px);
    //         }
                 
            
    //         i {
    //             transform: scale(1);
    //             transition: .5s cubic-bezier(.41,-2,.76,2);
    //         }
    //     }
    // }
    
    // .close {
    //     width: 100%;
    //     height: 100%;
    //     left: 0;
    //     top: 0;
    //     position: absolute;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border-radius: 50%;
    //     overflow: hidden;
        
    //     i {
    //         color: $white;
    //         font-size: 20px;
    //         transform: scale(0);
    //     }
        
    //     &.open {
            
    //         i {
    //             transform: scale(1);
    //             transition: .5s cubic-bezier(.41,-2,.76,2);
    //         }
    //     }
    // }
}





.woba {
    display: block; 
    position: absolute;
    background: $white;
    border-radius: 100%;
    transform: scale(0);
    opacity: .5;
}

.woba.animate {
    animation: ripple .5s linear forwards;
}
.shine, .chrome {
    // font-size: 3em;
    // margin: 0 auto;
    // margin-bottom: 1em;
}

.shine {
    background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
    -webkit-background-size: 150px;
    color: $text-color;
    -webkit-background-clip: text;
    -webkit-animation-name: shine;
    -webkit-animation-duration: $duration;
    -webkit-animation-iteration-count: infinite;
    text-shadow: 0 0px 0px rgba(255, 255, 255, .8);
}

.chrome {
    background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
    background-image: -webkit-linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
    -webkit-background-size: 200px;
    color: $text-color;
    -webkit-background-clip: text;
    -webkit-animation-name: shine;
    -webkit-animation-duration: $duration;
    -webkit-animation-iteration-count: infinite;
    text-shadow: 0 0px 0px rgba(255, 255, 255, .8);
}

@-webkit-keyframes shine {
    0%, 10% {
        background-position: -1000px;
    }
    20% {
        background-position: top left;
    }
    90% {
        background-position: top right;
    }
    100% {
        background-position: 1000px;
    }
}
@keyframes ripple {
    100% {opacity: 0; transform: scale(2.5);}
}

.sym-gauge{
    margin-bottom:-30px;
    b{
        font-size: 2.4rem !important;
        // font-weight: 700 !important;
        // line-height: 160px !important;
    }
    canvas{
        // padding-top:10px;
    }
    span{
        font-size: 2.4rem !important;
        font-weight:500;
    }
}