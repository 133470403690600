.notification-container {
    .pagination-table {
        color: white !important;
        background-color: $dark-black !important;
        font-size: 16px;
        md-icon {
            margin: 0;
            color: white;
        }
        md-select.md-table-select {
            margin: 20px 0 26px 0;
        }
        
        .md-button[disabled]{
            md-icon{
            color:red !important;
            }
        }
    }
    table.md-table tbody.md-body>tr.md-row:nth-child(odd)>td{
        background-color: #e6e5e5 !important;
    }
    table.md-table tbody.md-body>tr.md-row>td{
        font-weight:500;
    }
    table.md-table th.md-column{
        color: $black;
        font-size: 16px;
        text-transform: uppercase;
    }
    .no-results {
        padding: 0 16px;
    }
    .analysis-chart iframe{
        height:60vh;
    }
    .notification-header {
        background-color: $dark-black;
        color:white;
        height: 40px;
        .expand-icon{
            margin-left:10px;
        }
        .notification-title{
            font-size:20px;
            font-weight:500;
            letter-spacing: .2px;
            margin-left:5px;
        }
    }
    .catalog-icon{
        margin-right: 10px;
    }
}

md-select-menu md-option{
    background-color: white !important;
    color:black;
}