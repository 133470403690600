aqs-chat {
    padding: 0 !important;
}

md-backdrop.md-sidenav-backdrop {
    z-index: 998;
}


.md-sidenav-right {
    background-color: #758185;
    // max-width: 25vw;
    // max-height: 95vh;
    overflow-y: scroll;
    z-index: 1001;
    md-content {
        padding: 0;
        // max-width: 25vw;
        // overflow: scroll !important;
        background-color: $light-blue !important;
        form {
            .row {
                background-color: $secondaryblue;
                width: 25vw;
                height: 20vw;
                textarea {
                    margin: 5px 5px;
                }
            }
        }
        .send-button {
            width: 100px;
            margin: 0 0 0 5px;
            width: 309px;
            background-color: #0982CB;
            color: #EEF0F3;
            text-align: center;
            font-size: 25px;
            letter-spacing: 1px;
            md-icon {
                margin: 0;
                width: 8px;
                height: 8px;
                margin-right: 16px;
                margin-bottom: 20px;
                color: lightgray !important;
            }
        }
    }
    .chat-header {
        background-color: $logo-orange;
        height: 38px;
        display: flex;
        justify-content: center;
        h1 {
            margin: 0;
            padding: 7px 0;
            font-size: 24px;
            color: white;
            text-shadow: 1px 1px 1px $logo-secondary;
            text-align: center;
            sup {
                font-size: 5px;
                vertical-align: super;
                letter-spacing: 0.5px;
                color: black;
                text-shadow: none;
                top: -1.5em;
            }
        }
        img {
            width: 93px;
            // display: block;
            // margin-left: 30%;
        }
    }
}
.onbutton, .offbutton {
    margin: 0 !important;
    border-radius: 0;
}
.onbutton {
    background-color: #fff;
    color: $black;
}

.sami-btn{
    font-size: 24px;
    text-align: center;
    sup{
        font-size: 5px;
        vertical-align: super;
        letter-spacing: 0.5px;
        color: black;
        text-shadow: none;
        top: -1.5em;
    }
}
.offbutton {
    background-color: $black;
    color: $white;
}
ul.collection {
    max-height: 76vh;
    &.long{
        max-height: 88vh;
        padding: 0;
        width: 100% !important;
        padding: 0 8%;
        li.message:hover {
            background-color: $black;
            color: $white;
            position: relative;
            i{
                color: $white;
                font-size: 1.5em;
                padding: 0;
                margin: 0;

            }
            a {
                position: absolute;
                background-color: #fd6811;
                top: 0;
                right: 0;
                width: 20%;
                height: 100%;
                padding-top:4%;
                color: white;
                font-weight: bold;
                font-size: 1.3em;
            }
        }
    }
    &.short{
        max-height: 76vh;
    }
    div.chat-link {

            text-align: center;
            height: 100%;
        a {
            color: $black;
            // tex
        }
    }
    div.chat-link:hover {
            paddin: 100vmin;
        background-color: $secondaryblue;
        a {
            color: white;
        }
    }
    overflow-y: scroll !important;
    margin: 0;
    .message-user {
        float: left;
        padding-right: 2px;
        margin-left: 2px;
        font-weight: bolder;
    }
    .message-date {
        color: #ededed;
        font-size: 0.7em;
        margin-left: 3px;
        text-transform: capitalize;
        font-weight: normal;
        position: absolute;
        right: 10px;
        top: 3px;
    }
    .message-content {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.2px;
        word-wrap: break-word;
    }
    .message-bubble {
        background-color: white;
        border-radius: 15px;
    }
    .news {
        height: 100%;
        .material-icons {
        }
    }

    .news-icon-stats {
        // display: block;
        color: $black;
        // float: left;
        // font-size: 24px;
        // padding: 0 1%;
        // padding-right: 4vmin;
    }
    .news-icon-alerts {
        // display: block;
        color: $red;
        // float: left;
        // font-size: 24px;
        // padding: 0 1%;
        // padding-right: 4vmin;
    }

    li.news {
        overflow: hidden;
        display: block;
        width: 100%;
        max-height: 100%;
        margin: 2vmin 0;
        background-color: $white;
        border-radius: 4px;
        padding: 1.5vmin;
        // div {
        //     padding: 15px;
        // }
        .news-item {
            // border: 1px solid #f5f2f2;
        }
   
    }

    .news.ng-move ,
    .news.ng-enter{
        transition:all linear 2s;

    }
    .news.ng-leave {
        transition:all linear 0.3s;
    }

    .news.ng-leave,
    .news.ng-move ,
    .news.ng-enter {
        transition:all linear 1s;
    }
    .news.ng-leave .news-icon,
    .news.ng-move .news-icon ,
    .news.ng-enter .news-icon {
        transition:all linear 1s;
    }


    .news.ng-enter-stagger {
      transition-delay: 0.2s;

      /* As of 1.4.4, this must always be set: it signals ngAnimate
        to not accidentally inherit a delay property from another CSS class */
      transition-duration: 0s;
    }


    .news.ng-leave.ng-leave-active,
    .news.ng-move,
    .news.ng-enter {
      opacity:0;
      // max-height:0;
        // margin-left: 100%;
      // md-icon {
      //   margin-right: 100%;
      //   opacity: 0;
      // }
    }

    .news.ng-leave,
    .news.ng-move.ng-move-active,
    .news.ng-enter.ng-enter-active {
      opacity:1;
      // max-height:30px;
        // margin-left: 0%;
      // md-icon {
      //   margin-right: 0%;
      //   opacity: 1;
      // }
    }
}

.triangle-right {
    position: relative;
    padding: 5px;
    margin: 0.7em 0 0.7em;
    color: #fff;
    background: $blue;
    /* default background for browsers without gradient support */
    /* css3 */
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#2e88c4), to($blue));
    background: -moz-linear-gradient(#2e88c4, $blue);
    background: -o-linear-gradient(#2e88c4, $blue);
    background: linear-gradient(#2e88c4, $blue);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}


/* Variant : for top positioned triangle
------------------------------------------ */

.triangle-right.top {
    background: -webkit-gradient(linear, 0 0, 0 100%, from($blue), to(#2e88c4));
    background: -moz-linear-gradient($blue, #2e88c4);
    background: -o-linear-gradient($blue, #2e88c4);
    background: linear-gradient($blue, #2e88c4);
}


/* Variant : for left positioned triangle
------------------------------------------ */

.triangle-right.left {
    margin-left: -10px;
    background: $blue;
    margin-right: 30px;
}


/* Variant : for right positioned triangle
------------------------------------------ */

.triangle-right.right {
    margin-right: 30px;
    background: $orange;
    margin-left: -10px;
}

.triangle-right:after {
    content: "";
    position: absolute;
    bottom: -20px;
    /* value = - border-top-width - border-bottom-width */
    left: 50px;
    /* controls horizontal position */
    border-width: 20px 0 0 20px;
    /* vary these values to change the angle of the vertex */
    border-style: solid;
    border-color: $blue transparent;
    /* reduce the damage in FF3.0 */
    display: block;
    width: 0;
}


/* Variant : top
------------------------------------------ */

.triangle-right.top:after {
    top: -20px;
    /* value = - border-top-width - border-bottom-width */
    right: 50px;
    /* controls horizontal position */
    bottom: auto;
    left: auto;
    border-width: 20px 20px 0 0;
    /* vary these values to change the angle of the vertex */
    border-color: transparent $blue;
}


/* Variant : left
------------------------------------------ */

.triangle-right.left:after {
    top: 16px;
    left: -30px;
    /* value = - border-left-width - border-right-width */
    bottom: auto;
    border-width: 15px 30px 0 0;
    /* vary these values to change the angle of the vertex */
    border-color: transparent $blue;
}


/* Variant : right
------------------------------------------ */

.triangle-right.right:after {
    top: 16px;
    right: -30px;
    /* value = - border-left-width - border-right-width */
    bottom: auto;
    left: auto;
    border-width: 15px 0 0 30px;
    /* vary these values to change the angle of the vertex */
    border-color: transparent $orange;
}

.types{
    
    // width: 100%;
    // padding: 0;
    button{
        margin: 0 !important;
        width: 100%;

    }
    .type-all{
        padding: 0 3.5%;
        font-size: 20px;
        background: $logo-orange;
        border-radius: 0;
        color: white;
        font-weight: 500;
        min-width: 50px;
        text-transform: capitalize !important;

    }
    .stat-type{
        // padding: 0 3.5%;
        font-size: 20px;
        background: $black;
        border-radius: 0;
        color: white;
        font-weight: 500;
        text-transform: capitalize !important;
    }
    .alert-type{
        // padding: 0 3%;
        font-size: 20px;
        background: $red;
        border-radius: 0;
        color: white;
        font-weight: 500;
        text-transform: capitalize !important;
    }
    .mgt-type{
        // padding: 0 2%;
        font-size: 20px;
        background: #00B04F;
        border-radius: 0;
        color: white;
        font-weight: 500;
        text-transform: capitalize !important;
    }
    .industry-type{
        // padding: 0 4%;
        font-size: 20px;
        background: #558DD5;
        border-radius: 0;
        color: white;
        font-weight: 500;
        text-transform: capitalize !important;
    }
}

.news-card{
    color:white;
    .toggle-icon{
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
        }

        &:focus{
            outline: none;
        }
        .news-header{
            text-transform: capitalize;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.3px;
        }
    
}

.news-stats{
    background: $black;
}
.news-alerts{
    background: $red;
}
.news-management{
    background: #00B04F;
}
.news-industry{
    background: #558DD5;
}
md-badge{
    margin: 0 0 0 8px;
    background-color: #259b24;
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 16px;
  }

  .new-chat{
    background-color: red;
  }

  .ANN{
    background-color:#758185;
  }

  .ann-width{
    min-width: 200px !important;
    width: 85vw !important;
    max-width: 640px !important;
  }

  .no-news{
    margin-top: 15px;
    color:$white;
    text-transform: capitalize;
      .no-news-icon{
        line-height: 2;
      }
      .no-news-text{
        font-size:16px;
        font-weight:500;
      }
  }
