
.login-container {
  height: calc(100vh - 45px);
  // background: url("https://d2a5hmbghmbgtd.cloudfront.net/product_logos/apollo_loginBG.jpg") 20% no-repeat fixed;
  padding-top:0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @keyframes spinner {
    0% { transform: rotateZ(0deg); }
    100% { transform: rotateZ(359deg); }
  }
  
  * {
    box-sizing: border-box;
  }
  .logo-container{
    display: block;
    max-width: 450px;
    min-height: 100px;
    max-height: 100px;
  
    .logo{
      width: 290px;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
  
  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
    // padding: 20px;
    // background: rgba(darken($logo-orange,4%), 0.5);
  }
  
  
  .login,.reset {
    
    border-radius: 2px 2px 5px 5px;
    padding: 10px 20px 20px 20px;
    width: 90%;
    // height: 90%;
    max-width: 450px;
    background: #ffffff;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
    
    &.loading {
      button {
        max-height: 100%;
        padding-top: 50px;
        .spinner {
          opacity: 1;
          top: 40%;
        }
      }  
    }
    
    &.ok {
      button {
        background-color: #8bc34a;
        .spinner{
          border-radius: 0;
          border-top-color: transparent;
          border-right-color: transparent;
          height: 20px;
          animation: none;
          transform: rotateZ(-45deg);
        }
      }
    }
    
    input {
      display: block;
      padding: 20px 20px;
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      transition: border-width 0.2s ease;
      border-radius: 2px;
      color: #ccc;
      
      &+ i.fa {
        color: #fff;
        font-size: 1.8em;
        position: absolute;
        margin-top: -70px;
        opacity: 0;
        left: 0;
        transition: all 0.1s ease-in;
      }
      
      &:focus {
        &+ i.fa {
          opacity: 1;
          left: 30px;
        transition: all 0.25s ease-out;
        }
        outline: none;
        color: #444;
        border-color: $logo-orange;
        border-left-width: 40px;
      }
      
    }
    
    a {
     font-size: 0.8em;   
      color: $logo-orange;
      text-decoration: none;
    }
    
    .title {
      color: #444;
      font-size: 1.2em;
      font-weight: bold;
      margin: 10px 0 40px 0;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      text-align: center;
    }
    
    button {
      width: 100%;
      height: 100%;
      padding: 10px 10px;
      background: $logo-orange;
      color: #fff;
      display: block;
      border: none;
      margin-top: 20px;
      position: absolute;
      left: 0;
      bottom: 0;
      max-height: 60px;
      border: 0px solid rgba(0,0,0,0.1);
    border-radius: 0 0 2px 2px;
      transform: rotateZ(0deg);
      
      transition: all 0.1s ease-out;
        border-bottom-width: 7px;
      
      .spinner {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        border: 4px solid #ffffff;
        border-top-color: rgba(255,255,255,0.3);
        border-radius: 100%;
        left: 50%;
        top: 0;
        opacity: 0;
        margin-left: -20px;
        margin-top: -20px;
        animation: spinner 0.6s infinite linear;
        transition: top 0.3s 0.3s ease,
                  opacity 0.3s 0.3s ease,
                  border-radius 0.3s ease;
        box-shadow: 0px 1px 0px rgba(0,0,0,0.2);
      }
      
    }
    
      &:not(.loading) button:hover {
        box-shadow: 0px 1px 3px $logo-orange;
      }
      &:not(.loading) button:focus {
        border-bottom-width: 4px;
      }
      
    
  }
  
  footer {
    display: block;
    padding-top: 15px;
    text-align: center;
    color: #ddd;
    font-weight: normal;
    text-shadow: 0px -1px 0px rgba(0,0,0,0.2);
    // font-size: 0.8em;
    font-size: 1.5em;
    a, a:link {
      display: inline-block;
      width: 45%;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
      // color: #fff;
      // text-decoration: none;
    }
  }
  .button-title{
    color: white;
    // font-weight: bold;
    font-size: 24px;
  }

}


