.aq-lab-container {
    form {
        margin: 0;
        padding: 4px;
    }
    button.md-button.md-fab md-icon {
        display: inline;
    }
    .aq-lab-header {
        min-height: 40px !important;
        max-height: 40px;
        // border-radius: 4px;
        padding: 0;
        background-color: $darkblue !important;
        .aq-lab-title {
            font-size: calc(0.35rem + 1.85vmin);
            color: $white;
            font-weight: 500;
            padding: 0 8px;
        }
    }
    md-icon {
        margin: 0;
        color: white;
    }
    .md-button {
        // padding: 8px;
        margin: 0;
    }
    a.is-active:after {
        content: "";
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 86px;
        height: 43px;
        margin-top: -1px;
        margin-left: -43px;
        background: radial-gradient(circle at bottom left, transparent 0, transparent 68.5%, #fff 70.5%), radial-gradient(circle at bottom right, transparent 0, transparent 68.5%, #fff 70.5%);
        background-position: 0 100%, 100% 100%, 100% 0, 0 0;
        background-size: 51% 100%;
        background-repeat: no-repeat;
        transform: scaleY(-1) scale(0.35);
        margin-top: -28.8px;
    }
    .uda-chart-list {
        ul {
            li {
                display: inline;
                background-color: $light-blue;
                //   padding:20px 30px;
                position: relative;
                color: white;
                &:hover {
                    background-color: $light-blue;
                    cursor: pointer;
                    &:after {
                        border-top: $light-blue;
                    }
                }
                .current {
                    // width: 0;
                    // height: 0;
                    // border-left: 15px solid transparent;
                    // border-right: 15px solid transparent;
                    // border-top: 15px solid $light-blue;
                    // content: " ";
                    // position: absolute;
                    // bottom: -15px;
                    // left: 38%;
                    background-color: $logo-orange;
                    color: white;
                }
            }
        }
    }
    .uda-container {
        md-sidenav {
            width: 280px;
        }
        .uda-chart-options {
            text-align: center;
            background-color: $secondaryblue;
            .current {
                background-color: $logo-orange;
                color: $white;
            }
            .time_chart_div,
            .scatter_plot_div,
            .bar_chart_div,
            .favorite_div {
                border: 1px solid #fff;
                &:hover {
                    background-color: $light-blue;
                }
            }
        }
        .uda-form {
            md-input-container {
                margin-bottom: 0 !important;
                .md-input {
                    width: 95%;
                }
            }
            .favorite-table {
                background-color: $light-blue;
                table.md-table thead.md-head>tr.md-row {
                    height: 8px !important;
                    text-transform: capitalize !important;
                }
                table.md-table:not(.md-row-select) td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
                table.md-table:not(.md-row-select) th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
                    padding: 0;
                }
                table.md-table thead.md-head>tr.md-row th {
                    height: 8px !important;
                    text-transform: capitalize !important;
                    background-color: $darkblue !important;
                    padding: 0 !important;
                }
                table.md-table:not(.md-row-select) td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
                    padding: 0 !important;
                }
                table.md-table th.md-column {
                    color: $white !important;
                    font-weight: 600 !important;
                    text-align: center !important;
                }
                td.md-cell:nth-child(even),
                th.md-column:nth-child(even) {
                    background-color: $light-blue !important;
                    color: $white;
                    text-align: center;
                }
                td.md-cell:nth-child(odd),
                th.md-column:nth-child(odd) {
                    background-color: $light-blue !important;
                    color: $white !important;
                }
                .md-row .md-column,
                .md-row .md-cell {
                    text-align: center !important;
                    padding: 0 !important; // padding-left: 1%;
                    font-size: calc(0.27rem + 1vmin);
                    font-weight: 700;
                }
                table.md-table tbody.md-body>tr.md-row,
                table.md-table tfoot.md-foot>tr.md-row {
                    height: 2.3vh !important;
                    //   text-transform: capitalize;
                    text-align: left;
                }
            }
            md-expansion-panel md-expansion-panel-collapsed,
            md-expansion-panel-header .md-expansion-panel-header-container {
                padding: 0 0 0 8px;
                line-height: unset;
                font-weight: 500;
                min-height: 30px;
            }
            .favorite-container {
                .fav-header {
                    text-transform: capitalize;
                }
                md-icon {
                    color: $black;
                }
            }
            md-expansion-panel md-expansion-panel-expanded md-expansion-panel-content {
                padding: 4px 8px;
            }
        }
        .chart-form-content {
            max-height: calc(100vh - 130px);
        }
        .uda-chart-card {
            width: 100%;
            height: calc(100vh - 146px);
            .loading-chart {
                padding-top: calc(50vh - 80px);
            }
            #chartdiv {
                width: 100%;
                height: calc(100vh - 146px);
            }
        }
        .close-uda-form {
            position: fixed;
            bottom: 4px;
            right: 4px;
            background-color: $red;
            width: 30px;
            min-height: 30px;
            height: 30px;
            line-height: 30px;
            md-icon {
                margin: -4px;
            }
            &:hover {
                background-color: red;
            }
        }
        .form-row-delete {
            font-size: 13px;
            .md-mini {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }
            .md-button {
                min-height: 24px;
                padding: 0;
                vertical-align: middle;
            }
            .material-icons {
                font-size: 16px;
                font-weight: 900;
            }
        }
        .submit-btn {
            // width:103px;
        }
    }
    .aq-lab-form-panel {
        padding-top: 6px;
        .md-title {
            max-width: unset !important;
        }
    }
    .amcharts-amexport-label-level-1 {
        border: 2px solid #777;
    }
    .amcharts-amexport-top .amcharts-amexport-item>.amcharts-amexport-menu {
        // top: -3px!important;
        // left: 2px
    }
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
        width: fit-content;
        background-color: transparent !important;
    }
    #vericalToolBar {
        background-color: $light-blue;
        width: 50px;
    }
    #vericalToolBar md-icon {
        color: rgba(255, 255, 255, 0.87);
    }
    #toolBarTitle {
        writing-mode: tb-rl;
        transform: rotate(180deg);
        color: rgba(255, 255, 255, 0.87);
    }
    .open-side {
        background-color: $darkblue;
        margin-bottom: 4px;
        width: 30px !important;
        min-height: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
        md-icon {
            margin: -2px;
        }
        &:hover {
            background-color: $logo-orange !important;
        }
    }
}

.md-virtual-repeat-container {
    min-width: 300px !important;
    margin-top: 20px;
}