$primary: #4D5322;
$secondary: #ff9800;
$tertiary: #00b4a1;
$quaternary: #5923e3;
// $quinary: #;
// $senary: #;
// $septenary: #;
// $octonary: #;
// $nonary: #;
// $denary: #;
$white: #FFF;
$offwhite: #FAFAFA;
$dark-black: #404040;
$black: #4f4f51;
$blue: #658085;
$blue-tint: rgba(3, 48, 75, 0.03);
$darkblue: #313941;
$orange: #CB7909;
$orange-tint:rgba(203, 121, 9, 0.04);
$lightblue: #1D2E41;
$secondaryblack: #393C42;
$secondaryblue: #6A7B8E;
$red: #D41F22;
$secondaryred: rgb(217, 77, 95);
$yellow: #F0D705;
$violet: #7777ff;
$green: rgba(164, 192, 149, 0.99);
$darkgreen: #01584A;
$grey: #cac8c8;
$light-grey: #e9e9e9;
$grey-mud:#748FB2;
$gaugeblue: #1f77b4;
$gaugegreen: #2ca02c;
$gaugedarkgreen: #01584a;
$gaugeorange: #ff7f0e;
$gaugered: #d62728;
$gaugeyellow: $yellow;
$gaugepurple: #9467bd;
$gaugeblue: steelblue;
$break-sm-computer: 1024px;
$break-lg-computer: 1280px;
$break-xl-computer: 1440px;
// COLOR SCHEME ONE
// $dark-blue: #2493F8;
// $light-blue:#48B9FE;
// $dark-orange: #FC5A03;
// $light-orange:#FBA079;
// $light-white:#FCEBE4;
// $light-gold:#FC9F4C
$dark-blue: #1B3F4E;
$light-blue:#17749A;
$dark-orange: #4E361B;
$light-orange:#9A7245;
$light-white:#FCEBE4;
$light-gold:#FC9F4C;
$mid-orange:#9A6426;

$logo-orange:#fd6811;
$logo-blue:#00B0B0;
$logo-blue-lite:#11FDFD;
$logo-primary:#FF792B;
$logo-secondary:#B04506;