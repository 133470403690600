.sc-container {
    .first-card {
        text-align: center;
        max-height: fit-content;
        .flow-text,
        .flow-unit {
            background-color: $darkblue;
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.45rem + 1.55vmin);
            font-weight: bolder;
        }
        .info-1 {
            background-color: $darkblue;
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 5px;
            font-size: calc(0.45rem + 1.55vmin);
        }
        .pressure-text,
        .pressure-unit {
            color: $white;
            background-color: $orange;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.45rem + 1.55vmin);
            font-weight: bolder;
        }
        .flow-value,
        .pressure-value {
            font-size: calc(1.4rem + 2.5vw);
            letter-spacing: 2px;
            color: #4d5322;
            .odometer.odometer-theme-minimal,
                {
                .odometer-value {
                    font-size: calc(1.4rem + 5.45vmin);
                }
            }
        }
        .or-value{
            font-size: calc(1.4rem + 2vw);
            letter-spacing: 2px;
            color: #4d5322;
        }
        .value-box {
            background-color: $blue-tint;
            .symbol{
                font-size: calc(0.35rem + 1.75vmin);
                position: relative;
                top: 20%;
                margin-right:16px;
            }
        }
        .tss-flag{
            background-color: red;
            color: white;
            letter-spacing: 0.2px;
            font-weight: 600;
            font-size: calc(0.35rem + 1.2vmin);
            margin-right: 2px;
            border-radius: 2px
        }
    }
    .sr-card {
        text-align: center;
        max-height: fit-content;
        .flow-text{
            background-color: $darkblue;
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.45rem + 1.55vmin);
            font-weight: bolder;
        }
        .info-1 {
            background-color: $darkblue;
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 5px;
            font-size: calc(0.45rem + 1.55vmin);
        }
        .pressure-text,
        .pressure-unit {
            color: $white;
            background-color: $orange;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.45rem + 1.55vmin);
            font-weight: bolder;
        }
        .flow-value,
        .pressure-value {
            font-size: calc(1.4rem + 2.5vw);
            letter-spacing: 2px;
            // font-weight:500;
            color: #4d5322;
            .odometer.odometer-theme-minimal,
                {
                .odometer-value {
                    font-size: calc(1.4rem + 1vmin);
                }
            }
        }
        .flow-unit {
            background-color: $darkblue;
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.45rem + 1.55vmin);
            font-weight: bolder;
        }
        .footer-color{
            background-color: $darkblue;
            color: $white;
        }
        .value-box {
            background-color: $blue-tint;
            .sl-box,.re-box{
                // background-color: rgb(49, 57, 65);
                color:$white;
                // font-weight:500; 
            }
        }
        .sb-flag-yellow{
            background-color: yellow;
            color: black;
            font-family: Arial, Helvetica, sans-serif;
            letter-spacing: 0.2px;
            font-weight: 400;
            font-size: calc(0.45rem + 1.1vmin);
            margin-right: 2px;
            border-radius: 2px
        }
        .sb-flag-red{
            background-color: red;
            color:white;
            letter-spacing: 0.2px;
            font-weight: 500;
            font-size: calc(0.45rem + 1.1vmin);
            margin-right: 2px;
            border-radius: 2px
        }
    }
    
    .cp-card {
        .cp-heading {
            background-color: rgb(49, 57, 65);
            font-size: 22px;
            font-weight: 500;
            padding-top: 3px;
            letter-spacing: 0.2px;
            .toggle-icon{
                margin-left: auto;
                vertical-align: middle;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition: -webkit-transform 0.6s ease-in-out;
                transition: -webkit-transform 0.6s ease-in-out;
                transition: transform 0.6s ease-in-out;
                transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
                }
             
            .info-3 {
                color: $white;
                text-align: center;
                margin: 0;
                padding: 0.5vmin 0;
                vertical-align: middle;
                font-size: calc(0.45rem + 1.55vmin);
            }
        }
        .error-content {
            display: inherit;
            .error-icon {
                color: red;
                font-size: 20px;
            }
            .error-text {
                text-transform:capitalize;
                font-size: larger;
                font-weight: 700;
                color: black;
                margin-left: 5px;
            }
        }
        .cp-toggle{
            margin-right: 20px;
            &:focus{
                outline: none;
            }
        }
        .raspump-btn{
            background-color:green;
            font-size: calc(0.5rem + 1.1vmin);
            font-weight:800;
            &:hover{
                background-color:green;
            }
        }
        .ras-pump-name{
            font-size: calc(0.5rem + 1.1vmin);
            font-weight:500;
        }
        .raspump-btn-grey{
            background-color:grey;
            &:hover{
                background-color:grey;
            }
        }
        .pumpbtn-blink {
            -webkit-animation: blinking 2000ms infinite;
            -moz-animation: blinking 2000ms infinite;
            -o-animation: blinking 2000ms infinite;
            animation: blinking 2000ms infinite;
        }
        @keyframes blinking {
            0% {
                // background-color: #FF0000;
                box-shadow: 0 0  -1.5px #FF0000;
            }
            50% {
                // background-color: #FF0000;
                box-shadow: 0  0 30px #FF0000;
            }
            100% {
                // background-color: #FF0000;
                box-shadow: 0 0 -1.5px #FF0000;
            }
        }
    }
    .rank-card {
        .rank-header {
            background-color: rgb(49, 57, 65);
            font-size: 22px;
            font-weight: 500;
            .info-4 {
                color: $white;
                text-align: center;
                margin: 0;
                padding: 0.5vmin 0;
                vertical-align: middle;
                font-size: calc(0.45rem + 1.55vmin);
            }
            .rank-expand {
                margin-left: 5px;
                line-height: 1px;
                .ras-expand{
                    margin-left: 15px;
                }
                .ras-history{
                    margin-left: 15px;
                }
            }
        }
    }
}

.cp-dialog {
    .cp-heading {
        background-color: rgb(49, 57, 65);
        color: $white;
        font-size: calc(1.2rem + 1.55vmin);
        font-weight: 700;
    }
    .param-name {
        font-size: calc(0.6rem + 1vmin);
        font-weight: 500;
        margin-top: 20px;
    }
    .cp-value {
        font-size: calc(1.8rem + 1vmin);
        color: $logo-orange;
        font-weight: 700;
        margin-bottom: 20px;
        .ut-symbol{
            font-size: 16px;
        }
    }
}

.animate-if {
    background: $white;
    // border:1px solid black;
    // padding:10px;
  }
  
  .animate-if.ng-enter, .animate-if.ng-leave {
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  }
  
  .animate-if.ng-enter,
  .animate-if.ng-leave.ng-leave-active {
    opacity:0.3;
  }
  
  .animate-if.ng-leave,
  .animate-if.ng-enter.ng-enter-active {
    opacity:1;
  }

[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}