.elv-ch-container {
    .elv-ch__header {
        background-color: #2d3133;
        height: 48px;
        color: white;
        .elv-ch__text {
          font-size: 20px;
          font-weight: 500;
          padding-left: 24px;
          letter-spacing: 0.5px;
        }
      }
  .elv-ch-catalog {
    .elv-ch-catalog-header {
      height: 20px;
      .elv-ch-catalog-title {
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: 500;
      }
    }
    table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5 !important;
    }
    table.md-table tbody.md-body > tr.md-row > td {
      font-weight: 500;
    }
    table.md-table:not(.md-row-select) td.md-cell:first-child,
    td.md-cell:last-child {
      text-transform: capitalize;
    }
    table.md-table th.md-column {
      color: $black;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
