.tt-container {
    // padding-top: 4vh;
    display: block;
    overflow: scroll;
    min-height: 100vh;
    .hd-0,
    .dd-0 {
        background-color: $light-blue;
    }
    .hd-1,
    .dd-1 {
        background-color: $logo-primary;
    }
    .hd-2,
    .dd-2 {
        background-color: $darkblue;
    }
    .hd-3,
    .dd-3 {
        background-color: $logo-secondary;
    }
    .hd-4 {
        background-color: $logo-blue;
    }
    .hour-content {
        .hour-card-param {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
            display: inline-block;
            letter-spacing: 0.5px;
            .info-icon {
                float: right;
                font-size: 18px;
                margin-right: 8px;
            }
        }
        .hour-card-value {
            font-size: calc(1.4rem + 3vh); // letter-spacing: 2px;
            text-align: center;
            .value-symbol {
                margin: 25px 7px 0 0;
                font-size: calc(0.45rem + 1.3vmin);
            }
        }
        .hour-card-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
        }
        .block-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.45vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
        }
        .unit-symbol {
            font-size: calc(0.45rem + 1.3vmin);
        }
    }
    .second-content {
        .hour-card-param {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
            display: inline-block;
            letter-spacing: 0.5px;
        }
        .hour-card-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
        }
        .hour-card-value {
            font-size: calc(1.4rem + 3vh);
            text-align: center;
            display: inline-block;
            margin-top: 8vh;
            margin-bottom: 10vh;
            text-align: center;
            font-size: calc(1.2rem + 4vh);
        }
        .ct-card{
            text-align:center;
            color:white !important;
            .ct-header{
                background-color: $light-blue;
                color: white;
                font-size: 20px;
                font-weight: 500
            }
            .ct-footer{
                background-color: $darkblue;
                color: white;
                font-size: 14px;
                font-weight: 500
            }
            
            .ct-gauge{
                background-color: $darkblue;
                .ct-0{
                    padding-top:20px;
                }
                .permits-0{
                    padding-top:20px;
                }
                span{
                    color:white !important;
                }
                b {
                    font-size: 18px !important;
                    font-weight:500 !important;
                    color:white !important;
                }
            }
        }
        .dis-flow-prediction-container {
            md-card iframe {
                height: 370px;
            }
            .chart-label {
                text-align: center;
                background-color: $light-blue;
                color: $white;
                md-icon {
                    color: $white;
                    font-size: 24px;
                    margin: 0;
                }
                span {
                    font-size: calc(1.2rem + 1vh);
                    margin: 0;
                    letter-spacing: 1px;
                    font-weight:500;
                    padding:4px;
                    
                }
            }
        }
    }

    .dl-content {
        .dl-header{
            height:40px;
            border-radius:4px;
            background-color: #2D3133;
            .dl-title{
                font-size: calc(0.35rem + 1.85vmin);
                color: $white;
                font-weight:500;
                // text-shadow: 2px 2px 5px Black;
            }
            .dl-icon{
                text-align: right;
                display: block !important;
                color:$white;
                .toggle-icon{
                    margin-left: auto;
                    vertical-align: middle;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                    -webkit-transition: -webkit-transform 0.6s ease-in-out;
                    transition: -webkit-transform 0.6s ease-in-out;
                    transition: transform 0.6s ease-in-out;
                    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
                    }
                    &:focus{
                        outline: none;
                    } 
            }
                       
        }
        .dl-data{
            .first-row{
                height: 60px;
                font-weight: 500;
                text-transform: capitalize;
                .date-message{
                    background-image: linear-gradient(to right, #ac2f2f, #b62929, #c02222, #c9181a, #d20910);
                    color:$white;
                }
                .date-msg-orange{
                    background-image: linear-gradient(to left, #e63a04, #e14004, #dc4505, #d74a07, #d24e09);
                    color:$white;
                }
                .date-msg-green{
                    background-image: linear-gradient(to left, #346718, #367019, #39791b, #3a831c, #3c8c1e);
                    color:$white;
                }

            }
            .shift-text{
                margin-right:10px;
            }
        }
    }

    .multi-chart{
        .dis-frame{
            height:35vh;
        }
    }
}