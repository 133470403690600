.sp-dashboard-container {
  margin: 0;

  // height: 100%;
  md-toolbar {
    margin: 0;
    // height:40px;
  }

  .dashboard-nav {
    width: 100vw !important;
    display: block;
  }

  md-nav-bar .md-nav-bar {
    background-color: #3d4345;
  }

  md-nav-bar .md-button._md-nav-button.md-unselected {
    color: $white !important;
  }

  .md-button.md-accent {
    color: $white;
  }

  md-nav-bar md-nav-ink-bar {
    color: $light-blue;
    background: $light-blue;
  }

  .loading-shade {
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 81vh;
  }

  .dewatering-container {
    .odo-content {
      .odo-card-param {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: bolder;
        display: inline-block;
        letter-spacing: 0.5px;
      }

      .odo-card-value {
        font-size: calc(1.4rem + 3vh);
        // letter-spacing: 2px;
        text-align: center;

        .value-symbol {
          margin: 25px 7px 0 0;
          font-size: calc(0.45rem + 1.3vmin);
        }
      }

      .odo-card-unit {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: bolder;
      }

      .unit-symbol {
        font-size: calc(0.45rem + 1.3vmin);
      }
    }

    .od-0 {
      background-color: $darkblue;
    }

    .od-1 {
      background-color: $orange;
    }

    .od-2 {
      background-color: $darkblue;
    }

    .od-3 {
      background-color: $orange;
    }

    .od-4 {
      background-color: $darkblue;
    }

    .dt-header {
      font-size: 18px;
      font-weight: 500;
      padding: 4px;
    }

    .dw-advisor-chart {
      md-card iframe {
        height: 35vh;
      }

      .adv-chart-header {
        text-align: center;
        background-color: $darkblue;
        color: $white;

        md-icon {
          color: $white;
          font-size: 24px;
          margin: 0;
        }

        span {
          font-size: calc(1.2rem + 1vh);
          margin: 0;
          letter-spacing: 0.2px;
          font-weight: 500;
          padding: 4px;
        }
      }
    }
  }

  .management-container {
    md-card {
      margin: 6px;
    }

    .odo-content {
      .odo-card-param {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: 17.5795px;
        font-weight: bolder;
        // display: inline-block;
        letter-spacing: 0.5px;

        md-icon {
          margin: 0;
          padding-right: 100px;
          color: white;
        }

        .adv-text {
          padding-right: 10px;
        }
      }

      .odo-card-value {
        font-size: 45.072px;
        // letter-spacing: 2px;
        text-align: center;

        .value-symbol {
          margin: 25px 7px 0 0;
          font-size: calc(0.45rem + 1.3vmin);
        }
      }

      .odo-card-unit {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: 14.6665px;
        font-weight: bolder;
      }

      .unit-symbol {
        font-size: calc(0.45rem + 1.3vmin);
      }
    }

    .od-0 {
      background-color: $darkblue;
    }

    .od-1 {
      background-color: $orange;
    }

    .od-2 {
      background-color: $darkblue;
    }

    .od-3 {
      background-color: $orange;
    }

    .od-4 {
      background-color: $darkblue;
    }

    .dt-header {
      font-size: 18px;
      font-weight: 500;
      padding: 4px;
    }

    .sm-advisor-chart {
      md-card iframe {
        height: 35vh;
      }

      .adv-chart-header {
        text-align: center;
        background-color: $darkblue;
        color: $white;
        font-size: calc(0.35rem + 1.85vmin);
        padding: 4px;
        font-weight: 500;

        md-icon {
          color: $white;
          // font-size: 24px;
          margin: 0;
        }

        span {
          // font-size: calc(0.8rem + 1vh);
          margin: 0;
          letter-spacing: 0.2px;
          font-weight: 500;
          // padding: 4px;
        }
      }
    }

    .solids-planning-header {
      .solids-planning-title {
        font-size: calc(0.35rem + 1.85vmin);
        color: $white;
        font-weight: 500;
        // text-shadow: 2px 2px 5px Black;
      }

      .solids-planning-icon {
        md-icon {
          margin: 0;
          vertical-align: sub;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .sm-planning {
      .live-ops-header {
        background-color: $darkblue;
        color: $white;
        font-size: calc(0.35rem + 1.85vmin);
        padding: 4px;
        font-weight: 500;
      }

      .live-gauge-block {
        padding-top: 16px;
        background-image: linear-gradient(
          to right,
          #f4f5f8,
          #f7f7fa,
          #fafafb,
          #fcfcfd,
          #ffffff
        );
      }
    }
  }
  .incineration-container {
    md-icon {
      margin: 0;
      color: $white;
    }
    .field-content {
      .field-param-header {
        background-color: $darkblue;
        color: $white;
      }
      .info-icon{
        padding-left: 4px;
      }
      .field-unit-footer {
        background-color: $darkblue;
        color: $white;
      }
      .field-card-param {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: 500;
        display: inline-block;
        letter-spacing: 0.5px;
        .info-icon {
          float: right;
          font-size: 18px;
          margin-right: 8px;
        }
      }
      .field-value {
        font-size: calc(1.4rem + 3vh);
        // letter-spacing: 2px;
        text-align: center;
        .value-symbol {
          margin: 25px 7px 0 0;
          font-size: calc(0.45rem + 1.3vmin);
        }
      }
      .field-card-unit {
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: 500;
      }
      .dry-unit {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .cm-content {
      .inc-block{
        
        // border: 1px solid gray;
      }
      .cm-header {
        background-color: $darkblue;
        color: $white;
        padding: 4px;
        font-weight: 500;
        font-size: calc(0.35rem + 1.85vmin);
      }
      .in-block {
        padding-top: 12px;
        background-image: linear-gradient(to top, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada);
        

        .md-fab {
          width: 56px;
          height: 56px;
          line-height: 20px;
          background-color: green;
          .in-val-unit {
            display: block;
          }
        }
        .on-color {
          background-color: green;
          color: white;
        }
        .stand-by-color {
          background-color: #ff4f17;
          color: white;
        }
        .off-color {
          background-color: grey;
          // color: grey;
        }
        .progress {
          margin: 10px 30px;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          // border-radius: 1px;


          // -webkit-transform: rotate(-90deg); 
          // transform: rotate(-90deg);
          //  width: 90px;
          //  margin-top: 25px;
        }
        
        .in-name {
          font-weight: 500;
          letter-spacing: 0;
          font-size: calc(0.35rem + 1.65vmin);
          // text-transform: lowercase;
        }
        .gas-status {
          background-color: #ffeb3b;
          color: black;
          font-size: 10px;
          font-weight: 500;
          // border: 1px solid black;
          -webkit-transition: width 1.5s ease; 
          transition: width 1.5s ease;
        }
        .energy-status {
          background-color: red;
          color: white;
          font-size: 10px;
          font-weight: 500;
          // border: 1px solid black;
          -webkit-transition: width 1.5s ease; 
          transition: width 1.5s ease;
        }
      }
      .cm-legend {
        background-image: linear-gradient(to bottom, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada);
        padding: 8px;
        .gas-text {
          padding: 4px;
          font-weight: 500;
          .gas-legend {
            height: 10px;
            width: 10px;
            background-color: #ffeb3b;
            display: inline-block;
            vertical-align: middle;
          }
          .energy-legend {
            height: 10px;
            width: 10px;
            background-color: red;
            display: inline-block;
            vertical-align: middle;
          }
          .green-legend {
            height: 10px;
            width: 10px;
            background-color: green;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
          .yellow-legend {
            height: 10px;
            width: 10px;
            background-color: #ff4f17;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
          .gray-legend {
            height: 10px;
            width: 10px;
            background-color: gray;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
    .pm-content {
      .pm-header {
        background-color: $darkblue;
        color: $white;
        font-size: calc(0.35rem + 1.85vmin);
        padding: 4px;
        font-weight: 500;
      }
      .in-block {
        padding: 0 0 1vh 0;
        background-image: linear-gradient(to top, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada);
        .in-name-block {
          padding: 1vh 0;
          .in-name {
            font-weight: 500;
            font-size: calc(0.35rem + 1.5vmin);
          }
          .in-unit {
            font-weight: 400;
          }
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          border: 1px black solid;
          li {
            float: left;
            display: block;
            color: white;
            background-color: black;
            border: 1px white solid;
            text-align: center;
            padding: 12px 12px;
            text-decoration: none;
            // &:hover {
            //   background-color: #111;
            // }
          }
          .violation-color {
            background-color: red;
            color: white;
          }
          .warning-color {
            background-color: $logo-orange;
            color: white;
          }
          .compliant-color {
            background-color: green;
            color: white;
          }
          .non-applicable-color {
            background-color: gray;
            color: white;
          }
        }
      }
      .pm-legend {
        background-image: linear-gradient(to bottom, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada);
        padding: 8px;
        .violation-text {
          padding: 4px;
          font-weight: 500;
          .violation-legend {
            height: 10px;
            width: 10px;
            background-color: red;
            display: inline-block;
            vertical-align: middle;
          }
        }
        .warning-text {
          padding: 4px;
          font-weight: 500;
          .warning-legend {
            height: 10px;
            width: 10px;
            background-color: $logo-orange;
            display: inline-block;
            vertical-align: middle;
          }
        }
        .compliant-text {
          padding: 4px;
          font-weight: 500;
          .compliant-legend {
            height: 10px;
            width: 10px;
            background-color: green;
            display: inline-block;
            vertical-align: middle;
          }
        }
        .non-app-text {
          padding: 4px;
          font-weight: 500;
          .non-app-legend {
            height: 10px;
            width: 10px;
            background-color: gray;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    .ta-content {
      .ta-header {
        background-color: $darkblue;
        color: $white;
        font-size: calc(0.35rem + 1.85vmin);
        padding: 4px;
        font-weight: 500;
        .toggle-icon {
          margin-left: auto;
          vertical-align: middle;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          -webkit-transition: -webkit-transform 0.6s ease-in-out;
          transition: -webkit-transform 0.6s ease-in-out;
          transition: transform 0.6s ease-in-out;
          transition: transform 0.6s ease-in-out,
            -webkit-transform 0.6s ease-in-out;
        }
        .toggled {
          transform: rotate(0deg) !important;
        }
        &:focus {
          outline: none;
        }
      }

      table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
        background-color: #e6e5e5 !important;
      }
      table.md-table tbody.md-body > tr.md-row > td {
        font-weight: 500;
      }
      table.md-table:not(.md-row-select) td.md-cell:first-child,
      td.md-cell:last-child {
        text-transform: capitalize;
      }
      table.md-table th.md-column {
        color: $black;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}

.frame-adv {
  background: url("https://d2a5hmbghmbgtd.cloudfront.net/webfonts/atlas_spinner.svg")
    center center no-repeat;
}

.advisor-expand-dialog {
  background-image: linear-gradient(
    to bottom,
    #d1d2d5,
    #d9d6da,
    #e2dadc,
    #e9dfdd,
    #ece5de
  );
  // width: 85%;
  // height: 90%;
  max-width: fit-content;
  height: fit-content;

  .sadv-header {
    .icon-close {
      text-align: center;
    }

    .icon-info {
      text-align: center;
    }

    .adv-plan-title {
      font-size: calc(0.35rem + 2vmin);
      font-weight: 500;
      color: $white;
    }

    md-icon {
      color: $white;
    }

    background-color: $darkblue;
    min-height: 50px;
  }

  .forecast-block {
    background-color: $black;
    color: $white;
    font-weight: 500;
    border-radius: 4px;
    margin: 8px;

    .forecast-name {
      font-size: calc(0.35rem + 1.4vmin);
      padding: 8px;
    }

    .counter-span {
      font-size: calc(0.35rem + 1.4vmin);
      vertical-align: sub;
    }

    .forecast-date {
      margin: 30px 5px 0 20px;
      font-size: 12px;
    }
  }

  .form-block {
    padding-top: 0;

    .plan-select {
      padding: 8px;
      margin: 0;
      background: $logo-primary;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: white;
      font-weight: 600;
      font-size: calc(0.35rem + 1.4vmin);
      text-transform: uppercase;

      .md-on {
        background-color: $white;
      }

      md-radio-button.md-checked .md-off {
        border-color: $white;
      }

      .md-ink-ripple {
        color: $white;
      }
    }

    .adv-form {
      background-color: $white;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      md-input-container label {
        color: $black;
      }

      md-input-container .md-input {
        color: $black;
        font-weight: 500;
        font-size: calc(0.35rem + 1.5vmin);
      }

      md-input-container:not(.md-input-invalid).md-input-focused label {
        color: $logo-primary;
      }

      md-input-container:not(.md-input-invalid).md-input-focused .md-input {
        color: $black;
        font-weight: 500;
        font-size: calc(0.35rem + 2vmin);
        border-color: $logo-primary;
      }

      md-checkbox.md-checked .md-icon {
        background-color: $logo-orange;
      }

      md-select .md-select-value {
        text-align: center;
      }

      md-checkbox[disabled].md-checked .md-icon {
        background-color: $logo-orange;
      }

      md-input-container.md-block {
        text-transform: capitalize;
      }

      md-checkbox {
        font-weight: 500;
        font-size: calc(0.35rem + 1.2vmin);
      }

      .form-footer {
        .submit-button {
          background-color: $logo-orange;
        }

        .reset-form {
          background-color: $black;
        }
      }
    }
  }

  .adv-table {
    .adv-table-header {
      padding: 4px;
      margin: 0;
      background: $darkblue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: white;
      font-weight: 600;
      font-size: calc(0.35rem + 1.4vmin);
      text-transform: uppercase;
    }

    .table-card {
      table.md-table thead.md-head > tr.md-row {
        height: 20px !important;
        text-transform: capitalize !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
      table.md-table:not(.md-row-select)
        th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px;
      }

      table.md-table thead.md-head > tr.md-row th {
        height: 8px !important;
        text-transform: capitalize !important;
        // background-color: $darkblue !important;
        padding: 0 !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px !important;
      }

      table.md-table th.md-column {
        font-weight: 600 !important;
        text-align: center !important;
      }

      table.md-table th.md-column {
        color: $black;
        font-size: 13px;
        text-transform: uppercase;
      }

      .md-row .md-column,
      .md-row .md-cell {
        text-align: center !important;
        padding: 5px !important; // padding-left: 1%;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
      }

      table.md-table tbody.md-body > tr.md-row,
      table.md-table tfoot.md-foot > tr.md-row {
        height: 28px !important;
        text-transform: capitalize;
        text-align: left;
      }

      table.md-table th.md-column md-icon {
        font-size: 12px !important;
        min-width: 15px;
      }

      table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
        background-color: #e6e5e5;
      }

      table.md-table thead.md-head > tr.md-row {
        height: 20px !important;
        text-transform: capitalize !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
      table.md-table:not(.md-row-select)
        th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px;
      }

      table.md-table thead.md-head > tr.md-row th {
        height: 8px !important;
        text-transform: capitalize !important;
        // background-color: $darkblue !important;
        padding: 0 !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px !important;
      }

      table.md-table th.md-column {
        font-weight: 600 !important;
        text-align: center !important;
      }

      table.md-table th.md-column {
        color: $black;
        font-size: 13px;
        text-transform: uppercase;
      }

      .md-row .md-column,
      .md-row .md-cell {
        text-align: center !important;
        padding: 5px !important; // padding-left: 1%;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
      }

      table.md-table tbody.md-body > tr.md-row,
      table.md-table tfoot.md-foot > tr.md-row {
        height: 28px !important;
        text-transform: capitalize;
        text-align: left;
      }

      table.md-table th.md-column md-icon {
        font-size: 12px !important;
        min-width: 15px;
      }

      table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
        background-color: #e6e5e5;
      }

      table.md-table tbody.md-body > tr.md-row:last-child(odd) {
        background-color: #e6e5e5 !important;
      }

      .color-Infeasible {
        text-align: center;
        background-color: red !important;
        color: $white;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
        padding-top: 5px;
      }

      .color-Optimal {
        text-align: center;
        background-color: green !important;
        color: $white;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
        padding-top: 5px;
      }

      // .adv-plan{
      //     background-color:red !important;
      // }
      background-color: white !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .table-actions {
    .table-hide {
      background-image: linear-gradient(
        to left bottom,
        #d2d0d0,
        #dddcdc,
        #e8e7e8,
        #f3f3f3,
        #ffffff
      );

      .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out,
          -webkit-transform 0.6s ease-in-out;
      }
    }

    .apollo-accept {
      padding: 4px;
    }

    .sup-accept {
      padding: 4px;
    }

    .md-button {
      min-width: fit-content;
      min-height: fit-content;
      line-height: unset;
    }
  }

  md-icon {
    margin: 0;
  }

  md-input-container {
    margin: 0;
  }
}

.accept-dialog {
  background-image: linear-gradient(
    to bottom,
    #09203f,
    #1b3454,
    #2d4a69,
    #3f607f,
    #537895
  );
  color: $white;

  .accept-confirm-text {
    font-weight: 500;
    font-size: calc(0.35rem + 1.5vmin);
    padding: 20px 40px 30px 40px;
  }

  .show-close {
    &:hover {
      background-color: $red !important;
      color: white;
    }
  }

  .okay-Apollo {
    background-color: rgb(255, 87, 34) !important;
    color: white;
  }

  .okay-Supervisor {
    background-color: rgb(40, 53, 147) !important;
    color: white;
  }
}

.advisor-history-dialog {
  background-image: linear-gradient(
    to bottom,
    #d1d2d5,
    #d9d6da,
    #e2dadc,
    #e9dfdd,
    #ece5de
  );
  // width: 85%;
  // height: 90%;
  max-width: fit-content;
  height: fit-content;

  // transition: height 0.45s cubic-bezier(0.35, 0, 0.25, 1);

  .adv-history-header {
    min-width: 500px;
    .icon-close {
      text-align: center;
    }

    .icon-info {
      text-align: center;
    }

    .adv-plan-title {
      font-size: calc(0.35rem + 2vmin);
      font-weight: 500;
      color: $white;
      .md-datepicker-triangle-button .md-datepicker-expand-triangle {
        border-top-color: $white;
      }
      .md-datepicker-input {
        color: $white;
      }
    }
    .adv-plan-text {
      vertical-align: text-top;
    }

    md-icon {
      color: $white;
    }

    background-color: $darkblue;
    min-height: 50px;
  }

  .forecast-block {
    background-color: $black;
    color: $white;
    font-weight: 500;
    border-radius: 4px;
    margin: 8px;

    .forecast-name {
      font-size: calc(0.35rem + 1.4vmin);
      padding: 8px;
    }

    .counter-span {
      font-size: calc(0.35rem + 1.4vmin);
      vertical-align: sub;
    }

    .forecast-date {
      margin: 30px 5px 0 20px;
      font-size: 12px;
    }
  }

  .plan-select {
    padding: 8px;
    margin: 0;
    background: $logo-primary;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: calc(0.35rem + 1.4vmin);
    text-transform: uppercase;

    .md-on {
      background-color: $white;
    }

    md-radio-button.md-checked .md-off {
      border-color: $white;
    }

    .md-ink-ripple {
      color: $white;
    }
  }

  .adv-table {
    .adv-table-header {
      padding: 4px;
      margin: 0;
      background: $darkblue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: white;
      font-weight: 600;
      font-size: calc(0.35rem + 1.4vmin);
      text-transform: uppercase;
    }

    .table-card {
      table.md-table thead.md-head > tr.md-row {
        height: 20px !important;
        text-transform: capitalize !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
      table.md-table:not(.md-row-select)
        th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px;
      }

      table.md-table thead.md-head > tr.md-row th {
        height: 8px !important;
        text-transform: capitalize !important;
        // background-color: $darkblue !important;
        padding: 0 !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px !important;
      }

      table.md-table th.md-column {
        font-weight: 600 !important;
        text-align: center !important;
      }

      table.md-table th.md-column {
        color: $black;
        font-size: 13px;
        text-transform: uppercase;
      }

      .md-row .md-column,
      .md-row .md-cell {
        text-align: center !important;
        padding: 5px !important; // padding-left: 1%;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
      }

      table.md-table tbody.md-body > tr.md-row,
      table.md-table tfoot.md-foot > tr.md-row {
        height: 28px !important;
        text-transform: capitalize;
        text-align: left;
      }

      table.md-table th.md-column md-icon {
        font-size: 12px !important;
        min-width: 15px;
      }

      table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
        background-color: #e6e5e5;
      }

      table.md-table thead.md-head > tr.md-row {
        height: 20px !important;
        text-transform: capitalize !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
      table.md-table:not(.md-row-select)
        th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px;
      }

      table.md-table thead.md-head > tr.md-row th {
        height: 8px !important;
        text-transform: capitalize !important;
        // background-color: $darkblue !important;
        padding: 0 !important;
      }

      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 5px !important;
      }

      table.md-table th.md-column {
        font-weight: 600 !important;
        text-align: center !important;
      }

      table.md-table th.md-column {
        color: $black;
        font-size: 13px;
        text-transform: uppercase;
      }

      .md-row .md-column,
      .md-row .md-cell {
        text-align: center !important;
        padding: 5px !important; // padding-left: 1%;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
      }

      table.md-table tbody.md-body > tr.md-row,
      table.md-table tfoot.md-foot > tr.md-row {
        height: 28px !important;
        text-transform: capitalize;
        text-align: left;
      }

      table.md-table th.md-column md-icon {
        font-size: 12px !important;
        min-width: 15px;
      }

      table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
        background-color: #e6e5e5;
      }

      table.md-table tbody.md-body > tr.md-row:last-child(odd) {
        background-color: #e6e5e5 !important;
      }

      .color-Infeasible {
        text-align: center;
        background-color: red !important;
        color: $white;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
        padding-top: 5px;
      }

      .color-Optimal {
        text-align: center;
        background-color: green !important;
        color: $white;
        font-size: calc(0.27rem + 1vmin);
        font-weight: 700;
        padding-top: 5px;
      }

      // .adv-plan{
      //     background-color:red !important;
      // }
      background-color: white !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .table-actions {
    .table-hide {
      background-image: linear-gradient(
        to left bottom,
        #d2d0d0,
        #dddcdc,
        #e8e7e8,
        #f3f3f3,
        #ffffff
      );
      .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out,
          -webkit-transform 0.6s ease-in-out;
      }
    }

    .apollo-accept {
      padding: 4px;
    }

    .sup-accept {
      padding: 4px;
    }

    .md-button {
      min-width: fit-content;
      min-height: fit-content;
      line-height: unset;
    }
  }

  md-icon {
    margin: 0;
  }

  md-input-container {
    margin: 0;
  }

  .pagination-table {
    color: white !important;
    background-color: #404040 !important;
    font-size: 16px;
    md-select.md-table-select > .md-select-value {
      margin: 0;
    }
    md-icon {
      margin: 0;
      color: white;
    }

    .md-button[disabled] {
      md-icon {
        color: grey !important;
      }
    }
  }
  .no-plan {
    background-image: linear-gradient(
      to bottom,
      #cfdbfc,
      #c6d5f2,
      #becee8,
      #b7c8dd,
      #b0c1d3
    );
    .error-text {
      color: red;
    }
    .select-text {
      color: $black;
      font-weight: 500;
      font-size: 16px;
    }
    .plan-select {
      background-color: $darkblue;
    }
    md-icon {
      margin-bottom: 4px;
    }
  }
}

.active-show {
  transition: all 0.45s cubic-bezier(0.35, 0, 0.25, 1);
}

.odo-red {
  text-shadow: 2px 2px 5px red;
}

.odo-green {
  text-shadow: 2px 2px 5px green;
}

.odo-black {
  text-shadow: 2px 2px 5px black;
}

// .md-dialog-backdrop:nth-of-type(even) {
//     z-index: 81;
// }

// .md-dialog-backdrop:nth-of-type(odd) {
//     z-index: 79;
// }

// .md-dialog-container:nth-of-type(even) {
//     z-index: 82;
// }

// .md-dialog-container:nth-of-type(odd) {
//     z-index: 80;
// }

.chart-expand-dialog {
  .chart-unit {
    font-size: 12px;
  }
}
