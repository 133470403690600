.ss-container {
    // width: 85vw;
    margin: 0 !important;
    padding: 1vh 0 0 0 !important;
    // padding-top: 50px;
    md-card {
        // padding: 0 !important;
        md-input-container.md-block {
            margin: 0;
            md-select {
                width: 6vw
            }
        }
        &.table-container {
            padding: 0;
            overflow: hidden;
            td.table-cell-heading {
                font-weight: bolder;
                span {
                    text-transform: none;
                }
            }
        }
        a.md-button.md-primary.md-raised,
        a.md-button.md-primary.md-fab,
        .md-button.md-primary.md-raised,
        .md-button.md-primary.md-fab {
            background-color: #D17E3E;
        }
        a.md-button.md-primary.md-raised:not([disabled]):hover,
        a.md-button.md-primary.md-fab:not([disabled]):hover,
        .md-button.md-primary.md-raised:not([disabled]):hover,
        .md-button.md-primary.md-fab:not([disabled]):hover {
            background-color: #E55921;
        }
        md-card-content.content-table {
            padding: 0;
            width: 100%;
        }
        .table-heading,
        tbody td {
            text-transform: capitalize;
        }
        md-toolbar {
            text-align: center;
            text-transform: capitalize;
        }
    }
    md-card:nth-child(1) .card-heading {
        background-color: #878628;
    }
    md-card:nth-child(2) .card-heading {
        background-color: #DB6161;
    }
    md-card:nth-child(3) .card-heading {
        background-color: #499ED4;
    }
    md-card:nth-child(4) .card-heading {
        background-color: #A19F27;
    }
    md-card:nth-child(5) .card-heading {
        background-color: #216087;
    }
}

.frame {}

.frame2 {}

.frame3 {
    md-radio-group {
        md-radio-button {
            checked .md-ink-ripple {
                color: rgb(135, 134, 40);
            }
            .md-off {
                border-color: rgb(135, 134, 40);
            }
        }
    }
}

md-content {
    form {
        margin-left: 2vh;
    }
}

.frame4 {}

.frame5 {}

.operating-container {
    .icon-expand {
        display: none;
    }
}

.pha-chart-card {
    // max-height: 0vh ;
}

.pump_health_analytics-container,
.pump-health-container {
    width: 100%;
    #pha-14 {
        md-card {
            height: auto;
        }
    }
    md-card {
        max-height: 50vh;
        height: 40vh;
        table {
            width: 100%;
        }
        md-toolbar {
            // background-color: $black;
            text-align: center;
            // text-transform: capitalize;
        }
    }
}

table.pvtTable tr th {
    background-color: #cdcdcd;
    border: 1px solid #000;
    font-size: 8pt;
    padding: 5px;
    font-weight: 500;
}

.pvtVal {
    font-size: 14px !important;
}

md-icon {
    margin: 8px 0 0 8px;
}

.pscardTitle {
    font-size: 1.0em !important;
    text-align: center !important;
    max-width: 100% !important;
}
.pscardTitle-1 {
    font-size: 1.0em !important;
    text-align: center !important;
    
    margin-right:5% !important;
}

.table {
    margin-bottom: 1px !important;
}

table>thead>tr>th {
    text-align: center;
}

.pump-row {
    text-transform: uppercase;
}

.ip-header {
    padding: 30px 16px 0 0;
}

.stats-table {
    table.md-table th.md-column,
    table.md-table td.md-cell {
        text-align: center !important;
        padding: 0 !important;
        font-size: 14px !important;
    }
    table.md-table tbody.md-body>tr.md-row {
        height: 30px !important;
        text-align: center !important;
    }
    table.md-table thead.md-head>tr.md-row {
        height: 30px !important;
    }

}

.pump-name,
.pump-list {
    background-color: #fd6711;
    color: $black;
    text-transform: uppercase;
}

.unit-name {
    font-size:calc(0.6rem + 1.2vmin);
    font-weight:500;
    font-style: italic;
    text-transform: capitalize;
    margin-top:20px;
}

.unit-list {
    text-transform: uppercase;
    font-size:calc(0.6rem + 1.2vmin);
    font-weight:500;
    &:hover{
        background-color: $white !important;
        color: $logo-orange;
    }
}

md-select-menu md-content md-option[selected]{
    background-color: $white !important;
}

.status-dialog{
    img{
        width:200px !important; 
        height:auto ;
    }
}
.status-actions{
    .close-status,.status-home{
        background-color: $logo-orange !important;
        color:$white;
    }
    .status-chart{
        background-color: $light-blue !important;
        color:$white;
    }
}


md-toast.md-center {
    left: 50% !important;
    transform: translate3d(-50%, 0, 0) !important;
    z-index:70 !important;
  }

  .md-tab{
      color:white !important;
  }