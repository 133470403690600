* {
  font-family: "Roboto", sans-serif;
}

aqs-app-header {
  // display: block;
  width: 100%;
  max-height: 40px;
  height: 40px;
}
md-content.layout-wrap._md.layout-row {
  background: none;
}
md-select.md-default-theme .md-select-icon,
md-select .md-select-icon {
  color: white;
}

.bg-S,
.bg-ALL {
  background-color: #3c3c3c !important;
  color: $white;
  &.off {
    background-color: grey !important;
  }
  &.on {
    background-color: #3c3c3c !important;
  }
  &.bg-S:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}
.bg-A {
  background-color: $red !important;
  color: $white;
  &.off {
    background-color: grey !important;
  }
  &.on {
    background-color: $red !important;
  }
  &.bg-A:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}
.bg-M {
  background-color: #00b04f !important;
  color: $white;
  &.off {
    background-color: grey !important;
  }
  &.on {
    background-color: #00b04f !important;
  }
  &.bg-M:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}
.bg-I {
  background-color: $blue !important;
  color: $white;
  &.off {
    background-color: grey !important;
  }
  &.on {
    background-color: $blue !important;
  }
  &.bg-I:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}

.shell {
  animation: none;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 0;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  backface-visibility: visible;
  background: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-size: auto auto;
  border: 0;
  border-style: none;
  border-width: medium;
  border-color: inherit;
  border-bottom: 0;
  border-bottom-color: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image: none;
  border-left: 0;
  border-left-color: inherit;
  border-left-style: none;
  border-left-width: medium;
  border-radius: 0;
  border-right: 0;
  border-right-color: inherit;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top: 0;
  border-top-color: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: inherit;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-rule-color: currentColor;
  column-rule-style: none;
  column-rule-width: none;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font: normal;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 0;
  outline: 0;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: none;
  overflow-x: none;
  overflow-y: none;
  padding: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "\201C""\201D""\2018""\2019";
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: inherit;
  text-align-last: auto;
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-style: flat;
  transition: none;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 0;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  /* basic modern patch */
  all: initial;
  all: unset;
  &.header {
    width: 85vw;
    float: right;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.fullscreen-chart {
  form {
    margin: 5px 0 0 5px; // margin-top: 8vh;
  }
  .demo-tab {
    form {
      margin: 0px 0 0 12px;
    }
  }
  h2 {
    margin-top: 10px;
  }
  .card-heading {
    display: inline-flex; // position: absolute;
    width: 85vw;
    img {
      width: 3vw;
      height: 3vw;
      margin: 5px;
      max-height: 40px;
      max-width: 40px;
      position: absolute;
      top: 0vh;
    }
  }
}

.dashboard-container {
  h2 {
    margin-top: 10px;
  }
  .card-heading.dashboard {
    img {
      max-height: 40px;
      max-width: 40px;
      height: 4vh;
      width: 4vw;
      margin: 5px;
    }
  }
}

// media queries
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .chat-header {
    h1 {
      padding: 0.7vh 0 0 0;
    }
  }
  .fullscreen-chart {
    h2 {
      margin-top: 10px;
    }
    .card-heading {
      img {
        width: 3vw;
        height: 4vh;
        margin: 10px;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .fullscreen-chart {
    h2 {
      margin-top: 10px;
    }
    .card-heading {
      img {
        width: 3vw;
        height: 4vh;
        margin: 10px;
      }
    }
  }
}

/* 1.3 dpr */

@media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
  .fullscreen-chart {
    h2 {
      margin-top: 10px;
    }
    .card-heading {
      img {
        width: 3vw;
        height: 4vh;
        margin: 10px;
      }
    }
  }
}

/* 1.5 dpr */

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .fullscreen-chart {
    h2 {
      margin-top: 10px;
    }
    .card-heading {
      img {
        width: 4.5vw;
        height: auto;
        margin: 10px;
      }
    }
  }
}

.pumplist {
  height: auto;
  min-height: 20vh;
  display: block;
}

// cards on different pages
.foreground {
  z-index: 81;
}

.background {
  z-index: 78;
}

#myStaticDialog-sc-0,
#myStaticDialog-sc-1,
#myStaticDialog-sc-2 {
  width: 100vw !important;
  height: 100vh !important;
}

.md-dialog-container {
  // z-index: -80;
  .card-heading {
    background-color: $black; // position: absolute;
    //margin-bottom: 10px;
    max-height: 12vh;
    h2 {
      color: $white;
      text-align: center;
      text-transform: uppercase;
      font-size: calc(1.2rem + 1.55vmin);
      margin: 0;
      span {
        text-transform: none;
        font-size: 16px;
        font-weight: 700;
      }
    }
    button:hover {
      background-color: $red;
    }
    button {
      background-color: $lightblue;
      color: $white;
      font-weight: bold;
      font-size: 1.2em;
      float: right;
      // margin: 13px 20px;
    }
    .title-content {
      font-weight: 500;
    }
  }

  #aqChart-1 {
    height: 77vh !important;
    // z-index: 99;
    display: grid;
    // // display: grid;
    overflow-y: hidden;
  }
}

#pha-141 md-icon {
  display: none;
}

.large-chart {
  height: 90vh;
  md-icon {
    // display: none;
  }
  md-card {
    height: 90vh;
    max-height: 90vh !important; // max-width: 30vw;
    overflow: hidden;
    .rta-box {
    }
    iframe {
      height: 80vh !important;
      max-height: 80vh !important;
    }
  }
}

control-card {
  z-index: 0;
  md-card {
    max-height: 40vh;
    md-input-container {
      color: $white !important;
      padding: 0;
      margin: 0;
      float: right;

      md-select .md-select-value {
        height: 100%;
        border: none !important;
        margin-top: 2px;
        text-align: left;
        color: $white !important;
      }
    }
  }
}

.fade-out {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 0;
}

.fade-in {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 1;
}

.md-button.md-fab {
  background-color: none;
  widows: 22px;
}

.card-info {
  position: absolute;
  top: 0px;
  right: 0px;
  i {
    font-size: 1.5em; // text-align: center;
    // vertical-align: middle;
  }
}

.card-table {
  width: 100%;
}

md-card {
  .table-row {
    td {
      text-align: center;
    }
    .table-cell-heading {
      text-align: left;
    }
  }
  overflow-y: hidden;
  border-radius: 4px; //padding-bottom: 5px;
  md-content {
    border-radius: 4px;
    overflow-y: hidden;
  }
  md-toolbar {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    background-color: $dark-orange;
    color: #eef0f3;
    text-align: center;
    min-height: 40px;
    h2 {
      margin: 9px 0 0 0;
      &.multi-pump-card-title {
        text-align: right;
        padding-right: 4px;
      }
    }
  }

  .card-heading {
    background-color: #313638 !important;

    // background-color: $light-blue; // margin-bottom: 10px;

    // #0288D1
  }
  #chart-container {
    width: 100%; //height: 30vh;
    .custom-controls {
      position: absolute;
      z-index: 1;
      padding-top: 4px;
      margin-left: 19vw;
      @media screen and (max-width: $break-sm-computer) {
        margin-left: 12%;
      }
      @media screen and (min-width: $break-lg-computer) {
        margin-left: 17%;
      }
      @media screen and (min-width: $break-xl-computer) {
        margin-left: 19%;
      }
      .material-icons {
        font-size: 16px;
      }
    }
  }
}

// end cards on different pages
// pumps
.led-box {
  height: 30px;
  width: 25%;
  margin: 0 0 20px 0;
  float: left;
}

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 0;
}

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.led-gray {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #a6a6a6;
  border-radius: 50%;
  box-shadow: #e5e5e5 0 -1px 7px 1px, inset #006 0 -1px 9px, #e5e5e5 0 2px 14px;
}

// pump end
body {
  max-width: 100vw;
  overflow: hidden;
}

div.pump {
  position: absolute;
  left: 0;
  width: 30%;
  animation: translate 7s infinite linear;
  img {
    position: absolute;
    animation: upDown 0.7s alternate infinite ease-in-out;
    width: 5%;
  }
  @keyframes upDown {
    to {
      transform: translatey(50px);
    }
  }
  @keyframes translate {
    to {
      transform: translatex(450%);
    }
  }
}

.app-container {
  max-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.header-component {
  min-height: 45px;
  background-color: #fd6811;
  color: $white;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  max-height: 45px;
  // overflow: hidden;
  .greeting {
    float: left;
    font-weight: 600;
    padding: 0;
    h5 {
      margin: 14px 0;
    }
    max-height: 43px;
  }
  .logo {
    padding: 0;
    h1,
    p {
      margin: 0;
      text-align: center;
    }
    h1 {
      font-size: 1.8em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif; //background-color: $black;
      padding: 7px 0;
      float: left;
      margin-left: 2px;
    }
    a {
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      text-decoration: none !important ;
    }
    a:hover {
      text-decoration: none !important ;
    }
  }
  .apollo-home {
    text-decoration: none;
    color: #fff;
    padding: 8px;
    margin: 0;
  }
  .junk-icon {
    width: 100%;
    div {
      padding: 0;
      margin: 0;
      height: auto;
      display: inline-block;
      min-width: 10px !important;
      width: 16px;
    }
  }
  .notification-icon,
  .settings-icon,
  .ava-icon {
    margin: 10px;
    color: white;
    text-decoration: none;
  }
  .md-primary {
    color: $offwhite;
    float: right; // background-color: $dark-orange;
    font-family: "Montserrat", sans-serif;
    line-height: 40px;
    border-radius: 0;
    margin: 0;
    font-size: 1.2em;
    font-weight: 700;
    text-transform: capitalize;
    &:hover {
      color: $black;
      background-color: $red;
    }
  }
  .site-info {
    text-align: center;
    .site-name {
      text-transform: uppercase;
      margin-top: 2px; // font-size: calc(2vh + 0.2vmin);
      font-weight: 800;
    }
    .user-name {
      text-transform: capitalize;
      font-size: calc(1vh + 0.2vmin);
      font-weight: 500;
    }
  }
  .bar-button {
    background-color: #01d8b7;
    color: #eef0f3;
    float: right;
    height: 5px;
    font-size: 9px;
    margin-top: 10px;
    padding: 0;
    /* width: 5%; */
    line-height: 6px;
    min-height: 22px;
    /* text-align: center; */
    text-shadow: 0.5px 0.5px 0.5px #000;
    /* position: relative; */
    margin-left: 5px;
  }
  img {
    height: auto;
    width: 20px;
    margin-right: 10px;
    &:focus {
      outline: none !important;
    }
  }
  .notifications {
    float: left;
    padding-top: 5px;
    height: auto;
    padding-left: 33%;
    display: inline-block;
    .alerts {
    }
    .alert-box {
      border-radius: 50%;
      height: 28px;
      width: 30px;
      &:hover {
        background-color: $red;
      }
    }
  }
  .info-box {
    width: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background-color: $orange;
    overflow: hidden;
    padding-top: 9px;
    margin: 0;
    display: inline-block;
    transition: width 0.3s;
  }
  .counter-container {
    height: 43px;
    max-height: 43px;
    right: 0;
    float: right;
    width: auto;
    &:hover {
      border: 1px solid $orange;
      .info-box {
        height: auto;
        width: 100px;
      }
    }
    .data-box {
      float: right;
      display: inline-block;
      margin: 8px 10px;
      span.counter {
        float: left;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        height: auto;
      }
    }
    max-height: 43px;
  }
  .sami-icon {
    margin-left: 8px;
    margin-top: 2px;
    font-weight: 500;
  }
}

.middle-column {
  max-height: 2.5vmin !important;
  margin: auto 0 !important;
}

.ant-wrapper {
  background-color: $black;
  // min-height: 5vh;
  // min-height: 5vh;
  .ant-container {
    margin-top: -5.8px;
  }
  width: 100%;
  overflow: hidden;
  // margin-top: 5vh;
  max-height: 34px !important;
  md-menu button {
    border-color: none !important;
  }
  .spacer {
    height: 5vh;
    width: 100vw;
  }

  .ant-text-wrapper {
    position: absolute;
    left: 8%;
    z-index: 4 !important;
    padding: 0 15%;
    margin-right: 100px;
  }
  .ant-controls {
    // min-width: 3vw;
    // // position: static;
    // // left: 0;
    z-index: 10;
    height: 35px;
    // margin: 0;
    // background-color: #fd6711;
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    // transition: all 10.5s;
    // .md-menu {
    //     margin-right: 10px;
    // }
    button {
      // border-color: $black;
      border-radius: 2px;
      // height: 3vmin;
      // margin: 0vmin 1vmin 0 0;
      // width: 30px;
      // margin-right: 10px;
      text-align: center;
      height: 100%;
      font-size: 22px !important;
      font-weight: 500;
      border: 1px solid orange !important;
      min-width: 2.4vw;
    }
    .ant-controls-primary {
      // transition: all 10.5s;
      // background-color: #313941;
      padding: 5px 15px;

      button {
        // border-color: $black;
        border-radius: 2px;
        height: 3vmin;
        margin: 0.3vmin 0 0 -100px;
      }
    }
  }
  .ant-clip {
    z-index: 20;
    .ant-controls-secondary {
      // height: 5vh;
      .md-button.md-icon-button {
        margin: 0;
      }
      .playPause {
        background-color: #4f4f51;
      }

      button {
        // border-color: $black;
        border-radius: 2px;
        // height: 3vmin;
        // margin: 0vmin 1vmin 0 0;
        // width: 30px;
        // margin-right: 10px;
        text-align: center;
        height: 100%;
        font-size: 22px !important;
        font-weight: 500;
        border: 1px solid orange !important;
        min-width: 2.4vw;
      }
      i {
        vertical-align: middle;
      }
    }
  }
  .outer {
    position: absolute;
    // width: 2000px;
    left: 10vw;
    // min-width: 100vw;
    z-index: 0;
    bottom: 0;
    // margin-left: -1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 36px;
  }

  .left__inner {
    // display: none !important;
    // background: goldenrod;

    padding: 0px 4vmin;
    flex: 1;
    transform: skew(45deg);
    display: flex;
    justify-content: flex-end;
  }
  .right__inner {
    // display: none !important;
    // background: #222;

    padding: 0px 4vmin;
    flex: 1;
    transform: skew(45deg);
  }
  .left__inner,
  .right__inner {
    white-space: nowrap;
    // width: 100vw;
    &.off {
      display: none;
    }
    &.on {
      display: block;
    }
  }

  .left__text,
  .right__text {
    transform: skew(-45deg);
    span {
      color: white;
      // font-weight: 200;
      font-size: 1.5em;
      // text-transform: uppercase;
    }
  }

  .left__text {
    color: #3c3c3c;
  }

  .right__text {
    color: Goldenrod;
  }

  .ant-text-wrapper {
    height: 5vh;
    // overflow: display;
    position: relative;
    white-space: nowrap;
    // min-width: 100vw;
  }
  .ant-text-wrapper .outer,
  .ant-text-wrapper.single-remove-active .outer {
    height: 100%;
    margin: 0;
    //  margin-bottom: .7vmin;
    // line-height: 50px;
    text-align: center;
    /* Starting position */
    -moz-transform: translateX(14.3%);
    -webkit-transform: translateX(14.3%);
    transform: translateX(14.3%);
    /* Apply animation to this element */
    -moz-animation: ant-text-wrapper 180s 0s linear infinite;
    -webkit-animation: ant-text-wrapper 180s 0s linear infinite;
    animation: ant-text-wrapper 180s 0s linear infinite;
  }
  /* Move it (define the animation) */
  @-moz-keyframes ant-text-wrapper {
    0% {
      -moz-transform: translateX(14.3%);
    }
    100% {
      -moz-transform: translateX(-100%);
    }
  }
  @-webkit-keyframes ant-text-wrapper {
    0% {
      -webkit-transform: translateX(14.3%);
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }
  @keyframes ant-text-wrapper {
    0% {
      -moz-transform: translateX(14.3%); /* Firefox bug fix */
      -webkit-transform: translateX(14.3%); /* Firefox bug fix */
      transform: translateX(14.3%);
    }
    100% {
      -moz-transform: translateX(-100%); /* Firefox bug fix */
      -webkit-transform: translateX(-100%); /* Firefox bug fix */
      transform: translateX(-100%);
    }
  }
  .ant-text-wrapper {
    .single {
      // min-width: 100vw;
      &.short {
        .left__inner,
        .right__inner {
          white-space: nowrap;
          // width: 30vw;
          margin: 0 30vw 0 40vw;
        }
        -moz-animation: ant-text-wrapper 63s 0s linear infinite;
        -webkit-animation: ant-text-wrapper 63s 0s linear infinite;
        animation: ant-text-wrapper 63s 0s linear infinite;
        @keyframes ant-text-wrapper {
          0% {
            -moz-transform: translateX(100%); /* Firefox bug fix */
            -webkit-transform: translateX(100%); /* Firefox bug fix */
            transform: translateX(100%);
          }
          100% {
            -moz-transform: translateX(-100%); /* Firefox bug fix */
            -webkit-transform: translateX(-100%); /* Firefox bug fix */
            transform: translateX(-100%);
          }
        }
      }
      &.long {
        -moz-animation: ant-text-wrapper 160s 0s linear infinite;
        -webkit-animation: ant-text-wrapper 160s 0s linear infinite;
        animation: ant-text-wrapper 160s 0s linear infinite;
        @keyframes ant-text-wrapper {
          0% {
            -moz-transform: translateX(100%); /* Firefox bug fix */
            -webkit-transform: translateX(100%); /* Firefox bug fix */
            transform: translateX(100%);
          }
          100% {
            -moz-transform: translateX(-100%); /* Firefox bug fix */
            -webkit-transform: translateX(-100%); /* Firefox bug fix */
            transform: translateX(-100%);
          }
        }
      }

      // margin-left: 100vw;
      /* Move it (define the animation) */
      @-moz-keyframes ant-text-wrapper {
        0% {
          -moz-transform: translateX(25%);
        }
        100% {
          -moz-transform: translateX(-105%);
        }
      }
      @-webkit-keyframes ant-text-wrapper {
        0% {
          -webkit-transform: translateX(25%);
        }
        100% {
          -webkit-transform: translateX(-105%);
        }
      }
      @keyframes ant-text-wrapper {
        0% {
          -moz-transform: translateX(25%); /* Firefox bug fix */
          -webkit-transform: translateX(25%); /* Firefox bug fix */
          transform: translateX(25%);
        }
        100% {
          -moz-transform: translateX(-105%); /* Firefox bug fix */
          -webkit-transform: translateX(-105%); /* Firefox bug fix */
          transform: translateX(-105%);
        }
      }
    }
  }
}
.paused,
.outer:hover,
.outer.single:hover {
  -webkit-animation-play-state: paused !important;
  -moz-animation-play-state: paused !important;
  -o-animation-play-state: paused !important;
  animation-play-state: paused !important;
}
.ant-text-wrapper.closed {
  min-width: 0 !important;
  max-width: 0 !important;
  overflow: hidden !important;
  display: none;
}
.ant-clip {
  margin: 0;
  // transform: scale(0.85);
  transition: all 1.2s;
}
.ant-wrapper {
  transition: all 1.2s;
  transition-delay: 1.2s;
}
// .sami-icon {
//     div {
//         width: 0;
//         overflow: hidden;
//         transition: all 1.2s;
//         transition-delay: 2.4s;
//     }
// }
// .sami-icon.closed {
//     div {
//         width: 100%;
//         overflow: hidden;
//         // transition: all 2.0s;
//         // transition-delay: 6.0s;
//     }
// }
.sami-icon:focus,
.md-icon:focus,
span:focus,
button:focus {
  outline: none;
  border: 0;
}
.ant-clip.closed {
  padding-right: 85.88vw;
  // opacity: 1;
  transform: scale(2);
  .ant-controls-secondary {
    display: none !important;
  }
}
.ant-wrapper.closed {
  md-menu {
    opacity: 1;
  }
  // height: 0vh !important;
  // margin-bottom: 5vh;
  overflow: hidden;
  background: none;
  // display: none;
}

.rta-box {
  // display: inline-block;
  // float: left;
  // height: 85%;
  // background-color: $blue;
  // max-width: 5vw;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  border-radius: 0;
  .counters {
    max-height: 100px;
    margin: 0 !important; // display: inline-block;
    // max-width: 5vw;
    // float: left;
  }
  div {
    margin: 0;
  }
  h1 {
    background-color: #404040;
    color: #fff;
    font-size: xlarge;
    /* width: 100%; */
    margin: 0px;
    padding: 1% 0 0 1%;
    text-transform: capitalize;
    /* height: 35px; */
    display: block;
    padding-bottom: 45px;
  }
}

.rta-box-title {
  height: 10px !important;
  padding: 0;
  font-size: calc(1vw + 1.5vmin);
}

.rta-box-controls {
  background-color: $dark-black;
  padding-right: 5px;
  a,
  a:hover {
    text-decoration: none;
    color: $white;
    float: right;
    padding: 8px;
    margin: 0;
    cusor: pointer;
  }
  md-icon {
    float: right;
    color: $white;
  }
}

.rta-box .flow-box,
.rta-box .pressure-box {
  // width: 100%;
  // height: 15%;
  margin: 0 5px; // float: left;
  padding: 0; // height: 85%;
  h4 {
    color: $white;
    text-align: center; // height: 4vmin;
    // border-top-left-radius:6px;
    // border-top-right-radius:6px;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder;
    letter-spacing: 0.8px;
  }
  span {
    margin: 0; // border: 1px solid grey;
    // width: 100%;
    font-size: calc(0.35rem + 2.75vmin);
    text-align: left;
    letter-spacing: 1px; // display: block;
    text-align: center;
    &.demand {
      // min-width: 60%;
      overflow: hidden; // padding-left: 35%;
      .odometer.odometer-theme-minimal {
        display: inherit;
        height: 1vh;
        .odometer-value {
          font-size: calc(1.4rem + 4.45vmin);
          width: auto;
          display: inline;
        } // margin-left: 25%;
      } // padding: 0.8vmin 0;
      // padding: 10%;
      height: auto; // max-height: 20%;
    }
    &.units {
      // padding: 0.2vmin 0;
      // border: none;
      // border-bottom-left-radius:6px;
      // border-bottom-right-radius:6px;
      display: block;
      color: white;
      font-size: calc(0.6rem + 0.75vmin);
      font-weight: bolder;
      height: auto; //margin: 0 0 -5px 0;
      padding: 0 0 10px 0;
    }
  }
}

.flow-box {
  // min-width: 60% !important;
  h4 {
    background-color: $darkblue; // height:
  }
  span.demand {
    display: block;
    text-align: center;
    background-color: $blue-tint;
    padding: 0; // padding-left: 10%;
  } // .units {
  //     background-color: $darkblue;
  // }
}

.pressure-box {
  h4 {
    background-color: $orange; // height:
  }
  span.demand {
    background-color: $orange-tint;
    display: block;
    text-align: center; // background-color: $blue-tint;
    // padding-left: 10%;
  }
  .units {
    background-color: $orange;
    height: 100%;
  }
}

.rta-box .panel-pumps {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 13vmin;
  .wrapper {
    height: 63%; // padding-left: 1%;
    button {
      background-color: $lightblue; // the follow is not dry
      // -webkit-border-radius: 10px;
      border-radius: 50%;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      font-family: Arial;
      font-size: 10px;
      text-transform: capitalize;
      /*   padding: 5px 10px; */
      text-align: center;
      text-decoration: none;
      width: 25px;
      height: 25px;
      padding: 2px 1px;
      margin: 10px 0 0 18px;
    }
  }
  .panel-pumps-title {
    height: 32%;
    font-size: calc(0.35rem + 1.45vmin);
    display: block;
    width: 100%;
    color: $white;
    text-align: center;
    font-weight: bold;
    background-color: $darkblue;
  }
}

.rta-box .panel-cost {
  // width: auto;
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: calc(1rem + 1vmin);
  width: 100%;
  height: 11vmin;
  color: $white;
  text-align: center;
  background-color: $lightblue;
  .wrapper {
    height: 75%;
    .odometer.odometer-theme-minimal {
      margin-bottom: 25%;
      font-size: calc(1.4rem + 4.45vmin);
      text-align: center;
      height: 100%;
      min-width: 50%; // margin-left: 25%;
    }
  }
  .panel-pumps-title {
    height: 35%; // font-size: calc(0.35rem + .45vmin);
    display: block; // width: 100%;
    color: $white;
    text-align: center;
    font-weight: bold;
    background-color: $darkblue;
  }
}

.nav-component {
  background-color: $black;
  position: absolute;
  z-index: 999;
  color: $white;
  width: 15vw;
  height: 100vh;
  padding-top: 0;
  float: left;
  display: inline-block;
  -webkit-box-shadow: 12px 0 20px -2px #888;
  box-shadow: 12px 0 20px -2px #888;
  div {
    width: 100%;
    padding-top: 15px;
  }
  md-icon {
    margin: 0 5px;
    color: $white;
  }
  md-list-item {
    padding: 0;
    div {
      padding: 0;
      width: 15vw;
      height: 48px;
    }
  }
  .navlist {
    a:hover,
    a:focus div {
      text-decoration: none;
    }
    a:focus md-icon,
    a:hover md-icon,
    a:focus md-icon {
      color: $secondaryblue;
    }
    a:hover div,
    a:focus div {
      background-color: $white !important;
      cursor: pointer;
    }
    a.active .nav-title,
    a:hover .nav-title,
    a.active .nav-title {
      font-size: 1.2em;
      font-weight: 700;
      color: $secondaryblue !important;
    }
    .sub-nav {
      padding: 0;
      padding-top: 8px;
      padding-left: 40px;
      width: 15vw;
    }
  }
  .logo {
    padding: 0;
    h1,
    p {
      margin: 0;
      text-align: center;
    }
    h1 {
      font-size: 1.5em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      background-color: $black;
      padding: 10px 0;
    }
    p,
    md-input-container {
      padding: 0;
      background-color: $dark-black;
      color: $white;
      text-align: center;
      margin: 0;
    }
  }
  .nav-footer {
    background-color: $lightblue;
    position: absolute;
    bottom: 5vh;
    padding-top: 0;
    width: 15vw;
    p {
      margin: 0;
    }
  }
  .md-menu-bar {
    display: inline-flex;
  }
  .nav-title {
    text-transform: uppercase;
    font-size: 0.95em;
    margin: auto 0;
  }
  i {
    font-size: 1.6em;
    margin-right: 10px;
  }
  br {
    display: none;
  }
  md-divider {
    background-color: $white;
  }
  a {
    color: $white;
    img {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.menu-nav {
  position: absolute;
  left: 15vw;
}

.view-container {
  height: calc(100vh - 45px); // display: none !important;
  // padding-top: 1.6vh;
  background-color: #758185; //padding-left: 30px;
  //max-width: 85vw;
  //margin: 0 0 0 15vw;
  //padding: 1vw;
  overflow-y: auto;
  .content-container {
    max-height: 99%;
    padding-top: 1vh;
    background-color: #758185 !important;
  }
}

.footer-component {
  background-color: $logo-orange;
  color: $white;
  width: 100vw;
  min-height: 45px;
  text-align: center;
  z-index: 75;
  .logo {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    margin-left: 16px;
  }
  .plug {
    font-size: 12px;
    font-weight: 500;
  }
  span {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    letter-spacing: 0.7px;
  }
  .plug-logo {
    margin-right: 8px;
    font-weight: 500;
  }
}

.leaflet-top,
.leaflet-bottom {
  z-index: 75;
}

.md-select-menu-container {
  z-index: 1001;
}

.sys-eff img {
  width: 3vw;
  height: 3vw;
  margin: 5px;
  max-height: 40px;
  max-width: 40px;
  // position: absolute;
  // top: 0vh;
}

.legend {
  text-transform: capitalize;
}

.site-select {
  text-transform: capitalize;
}

.site-option {
  text-transform: capitalize;
}

.onpeak-icon {
  width: 100%;
  position: relative;
  background-color: $darkblue;
  color: $white;
  display: block;
  text-align: right;
  padding-right: 3%;
  font-weight: 500;
  padding: 0.5% 2% 2% 2%;
}

.xtick {
  font-size: 10px !important;
}

.tabsdemoDynamicHeight md-content {
  background-color: transparent !important;
}

.tabsdemoDynamicHeight md-content md-tabs {
  background: #f6f6f6;
  border: 1px solid #e1e1e1;
}

md-tabs md-tabs-wrapper {
  background: #3d4345 !important;
  span {
    color: $white;
  }
}
.tabsdemoDynamicHeight md-content md-tabs md-tabs-wrapper {
  background: #3d4345 !important;
}

.tabsdemoDynamicHeight md-content h1:first-child {
  margin-top: 0;
}

.toolbardemoBasicUsage md-toolbar md-icon.md-default-theme {
  color: white;
}

.rta-box-control {
  // background-color: $dark-black;
  padding-right: 5px;
  a,
  a:hover {
    text-decoration: none;
    color: $white;
    float: right;
    padding: 8px;
    margin: 0;
    cusor: pointer;
  }
  md-icon {
    float: right;
    color: $white;
  }
}

.md-padding {
  padding: 0;
}

md-toolbar.md-hue-2:not(.md-menu-toolbar) {
  // background-color: $light-blue;
  background-color: #2d3133;
  min-height: 0;
}

.md-toolbar-tools {
  height: 40px;
}

.menu-list {
  background-color: $light-blue;
  min-height: fit-content;
  max-height: unset;
  span {
    color: $white;
    font-size: calc(0.35rem + 1.75vmin);
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    text-shadow: 1px 1px 1px $black;
  }
  a:hover {
    text-decoration: none !important;
    border-bottom: 1.5px solid white;
  }
  .logout-btn {
    border-top: $red solid 2px;
    color: #fff;
    font-size: calc(0.35rem + 1.75vmin);
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    text-shadow: 1px 1px 1px #4f4f51;
    &:hover {
      background-color: $red;
      border-top: none;
    }
  }
}

@import url("//fonts.googleapis.com/css?family=Arimo");
.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-car .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-car
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  -moz-border-radius: 0.34em;
  -webkit-border-radius: 0.34em;
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #4371a0;
  color: $white;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  -moz-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, $secondaryblue),
    color-stop(40%, $secondaryblue),
    color-stop(60%, $lightblue),
    color-stop(80%, $secondaryblue),
    color-stop(100%, $secondaryblue)
  );
  background-image: -moz-linear-gradient(
    top,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  background-image: linear-gradient(
    to bottom,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  padding: 0 0.15em;
}

.odometer.odometer-auto-theme .odometer-digit:first-child,
.odometer.odometer-theme-car .odometer-digit:first-child {
  -moz-border-radius: 0.2em 0 0 0.2em;
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em 0 0 0.2em;
}

.odometer.odometer-auto-theme .odometer-digit:last-child,
.odometer.odometer-theme-car .odometer-digit:last-child {
  -moz-border-radius: 0 0.2em 0.2em 0;
  -webkit-border-radius: 0;
  border-radius: 0 0.2em 0.2em 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjZWVlMGQzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiNiYmFhOWEiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, $secondaryblue),
    color-stop(40%, $secondaryblue),
    color-stop(60%, $lightblue),
    color-stop(80%, $secondaryblue),
    color-stop(100%, $secondaryblue)
  );
  background-image: -moz-linear-gradient(
    top,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  background-image: linear-gradient(
    to bottom,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  color: $white;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.counter-data {
  font-size: calc(1rem + 0.4vmin);
  font-weight: 500;
  .counter-name {
    font-size: calc(1rem + 0.4vmin);
    font-weight: 500;
  }
}
.info-dialog {
  .info-title {
    background-color: $logo-orange;
    .info-ava {
      margin-left: 10px;
      padding: 7px 0;
      font-size: 24px;
      color: white;
      text-shadow: 1px 1px 1px #b04506;
      text-align: center;
      font-weight: 500;
      sup {
        font-size: 5px;
        vertical-align: super;
        letter-spacing: 0.5px;
        color: black;
        text-shadow: none;
        top: -1.5em;
      }
    }
    .content-title {
      font-size: 24px;
      text-transform: capitalize;
      font-weight: 500;
      color: $white;
    }
    .info-btn {
      background-color: $light-blue;
      span {
        text-shadow: 2px 2px 2px #000;
        font-size: 1.2em;
      }
    }
  }

  .md-dialog-content {
    background-color: $white;
    color: $black;
    li {
      font-size: 20px;
      font-weight: 500;
      list-style-type: square;
      letter-spacing: 0.5px;
    }
  }
}

md-content {
  background-color: $white;
}
.toggled {
  transform: rotate(0deg) !important;
}
.sami-list {
  background: none !important;
  padding-bottom: 0;
}

.report-popup-dialog {
  width: 60%;
  height: 90%;
  max-height: 100%;
  .report-app-frame {
    height: 100%;
  }
  md-icon {
    margin: auto;
  }
  .report-header {
    background-color: $darkblue;
    // padding:10px 0;
    .report-close {
      color: white;
    }
    .header-text {
      color: white;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .md-nav-bar {
    // background-color: $logo-orange !important;
    .md-button.md-accent {
      color: $logo-orange !important;
    }
    md-nav-ink-bar {
      background-color: $logo-orange;
      color: $logo-orange;
    }
  }
  .pdf-view {
    background-color: rgb(63, 63, 63);
  }
  .report-week__nav{
    padding-right:20px;
  }
}

.certi-popup-dialog {
  height: 70vh;
  width: 60vw;
}

.quiz-popup-dialog {
  width: 60%;
  height: 80%;
  max-height: 100%;
  .quiz-header {
    background-color: $darkblue;
    // padding:10px 0;
    .quiz-close {
      color: white;
    }
    .header-text {
      color: white;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .isCorrect_true {
    background-color: green;
    color: white;
  }

  .isCorrect_false {
    background-color: red;
    color: white;
  }
  .isCorrectAns_true {
    background-color: green;
    color: white;
  }
  .elv-review__block{
    padding:16px;
    ol {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.3px;
      margin-left: 10px;
    }
    .options-block {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.elv-icon{
  margin: 0 16px 0 20px;
}
