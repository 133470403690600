.view-container {
    .md-dialog-container {
        z-index: -80;
    }
    width: 100vw;
    .bg-blue {
        background-color: $gaugeblue;
    }
    .bg-green {
        background-color: $gaugegreen;
    }
    .bg-darkgreen {
        background-color: $gaugedarkgreen;
    }
    .bg-orange {
        background-color: $gaugeorange;
    }
    .bg-red {
        background-color: $gaugered;
    }
    .bg-yellow {
        background-color: $gaugeyellow;
    }
    .bg-purple {
        background-color: $gaugepurple;
    }
    .bg-blue {
        background-color: $gaugeblue;
    }
    .hide {
        display: none !important;
    }
}

.draglayer {
    margin-top: -20px !important;
}

td.pvtTotal.rowTotal {
    display: none;
}

td.pvtTotal.colTotal {
    display: none;
}

th.pvtTotalLabel {
    display: none;
}

td.pvtGrandTotal {
    display: none;
}

// .gauge {
//     text-align: center;
//     max-height: 20vh;
//     height: 20vh;
//     // height: 100px;
//     padding: 0;
// }
.todayschart {
    // display: none;
    width: 80vw;
    height: 80vh;
}

.gc {
    max-height: 20vh;
    height: 50%; // background-color: $darkblue;
    .ilp-gauge{
        text-align:center;
        height:200px;
        // padding-top:20px;
        u{
            font-weight:300 !important;
        }
        b{
            font-weight:500 !important;
        }
    }
}

.system-efficiency-container {
    // max-height: 870px;
    .system-efficiency-heading {
        height: 40px;
        margin: 0;
        background-color: #313941;
        h2 {
            text-align: center;
            background-color: #313941;
            color: white;
            font-size: 20px;
            margin: 0;
            padding-top: 0;
            letter-spacing: 1px;
        }
        md-radio-button.md-checked .md-off {
            border-color: $white;
        }
        md-radio-button .md-on {
            background-color: $orange;
        }
        md-radio-group {
            margin-right: 30px;
        }
        md-radio-group.layout-row md-radio-button {
            margin: 0 20px 0 20px;
        }
    }
}

.dashboard-container {
    z-index: 3 !important; //width: 85vw;
    overflow-y: scroll !important; //padding-top: 42px;
    .dashboard-charts-container {
        margin: 0;
        height: auto;
    }
    .chart-gauge {
        width: 100%; // margin: 100px auto  
    }
    #FlowValue-gague {
        .chart-filled {
            fill: rgb(31, 119, 180);
        }
    }
    #SpeedValue-gague {
        .chart-filled {
            fill: rgb(44, 160, 44);
        }
    }
    #TanklevelValue-gague {
        .chart-filled {
            fill: rgb(44, 160, 44);
        }
    }
    #Discharge_GateValue-gague,
    #Wet_WellValue-gague,
    #Well_LevelValue-gague {
        .chart-filled {
            fill: $gaugeorange;
        }
    }
    #PressureValue-gague {
        .chart-filled {
            fill: rgb(255, 127, 14);
        }
    }
    #HeadValue-gague {
        .chart-filled {
            fill: rgb(214, 39, 40);
        }
    }
    #CostValue-gague {
        .chart-filled {
            fill: rgb(148, 103, 189);
        }
    }
    .chart-filled {
        fill: steelblue;
    }
    .chart-empty {
        fill: #dedede;
    }
    .needle,
    .needle-center {
        fill: #464A4F;
    }
    svg {
        font: 10px sans-serif;
    }
    g.c3-chart-arcs {
        width: 70%;
        position: relative;
    }
    text.c3-gauge-value {
        font-size: 2em !important;
        display: none;
    }
    .nv-legend-symbol {
        display: none !important;
    }
    .legend-color-guide {
        display: none !important;
        div {
            background-color: none !important;
        }
    }
    .dashboard-gauge-sync-container {
        overflow-y: hidden;
            span {
                color: $black;
            }
        .md-active {
            background-color: #392f4e;
            color: $white;
            span {
                color: $white !important;
            }

        }
        md-tabs-canvas {
            background-color: $darkblue;
            border: none;
        }
        span {
            // font-weight: 500;
            // font-size: 14px;
        } // .dashboard-gauge-sync-heading {
        //     background-color: $blue;
        //     color: $white;
        //     text-align: center;
        //     font-weight: 700;
        //     height: 40px;
        //     z-index: 1;
        //     border-top-left-radius: 2px;
        //     border-top-right-radius: 2px;
        //     max-height: 38px;
        //     margin: 0;
        //     padding: 0;
        //     h2 {
        //         margin: 0;
        //         font-size: 16px;
        //         text-transform: capitalize;
        //         padding-top: 8px;
        //     }
        // }
        // .dashboard-sync-chart-container {
        //     padding: 15px 5px 15px 0;
        //     margin-left: -15px;
        // }
        md-tab-content {
            // height: 35vh;
        }
        .dashboard-gauge-container {
            height: 35vh;
            .gauges {
                height: 35vh;
                .gauge-view {
                    span {
                        display: block;
                        text-align: center;
                        line-height: 2px;
                        padding: 10%;
                    }
                    max-height: 5vh;
                }
            }
        }
        .gauge {
            // margin-bottom: 20px;
            // max-height: 14vh;
            color: $white;
            height: auto;
            text-align: center;
            .needle,
            .needle-center {
                fill: $white;
            }
            span:first-child {
                // line-height: 10%;
                padding: 2%;
            }
            .gauge-label {
                margin-bottom: 18px;
                display: block;
                text-align: center; // line-height: 1.2px;
                // margin-bottom: -10px;
                font-size: 1.2vmin;
                font-weight: 400; // font-size: 1.4vmin;
                span {
                    display: inline-block;
                    text-align: center; // line-height: 8px;
                    padding: 0;
                    letter-spacing: -.5px;
                    height: 0;
                    font-weight: bolder;
                    width: 33%;
                    &.left {
                        text-align: left;
                        text-transform: uppercase;
                    }
                    &.center {
                        text-align: center;
                    }
                    &.right {
                        text-align: right;
                    }
                }
            }
        }
        .gauge-units {
            display: block;
            width: 100%;
            text-align: center;
            font-weight: 400; // color: $darkblue;
            // font-size: 1.1vmin;
            //font-weight: bold;
            padding: 15% 0 15% 0; // margin-top: -10px;
            color: $white; //text-shadow: 0.25px 0.25px 0.25px #fff;
        }
    }
    .rta md-card {
        // height: auto;
        // max-height: 14vh;
        // min-height: 143px;
        display: inline-block;
        margin: 0 5px;
        .rta-box {
            width: 50%; // display: inline-block;
            float: left; // height: 85%;
        }
        .rta-box .flow-box,
        .rta-box .pressure-box {
            width: 100%;
            margin: 0;
            float: left;
            padding: 0; // height: 85%;
            h4 {
                color: $white;
                text-align: center; // height: 4vmin;
                // border-top-left-radius:6px;
                // border-top-right-radius:6px;
                margin: 0;
                padding: 0.5vmin 0;
                font-size: calc(0.35rem + 1.45vmin);
                font-weight: bolder;
            }
            span {
                margin: 0; // border: 1px solid grey;
                // width: 100%;
                font-size: calc(1.4rem + 3.45vmin);
                text-align: left;
                letter-spacing: 1px; // display: block;
                text-align: center;
                &.demand {
                    // min-width: 60%;
                    overflow: hidden; // padding-left: 35%;
                    .odometer.odometer-theme-minimal
                    {
                        display: inherit;
                        height: 1vh;
                        .odometer-value {
                            font-size: calc(1.4rem + 4.45vmin);
                            width: auto;
                            display: inline;
                        } // margin-left: 25%;
                    } // padding: 0.8vmin 0;
                    // padding: 10%;
                    // height: 8vmin;
                    // max-height: 20%;
                }
                &.units {
                    // padding: 0.2vmin 0;
                    // border: none; 
                    // border-bottom-left-radius:6px;
                    // border-bottom-right-radius:6px;
                    display: block;
                    color: white;
                    font-size: calc(1rem + 1vmin);
                    font-weight: bolder; // height: 4vmin;
                    padding: 0 0 4.5vmin 0.5vmin 0;
                }
            }
        }
        .flow-box {
            min-width: 60% !important;
            h4 {
                background-color: $darkblue; // height: 
            }
            span.demand {
                display: block;
                text-align: center;
                background-color: $blue-tint;
                padding: 0;
                padding-left: 10%;
            }
            .units {
                background-color: $darkblue;
            }
        }
        .pressure-box {
            h4 {
                background-color: $orange; // height: 
            }
            span.demand {
                background-color: $orange-tint;
                display: block;
                text-align: center; // background-color: $blue-tint;
                // padding-left: 10%;
            }
            .units {
                background-color: $orange;
                height: 100%;
            }
        }
        .rta-box .panel-pumps {
            width: 100%;
            display: inline-block;
            padding: 0;
            margin: 0;
            height: 12.8vmin;
            .wrapper {
                height: 69%; // padding-left: 1%;
                button {
                    background-color: $lightblue; // the follow is not dry 
                    // -webkit-border-radius: 10px;
                    border-radius: 50%;
                    border: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    display: inline-block;
                    font-family: Arial;
                    font-size: 10px;
                    text-transform: capitalize;
                    /*   padding: 5px 10px; */
                    text-align: center;
                    text-decoration: none;
                    width: 25px;
                    height: 25px;
                    padding: 2px 1px;
                    margin: 10px 0 0 18px;
                }
            }
            .panel-pumps-title {
                height: 32%;
                font-size: calc(0.35rem + 1.45vmin);
                display: block;
                width: 100%;
                color: $white;
                text-align: center;
                font-weight: bold;
                background-color: $darkblue;
            }
        }
        .rta-box .panel-cost {
            // width: auto;
            display: inline-block;
            padding: 0;
            margin: 0;
            font-size: calc(1rem + 1vmin);
            width: 100%;
            height: 11.8vmin;
            color: $white;
            text-align: center;
            background-color: $lightblue;
            .wrapper {
                // height: 93%;
                .odometer.odometer-theme-minimal {
                    margin-bottom: 25%;
                    font-size: calc(1.2rem + 3.5vmin);
                    text-align: center;
                    height: 100%;
                    min-width: 50%; // margin-left: 25%;
                }
            }
            .panel-pumps-title {
                height: 35%; // font-size: calc(0.35rem + .45vmin);
                display: block; // width: 100%;
                color: $white;
                text-align: center;
                font-weight: bold;
                background-color: $darkblue;
            }
        }
    }
    md-card {
        // overflow: hidden;
        // color: $primary;
        // height: 100%;
        // margin-bottom: 0;
        td {
            // text-align: center; !important;
            // padding-left: 15% !important;
            // word-wrap: break-word;
            // height: 3vh !important;
        }
        .rotate {
            border: none !important;
            height: 50px;
            /* Safari */
            -webkit-transform: rotate(45deg);
            /* Firefox */
            -moz-transform: rotate(45deg);
            /* IE */
            -ms-transform: rotate(45deg);
            /* Opera */
            -o-transform: rotate(45deg);
            /* Internet Explorer */
            // filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=3);
        }
        .card-heading {
            height: 20%;
            h3 {
                height: auto;
                margin: 0;
                padding: 0.8vmin 0;
                text-align: center;
                color: $white; // text-transform: uppercase;
                font-weight: bolder;
                font-size: calc(0.35rem + 1.45vmin);
                padding: 0.5vmin;
                text-transform: capitalize;
            }
        }
        .card-heading.blue {
            background-color: $secondaryblue;
        }
        .card-heading.dark-blue {
            background-color: $darkblue;
        }
        .card-heading.orange {
            background-color: $orange;
        }
        md-icon {
            float: left; // padding: 7px 10px;
            color: white;
        }
        td.table-heading {
            background-color: $blue !important;
            color: white;
            text-transform: uppercase;
            font-style: 1em;
            max-height: 8vh;
        }
    }
}

#history-view-system-efficiency h2 {
    background-color: $dark-orange;
    color: $white;
    text-align: center;
    margin: 0;
}

#myStaticDialog-sc-0 .js-plotly-plot,
#myStaticDialog-sc-1 .js-plotly-plot,
#myStaticDialog-sc-2 .js-plotly-plot {
    overflow: hidden;
    .plotly {
        margin-top: 25px;
    }
}

// @import url(https://fonts.googleapis.com/css?family=Rokkitt);
// * {
//   box-sizing: border-box;
// }
// body {
//   background: #e5e5e5;
// }
// ul {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   counter-reset: stairs 13;
//   padding: 0;
// }
// li {
//   clear: both;
//   width: 18px;
//   height: 18px;
//   border-radius: 50%;
//   margin:2.5px 14px;
//   background: #eaeaea;
//   position: relative;
//   counter-increment: stairs -1;
//   padding: 11px;
//   cursor: pointer;
//   display: inline-block;
//   box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.3), 0 0 5px rgba(0, 0, 0, 0.3);
// }
// li:active i:after {
//   background: #f0cb00;
// }
// li i {
//   width: 100%;
//   height: 100%;
//   display: block;
//   border-radius: inherit;
//   background: linear-gradient(#dadada, #fafafa);
//   position: relative;
// }
// li i:after {
//   position: absolute;
//   width: 6px;
//   height: 6px;
//   background: #969696;
//   left: 50%;
//   top: 50%;
//   margin: -3px 0 0 -3px;
//   content: "";
//   border-radius: inherit;
//   box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
// }
// li:after {
//   content: "";
//   position: absolute;
//   left: -3px;
//   top: -3px;
//   bottom: -3px;
//   right: -3px;
//   z-index: -1;
//   background: linear-gradient(#c3c3c3, #f4f4f4);
//   border-radius: inherit;
//   box-shadow: inset 0 1px 1px rgba(80, 80, 80, 0.1);
// }
// glowing buttons
.button-on {
    background-color: #089201;
    -webkit-border-radius: 10px;
    border-radius: 50%;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 12px;
    text-transform: capitalize;
    /*   padding: 5px 10px; */
    text-align: center;
    text-decoration: none;
    width: 25px;
    height: 25px;
    padding: 2px 1px;
    margin: 5px 10px;
}

// @-webkit-keyframes glowing {
//   0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
// }
// @-moz-keyframes glowing {
//   0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
// }
// @-o-keyframes glowing {
//   0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
// }
// pump panel text
// li:before {
// // content: counter(stairs);
// text-align: center;
// position: absolute;
// display: block;
// font-family: 'Rokkitt', serif;
// color: #999;
// font-size: 18px;
// top: 100%;
// margin: 5px -30%;
// transform: scaleY(1.15) scaleX(0.85);
// }
// default odometer
.odometer.odometer-auto-theme,
.odometer.odometer-theme-minimal {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-minimal .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
    display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-minimal .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.flow-prediction-container {
    max-height: 970px;
    .flow-prediction-heading {
        height: 40px;
        margin: 0;
        background-color: #313941;
        h2 {
            text-align: center;
            background-color: #313941;
            color: white;
            font-size: 20px;
            margin: 0;
            padding-top: 8px;
            letter-spacing: 0.5px;
        }
        .ado-info {
            float: right;
            color: $white;
            margin: 8px 5px 0;
        }
    }
}

.status-toast {
    .md-toast-content {
    background-color: $red;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-top: 32px;
    z-index: 1;
}
}

.md-center {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}

.md-whiteframe-12dp {
    box-shadow: 0px 15px 16px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.inner-wrapper {
    margin-left: 1.3px;
}

// table.md-table thead.md-head>tr.md-row {
//     height: 8px !important;
//     text-transform: capitalize !important;
// }
// table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2) {
//     padding: 0 7px 0 0 !important;
// }
// table.md-table th.md-column {
//     color: black !important;
//     font-weight: 600 !important;
// }
.padbottom {
    padding-bottom: 79px;
}

.md-toolbar-tools .md-select-value {
    border-bottom-color: rgb(63, 81, 181);
    color: white!important;
}

// .modebar-group {
//     display: none !important;
// }

// a.modebar-btn.plotlyjsicon.modebar-btn--logo {
//     display: none !important;
// }

.rta-card {
    height: auto;
    min-height: 2vh;
}

// [scroll] {
//     position: fixed;
//     top: 40px;
// }
.rta-load {
    font-size: larger;
    font-weight: 700;
}

.info-bar {
    background-color: $darkblue !important;
    min-height: 45px !important;
}

.info-h1 {
    margin: 0;
    text-align: center;
    font-size: 24px;
    padding-top: 12px;
}

.info-data {
    font-size: 13px;
    color: #EEF0F3;
    margin-left: 10px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 18px;
}

.info-toolbar {
    min-height: 0;
}

.info-content {
    background-color: $light-blue !important;
}

.info-heading {
    margin-top: 0;
    text-align: center;
    text-decoration: underline;
    font-size: large;
    font-weight: 700;
}

.close-info {
    background-image: linear-gradient(to right, #fd6811, #fe6c19, #fe711f, #ff7525, #ff792b);
    width: 95%;
    color: $white;
    &:hover {
        background-color: $logo-primary !important;
        color: $white !important;
    }
}

.rta-text {
    background-color: white;
    font-size: larger;
    font-weight: 700;
    max-height: 12vh;
    color: black;
    text-align: center;
    line-height: 11vh;
    height: auto;
    text-transform: capitalize;
    letter-spacing: 0.2px;
}

.area {
    animation: blur .3s ease-out 1; // text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
}

@keyframes blur {
    from {
        text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 50px #fff, 0px 0px 50px #fff, 0px 0px 50px #7B96B8, 0px 0px 150px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px -10px 100px #7B96B8, 0px -10px 100px #7B96B8;
    }
}

@media (max-width: 959px) {
    md-dialog.md-dialog-fullscreen {
        min-height: 0px;
        min-width: 0px;
        border-radius: 0;
    }
}

.first-card {
    text-align: center;
    max-height: fit-content;
    .flow-text,
    .flow-unit {
        background-color: $darkblue;
        color: $white;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.45rem + 1.55vmin);
        font-weight: bolder;
    }
    .pressure-text,
    .pressure-unit {
        color: $white;
        background-color: $orange;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.45rem + 1.55vmin);
        font-weight: bolder;
    }
    .flow-value,
    .pressure-value {
        font-size: calc(1.4rem + 2.5vw);
        letter-spacing: 2px;
        color: #4d5322;
        .odometer.odometer-theme-minimal
        {
            .odometer-value {
                font-size: calc(1.4rem + 5.45vmin);
            }
        }
    }
    .value-box {
        background-color: $blue-tint;
    }
}

.second-card {
    // text-align: center;
    max-height: fit-content;
    .second-card-heading {
        text-align: center;
        color: $white;
        font-weight: bolder;
        font-size: calc(0.35rem + 1.45vmin);
        text-transform: capitalize;
        background-color: $darkblue;
        margin: 0;
        padding: 0.5vmin 0;
    }
    .value-box {
        background-color: $light-blue;
        color: $white;
        text-align: center;
        .dmg-value {
            font-size: calc(1.4rem + 2.5vw);
            letter-spacing: 2px;
            margin: 0 2%;
        }
        .dmg-unit {
            font-size: calc(0.35rem + 1.75vmin);
            position: relative;
            top: 20%;
        }
    }
    .pump-btn {
        background-color: $lightblue;
        -webkit-border-radius: 10px;
        border-radius: 50%;
        border: none;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-family: Arial;
        font-size: calc(0.15rem + 1.1vmin);
        text-transform: uppercase;
        font-weight:700;
        /*   padding: 5px 10px; */
        text-align: center;
        text-decoration: none;
        width: 34px;
        height: 34px;
        padding: 2px 1px;
        margin: 5px 10px;
    }
    .pump-btn-on {
        background-color: #089201;
    }
    @keyframes glowing {
        0% {
            background-color: #089201;
            box-shadow: 0 0 1.5px #089201;
        }
        50% {
            background-color: #089201;
            box-shadow: 0 0 20px #089201;
        }
        100% {
            background-color: #089201;
            box-shadow: 0 0 1.5px #089201;
        }
    }
    .pump-btn-on {
        -webkit-animation: glowing 2000ms infinite;
        -moz-animation: glowing 2000ms infinite;
        -o-animation: glowing 2000ms infinite;
        animation: glowing 2000ms infinite;
    }
    .no-cpu {
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
    }
    .btn-flex {
        margin-left: 6%;
    }
    .second-card-footer {
        background-color: $darkblue;
        color: $white;
        padding: 9px;
        .update-time {
            text-align: center;
        }
    }
}

.third-card {
    min-height: 2vh !important;
    max-height: fit-content;
    .third-card-heading {
        height: auto;
        text-align: center;
        color: $white;
        font-weight: bolder;
        font-size: calc(0.35rem + 1.45vmin);
        text-transform: capitalize;
        background-color: $darkblue;
        margin: 0;
        padding: 0.4vmin 0;
        .rta-info {
            font-size: calc(0.35rem + 1.45vmin)!important;
            float: right;
        }
    }
    .op-manual {
        // background-color: $light-grey;
        background-color: $darkblue;
        h6 {
            // margin:0;
            background-color: $light-blue;
            margin: 0;
            color: $white;
            text-align: center;
            font-size: calc(0.30rem + 1.3vmin);
            font-weight: 500;
        }
        span {
            margin: 20px 30px 31px 30px;
            display: block;
            font-weight: 500;
            color: $white;
            text-align: center;
        }
    }
    .bg-rta {
        background-color: $light-blue;
        table.md-table thead.md-head>tr.md-row {
            height: 8px !important;
            text-transform: capitalize !important;
        }
        table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
        table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
            padding: 0;
        }
        table.md-table thead.md-head>tr.md-row th {
            height: 8px !important;
            text-transform: capitalize !important;
            background-color: $darkblue !important;
            padding: 0 !important;
        }
        table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2) {
            padding: 0 !important;
        }
        table.md-table th.md-column {
            color: $white !important;
            font-weight: 600 !important;
            text-align: center !important;
        }
        td.md-cell:nth-child(even),
        th.md-column:nth-child(even) {
            background-color: $light-blue !important;
            color: $white;
            text-align: center;
        }
        td.md-cell:nth-child(odd),
        th.md-column:nth-child(odd) {
            background-color: $light-blue !important;
            color: $white !important;
        }
        .md-row .md-column,
        .md-row .md-cell {
            text-align: center !important;
            padding: 0 !important; // padding-left: 1%;
            font-size: calc(0.27rem + 1vmin);
            font-weight: 700;
        }
        table.md-table tbody.md-body>tr.md-row,
        table.md-table tfoot.md-foot>tr.md-row {
            height: 2.3vh !important;
            text-transform: capitalize;
            text-align: left;
        }
    }
    .rta2-table {
        .bg-rta2 {
            table.md-table tbody.md-body>tr.md-row,
            table.md-table tfoot.md-foot>tr.md-row {
                height: 2.3vh !important;
                text-transform: capitalize;
            }
            table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
                padding: 0;
            }
            table>thead>tr>th {
                text-align: center;
            }
            table.md-table td.md-cell {
                text-align: center;
                padding: 0 !important;
            }
            table.md-table thead.md-head>tr.md-row {
                height: 8px !important;
                text-transform: capitalize !important;
                background-color: $darkblue !important;
                color: $white !important;
            }
            table.md-table th.md-column {
                color: $white !important;
                padding: 0;
            } // .row-colorGreen{
            //     background-color: silver;
            //     color:white !important;
            // }
            // .row-colorRed{
            //     background-color: Red;
            // }
            // .row-colorYellow{
            //     background-color:$logo-orange;
            // }
            .row-color {
                background-color: $light-blue;
            }
            table.md-table td.md-cell {
                color: $white;
                font-size: calc(0.27rem + 1vmin);
                font-weight: 600;
            }
            .Blink {
                animation: blinker 2s cubic-bezier(.9, 0, 1, 1) infinite alternate;
            }
            @keyframes blinker {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0.2;
                }
            }
            .text-danger {
                color: red !important;
            }

            .row-colorGreen{
                font-size:16px;
                color:$light-blue;
            }
            .row-colorRed{
                color: rgba(255, 0, 0, 1) !important;
                font-size:16px;
            }
            .row-colorYellow{
                // color:rgba(255, 0, 0, 0.5) !important;
                color:yellow!important;
                font-size:16px;
            }
        }
        .open-arrow {
            text-align: center;
            font-weight: 500;
            line-height: 0;
            font-size: 18px;
            background-color: $darkblue;
            color: $white;
        }
    }
}

.process-heading {
    font-size: calc(0.35rem + 1.75vmin) !important;
    font-weight: 500 !important;
    margin-top: 3px !important;
    text-transform: capitalize;
}

.rta-comment {
    position: absolute;
    margin: 3% 0 0 1%;
    font-weight: 500;
    color: #FFF;
    text-align: center;
    width: 15%;
    background-color: $light-blue;
    .rta-comment-text {
        background-color: $light-blue;
        text-transform: capitalize;
    }
}