.aif-page{
    padding-top:40px;
    md-card {
        background-color: #313941;
    }
    .map-radar{
        height:50vh !important;
    }
    md-card-title{
        padding: 8px;
    }
    md-card-content{
        padding:0 8px 8px 8px;
    }

    .radar-title{
        padding-left:10px;
    }
    .map-expand-icon{
        margin-top:6px;
    }
}
#embed-map #logo{
    display: none !important;
}

.map-expand-bar{
    background-color: #313941 !important;
}

.aif-header {
    background-color: #313941;
    color: $white;
    z-index: 50;
    width: 100%;
    // position: fixed;
    .logo {
        margin-left: 5px;
        display: inline-flex;
        text-align: center;
        .info-icon {
            color: $light-white;
            margin: 5px;
            font-size: calc(1vh + 1vmin);
            line-height: 2.2 !important;
        }
        h1 {
            font-size: calc(1vh + 1.2vmin);
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            color: $white;
        }
        h2 {
            color: white;
            margin-left: 4px;
            line-height: 0.7;
            font-size: calc(0.35rem + 1.75vmin);
            font-weight: 500;
            letter-spacing: .7px;
            margin: 18px 0 0 5px;
            text-transform: capitalize;
        }
    }
    .aif-home{
        color:$white;
        float: right;
        margin-right: 15px;
    }
    md-datepicker {
        background-color: #313941;
        color: $white;
        padding: 0;
        md-button {
            background: none;
        }
        md-icon {
            color: $white;
            margin:0 !important;
        }
        .md-datepicker-input {
            color: $white !important;
            font-weight: 500;
        }
        .md-datepicker-triangle-button .md-datepicker-expand-triangle, {
            color: $white !important;
        }
        .md-datepicker-input-container {
            border-bottom-style: none;
        }
    }
    .site-info {
        text-align: center;
        .site-name {
            text-transform: uppercase;
            margin-top: 2px;
            font-size: calc(2vh + 0.1vmin);
            font-weight: 800;
        }
        .user-name {
            text-transform: capitalize;
            font-size: calc(1vh + 0.1vmin);
            font-weight: 500;
        }
    }
    .md-primary {
        color: #FAFAFA;
        float: right;
        background-color: #4E361B;
        font-family: 'Montserrat', sans-serif;
        line-height: 40px;
        border-radius: 0;
        margin: 0;
        font-size: calc(1vh + 0.6vmin);
        font-weight: 700;
        text-transform: capitalize;
    }
}

.forecast-cards {
    margin-top: 2vh;
    .forecast-card {
        // background-color: $light-white;
        &:hover {
            // background-color: $secondaryblue;
            color: $logo-secondary;
        }
    }

    .md-button{
        -moz-transition: none;
    -webkit-transition: none !important;
    -o-transition: none;
    transition: none !important;
    }
}

.flow-card-bg {
    font-size: 2.1vh;
    font-weight: 700;
    background-color: $light-white;
    color: #313941 !important;
    &:hover {
        color: $light-white !important;
    }
}

.forecast-value {
    font-size: 4vh;
    line-height: 45px;
    background-color: $light-white;
    color: $logo-secondary !important;
    &:hover {
        color: $light-white !important;
    }
}

.card-text {
    font-size: calc(1vh + 0.5vmin);
    font-weight: 500;
}

.type-text {
    font-size: calc(0.6rem + 1.6vmin);
    text-transform: uppercase;
    font-weight: 700;
    padding-right: 8px
}
.type-unit {
    font-size: calc(0.6rem + 1vmin);
    font-weight: 500;
    padding-right: 8px
}

.text-head {
    // background: $logo-secondary;
    color: $light-white;
    // margin-bottom: 1vw;
}

.card-data {
    padding: 0;
    .data-content {
        padding: 10px 0;
        text-align: center;
        background-color: #69909A;
        // margin-top: 8px;
        color: $light-white;
        .text-flow {
            // margin-bottom: 7px;
        }
    }
    .data-content-tom {
        padding: 10px 0;
        text-align: center;
        background-color: #313941;
        // margin-top: 8px;
        color: $light-white;
        .text-flow {
            // margin-bottom: 7px;
        }
    }
}

.chart-button {
    background-color: $logo-secondary;
    color: $light-white;
    font-size: 12px;
    line-height: 2;
    &:hover {
        background-color: $logo-orange !important;
        color: $white !important;
    }
    .avg-plant {
        font-size: 16px !important;
    }
}

.pad-nill {
    padding: 0px !important;
}

// md-grid-tile {
//     -webkit-transition: all 1s ease-out 500s;
//     -moz-transition: all 1s ease-out 500ms;
//     -o-transition: all 1s ease-out 500ms;
//     transition: all 1s ease-out 500ms;
// }

.day-text {
    font-size: calc(1vh + 1.2vmin);
    font-weight: 500;
}

.forecast-text {
    font-size: calc(1vh + 0.8vmin);
    font-weight: 500;
}

.card-footer {
    background: rgba(0, 0, 0, 0.18) !important;
    margin-top: 18px;
    border-top: 0.5px dashed grey; 
}

.date-valid {
    margin-top: 8%;
}

// .date-card {
//     -ms-transform: rotate(270deg);
//     -webkit-transform: rotate(270deg);
//     transform: rotate(270deg);
//     margin-left: -30px;
//     float: left;
//     margin-top: 80px;
//     font-size: 16px !important;
//     text-decoration: underline;
//     position: absolute;
//     h4 {
//         margin: 0;
//         background: $light-white;
//     }
// }
.color-high {
    color: white !important;
    background-color: red !important;
    &:hover {
        color: $light-white !important;
    }
}

.color-low {
    color: white !important;
    background-color: green !important;
    &:hover {
        color: $light-white !important;
    }
}

.color-middle {
    background-color: $light-white !important;
    color: $dark-orange !important;
    &:hover {
        color: $dark-orange !important;
    }
}
.date-today {
    font-size: 14px;
    font-weight: 700;
    margin: 3px;
    color: #FFF;
}

#map-canvas {
    width: 100%;
    height: 50vh;
}

.aeris-attribution {
    display: none;
}

.rain {
    padding: 0 0 0 3px;
    position: absolute;
    right: 20vw;
    bottom: 53vh;
    color: white;
    img {
        width: 20vw;
    }
    h4 {
        margin: 0 0 10px;
        display: inline-block;
    }
}

.snow {
    padding: 0 0 0 3px;
    position: absolute;
    right: 2vw;
    bottom: 53vh;
    color: white;
    img {
        width: 10vw;
    }
    h4 {
        margin: 0 0 10px;
        display: inline-block;
    }
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib {
    display: none !important;
}

.map-heading {
    background-color: $darkblue;
    color: white;
    font-weight: 600;
    font-size: calc(1vh + 1.2vmin)

}

.map-select {
    margin: 10px;
    margin-bottom: 0;
    md-radio-button {
        .md-label span{
            color: white !important;
        }
    }
}
.map-select .md-off{
  border-color: gray !important;
}
.map-select .md-on{
  background-color: white !important;
}


md-content {
    background-color: rgba(0,0,0,0) !important;
}