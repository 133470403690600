.setting-container {
    width: 100%;
    // padding-top: 50px;
    md-card {
        padding: 0 !important;
        box-shadow: 0px 0px 0px;
        a.md-button.md-primary.md-raised,
        a.md-button.md-primary.md-fab,
        .md-button.md-primary.md-raised,
        .md-button.md-primary.md-fab {
            background-color: #D17E3E;
            float: right;
        }
        a.md-button.md-primary.md-raised:not([disabled]):hover,
        a.md-button.md-primary.md-fab:not([disabled]):hover,
        .md-button.md-primary.md-raised:not([disabled]):hover,
        .md-button.md-primary.md-fab:not([disabled]):hover {
            background-color: #E55921;
        }
        md-card-content {
            padding: 0;
            width: 100%;
            .alert-header {
                height: 40px;
                // background-image: linear-gradient(to left, #8b9dcc, #7083a7, #586a84, #435161, #313941);
                background-color: $dark-black;
                color: white;
                .alert-name {
                    font-size: 20px;
                    text-transform: uppercase;
                    letter-spacing: 0.2px;
                    font-weight: 500;
                }
            }
        }
    }
    .process-name {
        font-size: 18px;
        text-transform: capitalize;
        letter-spacing: 0.2px;
        font-weight: 500;
        vertical-align: middle;
        line-height: 3;
    }
    .card-title {
        background-color: $blue !important;
    }
    .card-out {
        background-color: $white;
    }
    md-checkbox.md-checked .md-icon {
        background-color: $logo-orange;
    }
    md-select .md-select-value {
        text-align: center;
    }
    .card-height {
        max-height: 40vh;
        overflow-y: scroll;
    }
    .icon-md {
        margin-top: 30px;
        font-size: 18px !important;
        margin-left: 10px;
    }
    md-checkbox[disabled].md-checked .md-icon {
        background-color: $logo-orange;
    }
    md-input-container.md-block {
        text-transform: capitalize;
    }
    .alert-catalog {
        padding-top: 0;
        .alert-catalog-header {
            height: 40px; // background-image: linear-gradient(to bottom, #2f2f30, #313134, #313439, #32363d, #313941);
            // background-image: linear-gradient(to left, #8b9dcc, #7083a7, #586a84, #435161, #313941);
            background-color: $dark-black;
            color: white;
            .alert-catalog-title {
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: 0.2px;
                font-weight: 500;
            }
        }
        table.md-table tbody.md-body>tr.md-row:nth-child(odd)>td {
            background-color: #e6e5e5 !important;
        }
        table.md-table tbody.md-body>tr.md-row>td {
            font-weight: 500;
        }
        table.md-table:not(.md-row-select) td.md-cell:first-child,td.md-cell:last-child{
            text-transform: capitalize;
        }
        table.md-table th.md-column {
            color: $black;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
    .daily-check{
        line-height:2.8;
        font-size: 18px;
        font-weight: 500;
    }
}