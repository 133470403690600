@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700italic,900,900italic,300italic,300,100italic,100,700);
@import url("//fonts.googleapis.com/css?family=Arimo");
.fullscreen-chart iframe,
#myHistoryDialog-db-1 iframe {
  width: 100%;
  height: 60vh; }

.fullscreen-chart iframe,
#myHistoryDialog-db-2 iframe {
  width: 100%;
  height: 65vh; }

md-card iframe {
  height: 28vh; }

.loading-gears.text {
  text-align: center;
  margin-left: 30px;
  display: block; }

.loading-gears.img {
  width: 4vw;
  height: auto;
  position: relative;
  left: 45%;
  margin-top: 2vh;
  margin-bottom: 0.2vh; }

.live-loading-gears.text {
  text-align: center;
  margin-left: 30px;
  display: block; }

.live-loading-gears.img {
  width: 4vw;
  height: auto;
  position: relative;
  margin-top: 2vh;
  margin-bottom: 0.2vh; }

md-tabs-content-wrapper {
  min-height: 60vh; }

.unit-name {
  text-transform: uppercase; }

md-tab-canvas md-tab-item#md-active {
  background-color: #499fd4;
  color: white; }
  md-tab-canvas md-tab-item#md-active span {
    text-shadow: 2px 2px 5px Black; }

md-tab-canvas md-tab-item#md-active,
md-tabs md-ink-bar {
  color: #499FD4;
  background-color: #499FD4; }

.fullscreen-chart .md-button,
.fullscreen-chart .chart-views .md-button {
  background-color: #499ED4;
  width: 20%;
  color: white; }

.fullscreen-chart .md-button span {
  text-shadow: 2px 2px 2px black; }

.myHistoryDialog > md-dialog > md-tooolbar,
.md-dialog-container .card-heading.dashboard {
  max-height: 2vh; }

.myHistoryDialog > md-dialog > md-tooolbar,
.md-dialog-container .card-heading {
  max-height: 7vh; }

md-radio-group {
  text-transform: capitalize;
  padding: 5px 0; }

.modebar-group svg {
  display: unset;
  width: unset;
  height: unset; }

.demo-dialog {
  width: 500px !important; }
  .demo-dialog .carousel-item {
    text-align: center !important; }
    .demo-dialog .carousel-item img {
      width: 500px !important; }
  .demo-dialog .ui-carousel {
    display: block;
    margin-bottom: 30px; }
  .demo-dialog .ui-carousel .carousel-wrapper {
    position: relative; }
  .demo-dialog .ui-carousel .track-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .demo-dialog .ui-carousel .track {
    position: relative;
    display: block;
    float: left; }
  .demo-dialog .ui-carousel .slide {
    float: left;
    height: 100%;
    min-height: 1px; }
  .demo-dialog .ui-carousel .carousel-btn {
    position: absolute;
    z-index: 10;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 20px;
    opacity: .75; }
  .demo-dialog .ui-carousel .carousel-btn:hover {
    opacity: 1; }
  .demo-dialog .ui-carousel .carousel-prev .carousel-btn {
    left: -25px; }
  .demo-dialog .ui-carousel .carousel-next .carousel-btn {
    right: -25px; }
  .demo-dialog .ui-carousel .carousel-disable {
    opacity: 0.5; }
  .demo-dialog .ui-carousel .carousel-disable .carousel-btn:hover {
    opacity: .75; }
  .demo-dialog .carousel-dots {
    position: absolute;
    bottom: -30px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center; }
  .demo-dialog .carousel-dots li {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
  .demo-dialog .carousel-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 15px;
    height: 15px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent; }
  .demo-dialog .carousel-dots li button:before {
    font-family: ui-carousel;
    font-size: 9px;
    line-height: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
    height: 15px;
    content: "\63";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased; }
  .demo-dialog .carousel-dots li.carousel-active button:before {
    opacity: .75; }

.aq-video-container .elv-video__container {
  padding: 6px; }

.aq-video-container .aq-video__header {
  background-color: #2d3133;
  height: 48px;
  color: white; }
  .aq-video-container .aq-video__header .aq-video__text {
    font-size: 20px;
    font-weight: 500;
    padding-left: 24px;
    letter-spacing: 0.5px; }
    .aq-video-container .aq-video__header .aq-video__text .elv-icon-page {
      margin: 0 0 5px 0;
      width: 28px;
      height: 28px; }

.aq-video-container .video-container {
  overflow: hidden; }

.aq-video-container .video-container video {
  min-width: 100%;
  max-height: 60vh; }

.aq-video-container .image-w {
  position: relative; }
  .aq-video-container .image-w .image {
    display: block;
    width: 100%;
    height: 130px; }
  .aq-video-container .image-w .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0);
    text-align: center;
    transition: all 1s linear;
    cursor: pointer; }
  .aq-video-container .image-w:hover .cover {
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.2s linear; }
  .aq-video-container .image-w:hover .elv-play__icon {
    color: #ff5722; }
  .aq-video-container .image-w .elv-play__icon {
    font-size: 30px;
    position: absolute;
    left: 0;
    bottom: 10px;
    text-align: center; }

.aq-video-container .video-list__content {
  padding: 4px; }
  .aq-video-container .video-list__content .video-list__content-tilte {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    letter-spacing: 0.3px; }
  .aq-video-container .video-list__content .video-list__app-name {
    font-size: 12px;
    font-weight: 500;
    color: #606060;
    margin: 4px 0;
    text-transform: uppercase; }
  .aq-video-container .video-list__content .video-list__new-tag {
    padding: 2px;
    background: rgba(251, 97, 8, 0.94);
    color: white;
    font-weight: 700;
    font-size: 11px;
    border-radius: 2px; }

.aq-video-container .elv-content__block {
  padding: 8px 16px;
  border-bottom: 1.2px #9e9e9e solid; }
  .aq-video-container .elv-content__block .elv-content__block-title {
    font-size: 18px;
    font-weight: 500;
    padding: 8px 0;
    letter-spacing: 0.3px; }
  .aq-video-container .elv-content__block .elv-content__block-date {
    color: #606060;
    font-weight: 500;
    font-size: 14px; }

.aq-video-container .isCorrect_true {
  background-color: green;
  color: white; }

.aq-video-container .isCorrect_false {
  background-color: red;
  color: white; }

.aq-video-container .elv-quiz__header {
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.3px; }

.aq-video-container ol {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-left: 30px; }

.aq-video-container .options-block {
  font-size: 16px;
  font-weight: 400; }

.aq-video-container .question {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding-bottom: 1px; }

.aq-video-container .elv-result__block {
  padding-bottom: 14px; }
  .aq-video-container .elv-result__block .elv-result__text {
    font-weight: 400;
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px; }
  .aq-video-container .elv-result__block .elv-fail__text {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500; }
  .aq-video-container .elv-result__block .elv-pass__text {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: green; }

.aq-video-container .elv-quiz__submit {
  padding-bottom: 14px; }

.aq-video-container .elv-selected {
  border: 2px #fd6811 solid;
  will-change: transform, box-shadow;
  transform: matrix(1.012, 0, 0, 1.012, 0, 0); }

.aq-video-container md-card {
  outline: none; }

.pass-top {
  padding: 30px 30px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #fbfbfb; }

.pass-icon {
  display: block;
  max-width: 200px;
  margin: 0 auto 30px; }

.pass-header {
  margin-bottom: 10px;
  font-size: 25.5px;
  letter-spacing: 2px;
  text-align: center; }

.pass-subheader {
  max-width: 350px;
  margin: 0 auto;
  font-size: 19px;
  line-height: 1.3;
  letter-spacing: 1.25px;
  text-align: center;
  color: #999; }

.u-imgResponsive {
  width: 60%;
  height: auto; }

#bottom {
  display: block;
  margin-top: 2000px; }

.aif-page {
  padding-top: 40px; }
  .aif-page md-card {
    background-color: #313941; }
  .aif-page .map-radar {
    height: 50vh !important; }
  .aif-page md-card-title {
    padding: 8px; }
  .aif-page md-card-content {
    padding: 0 8px 8px 8px; }
  .aif-page .radar-title {
    padding-left: 10px; }
  .aif-page .map-expand-icon {
    margin-top: 6px; }

#embed-map #logo {
  display: none !important; }

.map-expand-bar {
  background-color: #313941 !important; }

.aif-header {
  background-color: #313941;
  color: #FFF;
  z-index: 50;
  width: 100%; }
  .aif-header .logo {
    margin-left: 5px;
    display: inline-flex;
    text-align: center; }
    .aif-header .logo .info-icon {
      color: #FCEBE4;
      margin: 5px;
      font-size: calc(1vh + 1vmin);
      line-height: 2.2 !important; }
    .aif-header .logo h1 {
      font-size: calc(1vh + 1.2vmin);
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      color: #FFF; }
    .aif-header .logo h2 {
      color: white;
      margin-left: 4px;
      line-height: 0.7;
      font-size: calc(0.35rem + 1.75vmin);
      font-weight: 500;
      letter-spacing: .7px;
      margin: 18px 0 0 5px;
      text-transform: capitalize; }
  .aif-header .aif-home {
    color: #FFF;
    float: right;
    margin-right: 15px; }
  .aif-header md-datepicker {
    background-color: #313941;
    color: #FFF;
    padding: 0; }
    .aif-header md-datepicker md-button {
      background: none; }
    .aif-header md-datepicker md-icon {
      color: #FFF;
      margin: 0 !important; }
    .aif-header md-datepicker .md-datepicker-input {
      color: #FFF !important;
      font-weight: 500; }
    .aif-header md-datepicker .md-datepicker-triangle-button .md-datepicker-expand-triangle {
      color: #FFF !important; }
    .aif-header md-datepicker .md-datepicker-input-container {
      border-bottom-style: none; }
  .aif-header .site-info {
    text-align: center; }
    .aif-header .site-info .site-name {
      text-transform: uppercase;
      margin-top: 2px;
      font-size: calc(2vh + 0.1vmin);
      font-weight: 800; }
    .aif-header .site-info .user-name {
      text-transform: capitalize;
      font-size: calc(1vh + 0.1vmin);
      font-weight: 500; }
  .aif-header .md-primary {
    color: #FAFAFA;
    float: right;
    background-color: #4E361B;
    font-family: 'Montserrat', sans-serif;
    line-height: 40px;
    border-radius: 0;
    margin: 0;
    font-size: calc(1vh + 0.6vmin);
    font-weight: 700;
    text-transform: capitalize; }

.forecast-cards {
  margin-top: 2vh; }
  .forecast-cards .forecast-card:hover {
    color: #B04506; }
  .forecast-cards .md-button {
    -moz-transition: none;
    -webkit-transition: none !important;
    -o-transition: none;
    transition: none !important; }

.flow-card-bg {
  font-size: 2.1vh;
  font-weight: 700;
  background-color: #FCEBE4;
  color: #313941 !important; }
  .flow-card-bg:hover {
    color: #FCEBE4 !important; }

.forecast-value {
  font-size: 4vh;
  line-height: 45px;
  background-color: #FCEBE4;
  color: #B04506 !important; }
  .forecast-value:hover {
    color: #FCEBE4 !important; }

.card-text {
  font-size: calc(1vh + 0.5vmin);
  font-weight: 500; }

.type-text {
  font-size: calc(0.6rem + 1.6vmin);
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 8px; }

.type-unit {
  font-size: calc(0.6rem + 1vmin);
  font-weight: 500;
  padding-right: 8px; }

.text-head {
  color: #FCEBE4; }

.card-data {
  padding: 0; }
  .card-data .data-content {
    padding: 10px 0;
    text-align: center;
    background-color: #69909A;
    color: #FCEBE4; }
  .card-data .data-content-tom {
    padding: 10px 0;
    text-align: center;
    background-color: #313941;
    color: #FCEBE4; }

.chart-button {
  background-color: #B04506;
  color: #FCEBE4;
  font-size: 12px;
  line-height: 2; }
  .chart-button:hover {
    background-color: #fd6811 !important;
    color: #FFF !important; }
  .chart-button .avg-plant {
    font-size: 16px !important; }

.pad-nill {
  padding: 0px !important; }

.day-text {
  font-size: calc(1vh + 1.2vmin);
  font-weight: 500; }

.forecast-text {
  font-size: calc(1vh + 0.8vmin);
  font-weight: 500; }

.card-footer {
  background: rgba(0, 0, 0, 0.18) !important;
  margin-top: 18px;
  border-top: 0.5px dashed grey; }

.date-valid {
  margin-top: 8%; }

.color-high {
  color: white !important;
  background-color: red !important; }
  .color-high:hover {
    color: #FCEBE4 !important; }

.color-low {
  color: white !important;
  background-color: green !important; }
  .color-low:hover {
    color: #FCEBE4 !important; }

.color-middle {
  background-color: #FCEBE4 !important;
  color: #4E361B !important; }
  .color-middle:hover {
    color: #4E361B !important; }

.date-today {
  font-size: 14px;
  font-weight: 700;
  margin: 3px;
  color: #FFF; }

#map-canvas {
  width: 100%;
  height: 50vh; }

.aeris-attribution {
  display: none; }

.rain {
  padding: 0 0 0 3px;
  position: absolute;
  right: 20vw;
  bottom: 53vh;
  color: white; }
  .rain img {
    width: 20vw; }
  .rain h4 {
    margin: 0 0 10px;
    display: inline-block; }

.snow {
  padding: 0 0 0 3px;
  position: absolute;
  right: 2vw;
  bottom: 53vh;
  color: white; }
  .snow img {
    width: 10vw; }
  .snow h4 {
    margin: 0 0 10px;
    display: inline-block; }

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib {
  display: none !important; }

.map-heading {
  background-color: #313941;
  color: white;
  font-weight: 600;
  font-size: calc(1vh + 1.2vmin); }

.map-select {
  margin: 10px;
  margin-bottom: 0; }
  .map-select md-radio-button .md-label span {
    color: white !important; }

.map-select .md-off {
  border-color: gray !important; }

.map-select .md-on {
  background-color: white !important; }

md-content {
  background-color: transparent !important; }

.ss-container {
  margin: 0 !important;
  padding: 1vh 0 0 0 !important; }
  .ss-container md-card md-input-container.md-block {
    margin: 0; }
    .ss-container md-card md-input-container.md-block md-select {
      width: 6vw; }
  .ss-container md-card.table-container {
    padding: 0;
    overflow: hidden; }
    .ss-container md-card.table-container td.table-cell-heading {
      font-weight: bolder; }
      .ss-container md-card.table-container td.table-cell-heading span {
        text-transform: none; }
  .ss-container md-card a.md-button.md-primary.md-raised,
  .ss-container md-card a.md-button.md-primary.md-fab,
  .ss-container md-card .md-button.md-primary.md-raised,
  .ss-container md-card .md-button.md-primary.md-fab {
    background-color: #D17E3E; }
  .ss-container md-card a.md-button.md-primary.md-raised:not([disabled]):hover,
  .ss-container md-card a.md-button.md-primary.md-fab:not([disabled]):hover,
  .ss-container md-card .md-button.md-primary.md-raised:not([disabled]):hover,
  .ss-container md-card .md-button.md-primary.md-fab:not([disabled]):hover {
    background-color: #E55921; }
  .ss-container md-card md-card-content.content-table {
    padding: 0;
    width: 100%; }
  .ss-container md-card .table-heading,
  .ss-container md-card tbody td {
    text-transform: capitalize; }
  .ss-container md-card md-toolbar {
    text-align: center;
    text-transform: capitalize; }
  .ss-container md-card:nth-child(1) .card-heading {
    background-color: #878628; }
  .ss-container md-card:nth-child(2) .card-heading {
    background-color: #DB6161; }
  .ss-container md-card:nth-child(3) .card-heading {
    background-color: #499ED4; }
  .ss-container md-card:nth-child(4) .card-heading {
    background-color: #A19F27; }
  .ss-container md-card:nth-child(5) .card-heading {
    background-color: #216087; }

.frame3 md-radio-group md-radio-button checked .md-ink-ripple {
  color: #878628; }

.frame3 md-radio-group md-radio-button .md-off {
  border-color: #878628; }

md-content form {
  margin-left: 2vh; }

.operating-container .icon-expand {
  display: none; }

.pump_health_analytics-container,
.pump-health-container {
  width: 100%; }
  .pump_health_analytics-container #pha-14 md-card,
  .pump-health-container #pha-14 md-card {
    height: auto; }
  .pump_health_analytics-container md-card,
  .pump-health-container md-card {
    max-height: 50vh;
    height: 40vh; }
    .pump_health_analytics-container md-card table,
    .pump-health-container md-card table {
      width: 100%; }
    .pump_health_analytics-container md-card md-toolbar,
    .pump-health-container md-card md-toolbar {
      text-align: center; }

table.pvtTable tr th {
  background-color: #cdcdcd;
  border: 1px solid #000;
  font-size: 8pt;
  padding: 5px;
  font-weight: 500; }

.pvtVal {
  font-size: 14px !important; }

md-icon {
  margin: 8px 0 0 8px; }

.pscardTitle {
  font-size: 1.0em !important;
  text-align: center !important;
  max-width: 100% !important; }

.pscardTitle-1 {
  font-size: 1.0em !important;
  text-align: center !important;
  margin-right: 5% !important; }

.table {
  margin-bottom: 1px !important; }

table > thead > tr > th {
  text-align: center; }

.pump-row {
  text-transform: uppercase; }

.ip-header {
  padding: 30px 16px 0 0; }

.stats-table table.md-table th.md-column,
.stats-table table.md-table td.md-cell {
  text-align: center !important;
  padding: 0 !important;
  font-size: 14px !important; }

.stats-table table.md-table tbody.md-body > tr.md-row {
  height: 30px !important;
  text-align: center !important; }

.stats-table table.md-table thead.md-head > tr.md-row {
  height: 30px !important; }

.pump-name,
.pump-list {
  background-color: #fd6711;
  color: #4f4f51;
  text-transform: uppercase; }

.unit-name {
  font-size: calc(0.6rem + 1.2vmin);
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
  margin-top: 20px; }

.unit-list {
  text-transform: uppercase;
  font-size: calc(0.6rem + 1.2vmin);
  font-weight: 500; }
  .unit-list:hover {
    background-color: #FFF !important;
    color: #fd6811; }

md-select-menu md-content md-option[selected] {
  background-color: #FFF !important; }

.status-dialog img {
  width: 200px !important;
  height: auto; }

.status-actions .close-status, .status-actions .status-home {
  background-color: #fd6811 !important;
  color: #FFF; }

.status-actions .status-chart {
  background-color: #17749A !important;
  color: #FFF; }

md-toast.md-center {
  left: 50% !important;
  transform: translate3d(-50%, 0, 0) !important;
  z-index: 70 !important; }

.md-tab {
  color: white !important; }

.aq-lab-container form {
  margin: 0;
  padding: 4px; }

.aq-lab-container button.md-button.md-fab md-icon {
  display: inline; }

.aq-lab-container .aq-lab-header {
  min-height: 40px !important;
  max-height: 40px;
  padding: 0;
  background-color: #313941 !important; }
  .aq-lab-container .aq-lab-header .aq-lab-title {
    font-size: calc(0.35rem + 1.85vmin);
    color: #FFF;
    font-weight: 500;
    padding: 0 8px; }

.aq-lab-container md-icon {
  margin: 0;
  color: white; }

.aq-lab-container .md-button {
  margin: 0; }

.aq-lab-container a.is-active:after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 86px;
  height: 43px;
  margin-top: -1px;
  margin-left: -43px;
  background: radial-gradient(circle at bottom left, transparent 0, transparent 68.5%, #fff 70.5%), radial-gradient(circle at bottom right, transparent 0, transparent 68.5%, #fff 70.5%);
  background-position: 0 100%, 100% 100%, 100% 0, 0 0;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  transform: scaleY(-1) scale(0.35);
  margin-top: -28.8px; }

.aq-lab-container .uda-chart-list ul li {
  display: inline;
  background-color: #17749A;
  position: relative;
  color: white; }
  .aq-lab-container .uda-chart-list ul li:hover {
    background-color: #17749A;
    cursor: pointer; }
    .aq-lab-container .uda-chart-list ul li:hover:after {
      border-top: #17749A; }
  .aq-lab-container .uda-chart-list ul li .current {
    background-color: #fd6811;
    color: white; }

.aq-lab-container .uda-container md-sidenav {
  width: 280px; }

.aq-lab-container .uda-container .uda-chart-options {
  text-align: center;
  background-color: #6A7B8E; }
  .aq-lab-container .uda-container .uda-chart-options .current {
    background-color: #fd6811;
    color: #FFF; }
  .aq-lab-container .uda-container .uda-chart-options .time_chart_div,
  .aq-lab-container .uda-container .uda-chart-options .scatter_plot_div,
  .aq-lab-container .uda-container .uda-chart-options .bar_chart_div,
  .aq-lab-container .uda-container .uda-chart-options .favorite_div {
    border: 1px solid #fff; }
    .aq-lab-container .uda-container .uda-chart-options .time_chart_div:hover,
    .aq-lab-container .uda-container .uda-chart-options .scatter_plot_div:hover,
    .aq-lab-container .uda-container .uda-chart-options .bar_chart_div:hover,
    .aq-lab-container .uda-container .uda-chart-options .favorite_div:hover {
      background-color: #17749A; }

.aq-lab-container .uda-container .uda-form md-input-container {
  margin-bottom: 0 !important; }
  .aq-lab-container .uda-container .uda-form md-input-container .md-input {
    width: 95%; }

.aq-lab-container .uda-container .uda-form .favorite-table {
  background-color: #17749A; }
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table thead.md-head > tr.md-row {
    height: 8px !important;
    text-transform: capitalize !important; }
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table:not(.md-row-select) td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table:not(.md-row-select) th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
    padding: 0; }
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table thead.md-head > tr.md-row th {
    height: 8px !important;
    text-transform: capitalize !important;
    background-color: #313941 !important;
    padding: 0 !important; }
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table:not(.md-row-select) td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
    padding: 0 !important; }
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table th.md-column {
    color: #FFF !important;
    font-weight: 600 !important;
    text-align: center !important; }
  .aq-lab-container .uda-container .uda-form .favorite-table td.md-cell:nth-child(even),
  .aq-lab-container .uda-container .uda-form .favorite-table th.md-column:nth-child(even) {
    background-color: #17749A !important;
    color: #FFF;
    text-align: center; }
  .aq-lab-container .uda-container .uda-form .favorite-table td.md-cell:nth-child(odd),
  .aq-lab-container .uda-container .uda-form .favorite-table th.md-column:nth-child(odd) {
    background-color: #17749A !important;
    color: #FFF !important; }
  .aq-lab-container .uda-container .uda-form .favorite-table .md-row .md-column,
  .aq-lab-container .uda-container .uda-form .favorite-table .md-row .md-cell {
    text-align: center !important;
    padding: 0 !important;
    font-size: calc(0.27rem + 1vmin);
    font-weight: 700; }
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table tbody.md-body > tr.md-row,
  .aq-lab-container .uda-container .uda-form .favorite-table table.md-table tfoot.md-foot > tr.md-row {
    height: 2.3vh !important;
    text-align: left; }

.aq-lab-container .uda-container .uda-form md-expansion-panel md-expansion-panel-collapsed,
.aq-lab-container .uda-container .uda-form md-expansion-panel-header .md-expansion-panel-header-container {
  padding: 0 0 0 8px;
  line-height: unset;
  font-weight: 500;
  min-height: 30px; }

.aq-lab-container .uda-container .uda-form .favorite-container .fav-header {
  text-transform: capitalize; }

.aq-lab-container .uda-container .uda-form .favorite-container md-icon {
  color: #4f4f51; }

.aq-lab-container .uda-container .uda-form md-expansion-panel md-expansion-panel-expanded md-expansion-panel-content {
  padding: 4px 8px; }

.aq-lab-container .uda-container .chart-form-content {
  max-height: calc(100vh - 130px); }

.aq-lab-container .uda-container .uda-chart-card {
  width: 100%;
  height: calc(100vh - 146px); }
  .aq-lab-container .uda-container .uda-chart-card .loading-chart {
    padding-top: calc(50vh - 80px); }
  .aq-lab-container .uda-container .uda-chart-card #chartdiv {
    width: 100%;
    height: calc(100vh - 146px); }

.aq-lab-container .uda-container .close-uda-form {
  position: fixed;
  bottom: 4px;
  right: 4px;
  background-color: #D41F22;
  width: 30px;
  min-height: 30px;
  height: 30px;
  line-height: 30px; }
  .aq-lab-container .uda-container .close-uda-form md-icon {
    margin: -4px; }
  .aq-lab-container .uda-container .close-uda-form:hover {
    background-color: red; }

.aq-lab-container .uda-container .form-row-delete {
  font-size: 13px; }
  .aq-lab-container .uda-container .form-row-delete .md-mini {
    width: 24px;
    height: 24px;
    line-height: 24px; }
  .aq-lab-container .uda-container .form-row-delete .md-button {
    min-height: 24px;
    padding: 0;
    vertical-align: middle; }
  .aq-lab-container .uda-container .form-row-delete .material-icons {
    font-size: 16px;
    font-weight: 900; }

.aq-lab-container .aq-lab-form-panel {
  padding-top: 6px; }
  .aq-lab-container .aq-lab-form-panel .md-title {
    max-width: unset !important; }

.aq-lab-container .amcharts-amexport-label-level-1 {
  border: 2px solid #777; }

.aq-lab-container .amcharts-amexport-item.amcharts-amexport-item-level-0 {
  width: fit-content;
  background-color: transparent !important; }

.aq-lab-container #vericalToolBar {
  background-color: #17749A;
  width: 50px; }

.aq-lab-container #vericalToolBar md-icon {
  color: rgba(255, 255, 255, 0.87); }

.aq-lab-container #toolBarTitle {
  writing-mode: tb-rl;
  transform: rotate(180deg);
  color: rgba(255, 255, 255, 0.87); }

.aq-lab-container .open-side {
  background-color: #313941;
  margin-bottom: 4px;
  width: 30px !important;
  min-height: 30px !important;
  height: 30px !important;
  line-height: 30px !important; }
  .aq-lab-container .open-side md-icon {
    margin: -2px; }
  .aq-lab-container .open-side:hover {
    background-color: #fd6811 !important; }

.md-virtual-repeat-container {
  min-width: 300px !important;
  margin-top: 20px; }

aqs-chat {
  padding: 0 !important; }

md-backdrop.md-sidenav-backdrop {
  z-index: 998; }

.md-sidenav-right {
  background-color: #758185;
  overflow-y: scroll;
  z-index: 1001; }
  .md-sidenav-right md-content {
    padding: 0;
    background-color: #17749A !important; }
    .md-sidenav-right md-content form .row {
      background-color: #6A7B8E;
      width: 25vw;
      height: 20vw; }
      .md-sidenav-right md-content form .row textarea {
        margin: 5px 5px; }
    .md-sidenav-right md-content .send-button {
      width: 100px;
      margin: 0 0 0 5px;
      width: 309px;
      background-color: #0982CB;
      color: #EEF0F3;
      text-align: center;
      font-size: 25px;
      letter-spacing: 1px; }
      .md-sidenav-right md-content .send-button md-icon {
        margin: 0;
        width: 8px;
        height: 8px;
        margin-right: 16px;
        margin-bottom: 20px;
        color: lightgray !important; }
  .md-sidenav-right .chat-header {
    background-color: #fd6811;
    height: 38px;
    display: flex;
    justify-content: center; }
    .md-sidenav-right .chat-header h1 {
      margin: 0;
      padding: 7px 0;
      font-size: 24px;
      color: white;
      text-shadow: 1px 1px 1px #B04506;
      text-align: center; }
      .md-sidenav-right .chat-header h1 sup {
        font-size: 5px;
        vertical-align: super;
        letter-spacing: 0.5px;
        color: black;
        text-shadow: none;
        top: -1.5em; }
    .md-sidenav-right .chat-header img {
      width: 93px; }

.onbutton, .offbutton {
  margin: 0 !important;
  border-radius: 0; }

.onbutton {
  background-color: #fff;
  color: #4f4f51; }

.sami-btn {
  font-size: 24px;
  text-align: center; }
  .sami-btn sup {
    font-size: 5px;
    vertical-align: super;
    letter-spacing: 0.5px;
    color: black;
    text-shadow: none;
    top: -1.5em; }

.offbutton {
  background-color: #4f4f51;
  color: #FFF; }

ul.collection {
  max-height: 76vh;
  overflow-y: scroll !important;
  margin: 0; }
  ul.collection.long {
    max-height: 88vh;
    padding: 0;
    width: 100% !important;
    padding: 0 8%; }
    ul.collection.long li.message:hover {
      background-color: #4f4f51;
      color: #FFF;
      position: relative; }
      ul.collection.long li.message:hover i {
        color: #FFF;
        font-size: 1.5em;
        padding: 0;
        margin: 0; }
      ul.collection.long li.message:hover a {
        position: absolute;
        background-color: #fd6811;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        padding-top: 4%;
        color: white;
        font-weight: bold;
        font-size: 1.3em; }
  ul.collection.short {
    max-height: 76vh; }
  ul.collection div.chat-link {
    text-align: center;
    height: 100%; }
    ul.collection div.chat-link a {
      color: #4f4f51; }
  ul.collection div.chat-link:hover {
    paddin: 100vmin;
    background-color: #6A7B8E; }
    ul.collection div.chat-link:hover a {
      color: white; }
  ul.collection .message-user {
    float: left;
    padding-right: 2px;
    margin-left: 2px;
    font-weight: bolder; }
  ul.collection .message-date {
    color: #ededed;
    font-size: 0.7em;
    margin-left: 3px;
    text-transform: capitalize;
    font-weight: normal;
    position: absolute;
    right: 10px;
    top: 3px; }
  ul.collection .message-content {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    word-wrap: break-word; }
  ul.collection .message-bubble {
    background-color: white;
    border-radius: 15px; }
  ul.collection .news {
    height: 100%; }
  ul.collection .news-icon-stats {
    color: #4f4f51; }
  ul.collection .news-icon-alerts {
    color: #D41F22; }
  ul.collection li.news {
    overflow: hidden;
    display: block;
    width: 100%;
    max-height: 100%;
    margin: 2vmin 0;
    background-color: #FFF;
    border-radius: 4px;
    padding: 1.5vmin; }
  ul.collection .news.ng-move,
  ul.collection .news.ng-enter {
    transition: all linear 2s; }
  ul.collection .news.ng-leave {
    transition: all linear 0.3s; }
  ul.collection .news.ng-leave,
  ul.collection .news.ng-move,
  ul.collection .news.ng-enter {
    transition: all linear 1s; }
  ul.collection .news.ng-leave .news-icon,
  ul.collection .news.ng-move .news-icon,
  ul.collection .news.ng-enter .news-icon {
    transition: all linear 1s; }
  ul.collection .news.ng-enter-stagger {
    transition-delay: 0.2s;
    /* As of 1.4.4, this must always be set: it signals ngAnimate
        to not accidentally inherit a delay property from another CSS class */
    transition-duration: 0s; }
  ul.collection .news.ng-leave.ng-leave-active,
  ul.collection .news.ng-move,
  ul.collection .news.ng-enter {
    opacity: 0; }
  ul.collection .news.ng-leave,
  ul.collection .news.ng-move.ng-move-active,
  ul.collection .news.ng-enter.ng-enter-active {
    opacity: 1; }

.triangle-right {
  position: relative;
  padding: 5px;
  margin: 0.7em 0 0.7em;
  color: #fff;
  background: #658085;
  /* default background for browsers without gradient support */
  /* css3 */
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#2e88c4), to(#658085));
  background: -moz-linear-gradient(#2e88c4, #658085);
  background: -o-linear-gradient(#2e88c4, #658085);
  background: linear-gradient(#2e88c4, #658085);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

/* Variant : for top positioned triangle
------------------------------------------ */
.triangle-right.top {
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#658085), to(#2e88c4));
  background: -moz-linear-gradient(#658085, #2e88c4);
  background: -o-linear-gradient(#658085, #2e88c4);
  background: linear-gradient(#658085, #2e88c4); }

/* Variant : for left positioned triangle
------------------------------------------ */
.triangle-right.left {
  margin-left: -10px;
  background: #658085;
  margin-right: 30px; }

/* Variant : for right positioned triangle
------------------------------------------ */
.triangle-right.right {
  margin-right: 30px;
  background: #CB7909;
  margin-left: -10px; }

.triangle-right:after {
  content: "";
  position: absolute;
  bottom: -20px;
  /* value = - border-top-width - border-bottom-width */
  left: 50px;
  /* controls horizontal position */
  border-width: 20px 0 0 20px;
  /* vary these values to change the angle of the vertex */
  border-style: solid;
  border-color: #658085 transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0; }

/* Variant : top
------------------------------------------ */
.triangle-right.top:after {
  top: -20px;
  /* value = - border-top-width - border-bottom-width */
  right: 50px;
  /* controls horizontal position */
  bottom: auto;
  left: auto;
  border-width: 20px 20px 0 0;
  /* vary these values to change the angle of the vertex */
  border-color: transparent #658085; }

/* Variant : left
------------------------------------------ */
.triangle-right.left:after {
  top: 16px;
  left: -30px;
  /* value = - border-left-width - border-right-width */
  bottom: auto;
  border-width: 15px 30px 0 0;
  /* vary these values to change the angle of the vertex */
  border-color: transparent #658085; }

/* Variant : right
------------------------------------------ */
.triangle-right.right:after {
  top: 16px;
  right: -30px;
  /* value = - border-left-width - border-right-width */
  bottom: auto;
  left: auto;
  border-width: 15px 0 0 30px;
  /* vary these values to change the angle of the vertex */
  border-color: transparent #CB7909; }

.types button {
  margin: 0 !important;
  width: 100%; }

.types .type-all {
  padding: 0 3.5%;
  font-size: 20px;
  background: #fd6811;
  border-radius: 0;
  color: white;
  font-weight: 500;
  min-width: 50px;
  text-transform: capitalize !important; }

.types .stat-type {
  font-size: 20px;
  background: #4f4f51;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.types .alert-type {
  font-size: 20px;
  background: #D41F22;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.types .mgt-type {
  font-size: 20px;
  background: #00B04F;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.types .industry-type {
  font-size: 20px;
  background: #558DD5;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.news-card {
  color: white; }
  .news-card .toggle-icon {
    margin-left: auto;
    vertical-align: middle;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .news-card:focus {
    outline: none; }
  .news-card .news-header {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px; }

.news-stats {
  background: #4f4f51; }

.news-alerts {
  background: #D41F22; }

.news-management {
  background: #00B04F; }

.news-industry {
  background: #558DD5; }

md-badge {
  margin: 0 0 0 8px;
  background-color: #259b24;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 16px; }

.new-chat {
  background-color: red; }

.ANN {
  background-color: #758185; }

.ann-width {
  min-width: 200px !important;
  width: 85vw !important;
  max-width: 640px !important; }

.no-news {
  margin-top: 15px;
  color: #FFF;
  text-transform: capitalize; }
  .no-news .no-news-icon {
    line-height: 2; }
  .no-news .no-news-text {
    font-size: 16px;
    font-weight: 500; }

.elv-ch-container .elv-ch__header {
  background-color: #2d3133;
  height: 48px;
  color: white; }
  .elv-ch-container .elv-ch__header .elv-ch__text {
    font-size: 20px;
    font-weight: 500;
    padding-left: 24px;
    letter-spacing: 0.5px; }

.elv-ch-container .elv-ch-catalog .elv-ch-catalog-header {
  height: 20px; }
  .elv-ch-container .elv-ch-catalog .elv-ch-catalog-header .elv-ch-catalog-title {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 500; }

.elv-ch-container .elv-ch-catalog table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
  background-color: #e6e5e5 !important; }

.elv-ch-container .elv-ch-catalog table.md-table tbody.md-body > tr.md-row > td {
  font-weight: 500; }

.elv-ch-container .elv-ch-catalog table.md-table:not(.md-row-select) td.md-cell:first-child,
.elv-ch-container .elv-ch-catalog td.md-cell:last-child {
  text-transform: capitalize; }

.elv-ch-container .elv-ch-catalog table.md-table th.md-column {
  color: #4f4f51;
  font-size: 12px;
  text-transform: uppercase; }

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* ----------- iPad Pro 10.5" ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* ----------- iPad Pro 12.9" ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  tackle-meter, .chart-gauge {
    max-height: 65% !important; } }

* {
  font-family: "Roboto", sans-serif; }

aqs-app-header {
  width: 100%;
  max-height: 40px;
  height: 40px; }

md-content.layout-wrap._md.layout-row {
  background: none; }

md-select.md-default-theme .md-select-icon,
md-select .md-select-icon {
  color: white; }

.bg-S,
.bg-ALL {
  background-color: #3c3c3c !important;
  color: #FFF; }
  .bg-S.off,
  .bg-ALL.off {
    background-color: grey !important; }
  .bg-S.on,
  .bg-ALL.on {
    background-color: #3c3c3c !important; }
  .bg-S.bg-S:hover,
  .bg-ALL.bg-S:hover {
    color: #4f4f51; }

.bg-A {
  background-color: #D41F22 !important;
  color: #FFF; }
  .bg-A.off {
    background-color: grey !important; }
  .bg-A.on {
    background-color: #D41F22 !important; }
  .bg-A.bg-A:hover {
    color: #4f4f51; }

.bg-M {
  background-color: #00b04f !important;
  color: #FFF; }
  .bg-M.off {
    background-color: grey !important; }
  .bg-M.on {
    background-color: #00b04f !important; }
  .bg-M.bg-M:hover {
    color: #4f4f51; }

.bg-I {
  background-color: #658085 !important;
  color: #FFF; }
  .bg-I.off {
    background-color: grey !important; }
  .bg-I.on {
    background-color: #658085 !important; }
  .bg-I.bg-I:hover {
    color: #4f4f51; }

.shell {
  animation: none;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 0;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  backface-visibility: visible;
  background: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-size: auto auto;
  border: 0;
  border-style: none;
  border-width: medium;
  border-color: inherit;
  border-bottom: 0;
  border-bottom-color: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image: none;
  border-left: 0;
  border-left-color: inherit;
  border-left-style: none;
  border-left-width: medium;
  border-radius: 0;
  border-right: 0;
  border-right-color: inherit;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top: 0;
  border-top-color: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: inherit;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-rule-color: currentColor;
  column-rule-style: none;
  column-rule-width: none;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font: normal;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 0;
  outline: 0;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: none;
  overflow-x: none;
  overflow-y: none;
  padding: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "“" "”" "‘" "’";
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: inherit;
  text-align-last: auto;
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-style: flat;
  transition: none;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 0;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  /* basic modern patch */
  all: initial;
  all: unset; }
  .shell.header {
    width: 85vw;
    float: right; }

.leaflet-control-attribution {
  display: none !important; }

.fullscreen-chart form {
  margin: 5px 0 0 5px; }

.fullscreen-chart .demo-tab form {
  margin: 0px 0 0 12px; }

.fullscreen-chart h2 {
  margin-top: 10px; }

.fullscreen-chart .card-heading {
  display: inline-flex;
  width: 85vw; }
  .fullscreen-chart .card-heading img {
    width: 3vw;
    height: 3vw;
    margin: 5px;
    max-height: 40px;
    max-width: 40px;
    position: absolute;
    top: 0vh; }

.dashboard-container h2 {
  margin-top: 10px; }

.dashboard-container .card-heading.dashboard img {
  max-height: 40px;
  max-width: 40px;
  height: 4vh;
  width: 4vw;
  margin: 5px; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .chat-header h1 {
    padding: 0.7vh 0 0 0; }
  .fullscreen-chart h2 {
    margin-top: 10px; }
  .fullscreen-chart .card-heading img {
    width: 3vw;
    height: 4vh;
    margin: 10px; } }

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .fullscreen-chart h2 {
    margin-top: 10px; }
  .fullscreen-chart .card-heading img {
    width: 3vw;
    height: 4vh;
    margin: 10px; } }

/* 1.3 dpr */
@media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
  .fullscreen-chart h2 {
    margin-top: 10px; }
  .fullscreen-chart .card-heading img {
    width: 3vw;
    height: 4vh;
    margin: 10px; } }

/* 1.5 dpr */
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .fullscreen-chart h2 {
    margin-top: 10px; }
  .fullscreen-chart .card-heading img {
    width: 4.5vw;
    height: auto;
    margin: 10px; } }

.pumplist {
  height: auto;
  min-height: 20vh;
  display: block; }

.foreground {
  z-index: 81; }

.background {
  z-index: 78; }

#myStaticDialog-sc-0,
#myStaticDialog-sc-1,
#myStaticDialog-sc-2 {
  width: 100vw !important;
  height: 100vh !important; }

.md-dialog-container .card-heading {
  background-color: #4f4f51;
  max-height: 12vh; }
  .md-dialog-container .card-heading h2 {
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    font-size: calc(1.2rem + 1.55vmin);
    margin: 0; }
    .md-dialog-container .card-heading h2 span {
      text-transform: none;
      font-size: 16px;
      font-weight: 700; }
  .md-dialog-container .card-heading button:hover {
    background-color: #D41F22; }
  .md-dialog-container .card-heading button {
    background-color: #1D2E41;
    color: #FFF;
    font-weight: bold;
    font-size: 1.2em;
    float: right; }
  .md-dialog-container .card-heading .title-content {
    font-weight: 500; }

.md-dialog-container #aqChart-1 {
  height: 77vh !important;
  display: grid;
  overflow-y: hidden; }

#pha-141 md-icon {
  display: none; }

.large-chart {
  height: 90vh; }
  .large-chart md-card {
    height: 90vh;
    max-height: 90vh !important;
    overflow: hidden; }
    .large-chart md-card iframe {
      height: 80vh !important;
      max-height: 80vh !important; }

control-card {
  z-index: 0; }
  control-card md-card {
    max-height: 40vh; }
    control-card md-card md-input-container {
      color: #FFF !important;
      padding: 0;
      margin: 0;
      float: right; }
      control-card md-card md-input-container md-select .md-select-value {
        height: 100%;
        border: none !important;
        margin-top: 2px;
        text-align: left;
        color: #FFF !important; }

.fade-out {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 0; }

.fade-in {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 1; }

.md-button.md-fab {
  background-color: none;
  widows: 22px; }

.card-info {
  position: absolute;
  top: 0px;
  right: 0px; }
  .card-info i {
    font-size: 1.5em; }

.card-table {
  width: 100%; }

md-card {
  overflow-y: hidden;
  border-radius: 4px; }
  md-card .table-row td {
    text-align: center; }
  md-card .table-row .table-cell-heading {
    text-align: left; }
  md-card md-content {
    border-radius: 4px;
    overflow-y: hidden; }
  md-card md-toolbar {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    background-color: #4E361B;
    color: #eef0f3;
    text-align: center;
    min-height: 40px; }
    md-card md-toolbar h2 {
      margin: 9px 0 0 0; }
      md-card md-toolbar h2.multi-pump-card-title {
        text-align: right;
        padding-right: 4px; }
  md-card .card-heading {
    background-color: #313638 !important; }
  md-card #chart-container {
    width: 100%; }
    md-card #chart-container .custom-controls {
      position: absolute;
      z-index: 1;
      padding-top: 4px;
      margin-left: 19vw; }
      @media screen and (max-width: 1024px) {
        md-card #chart-container .custom-controls {
          margin-left: 12%; } }
      @media screen and (min-width: 1280px) {
        md-card #chart-container .custom-controls {
          margin-left: 17%; } }
      @media screen and (min-width: 1440px) {
        md-card #chart-container .custom-controls {
          margin-left: 19%; } }
      md-card #chart-container .custom-controls .material-icons {
        font-size: 16px; }

.led-box {
  height: 30px;
  width: 25%;
  margin: 0 0 20px 0;
  float: left; }

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 0; }

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px; }

.led-gray {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #a6a6a6;
  border-radius: 50%;
  box-shadow: #e5e5e5 0 -1px 7px 1px, inset #006 0 -1px 9px, #e5e5e5 0 2px 14px; }

body {
  max-width: 100vw;
  overflow: hidden; }

div.pump {
  position: absolute;
  left: 0;
  width: 30%;
  animation: translate 7s infinite linear; }
  div.pump img {
    position: absolute;
    animation: upDown 0.7s alternate infinite ease-in-out;
    width: 5%; }

@keyframes upDown {
  to {
    transform: translatey(50px); } }

@keyframes translate {
  to {
    transform: translatex(450%); } }

.app-container {
  max-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0; }

.header-component {
  min-height: 45px;
  background-color: #fd6811;
  color: #FFF;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  max-height: 45px; }
  .header-component .greeting {
    float: left;
    font-weight: 600;
    padding: 0;
    max-height: 43px; }
    .header-component .greeting h5 {
      margin: 14px 0; }
  .header-component .logo {
    padding: 0; }
    .header-component .logo h1,
    .header-component .logo p {
      margin: 0;
      text-align: center; }
    .header-component .logo h1 {
      font-size: 1.8em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      padding: 7px 0;
      float: left;
      margin-left: 2px; }
    .header-component .logo a {
      color: #FFF;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      text-decoration: none !important; }
    .header-component .logo a:hover {
      text-decoration: none !important; }
  .header-component .apollo-home {
    text-decoration: none;
    color: #fff;
    padding: 8px;
    margin: 0; }
  .header-component .junk-icon {
    width: 100%; }
    .header-component .junk-icon div {
      padding: 0;
      margin: 0;
      height: auto;
      display: inline-block;
      min-width: 10px !important;
      width: 16px; }
  .header-component .notification-icon,
  .header-component .settings-icon,
  .header-component .ava-icon {
    margin: 10px;
    color: white;
    text-decoration: none; }
  .header-component .md-primary {
    color: #FAFAFA;
    float: right;
    font-family: "Montserrat", sans-serif;
    line-height: 40px;
    border-radius: 0;
    margin: 0;
    font-size: 1.2em;
    font-weight: 700;
    text-transform: capitalize; }
    .header-component .md-primary:hover {
      color: #4f4f51;
      background-color: #D41F22; }
  .header-component .site-info {
    text-align: center; }
    .header-component .site-info .site-name {
      text-transform: uppercase;
      margin-top: 2px;
      font-weight: 800; }
    .header-component .site-info .user-name {
      text-transform: capitalize;
      font-size: calc(1vh + 0.2vmin);
      font-weight: 500; }
  .header-component .bar-button {
    background-color: #01d8b7;
    color: #eef0f3;
    float: right;
    height: 5px;
    font-size: 9px;
    margin-top: 10px;
    padding: 0;
    /* width: 5%; */
    line-height: 6px;
    min-height: 22px;
    /* text-align: center; */
    text-shadow: 0.5px 0.5px 0.5px #000;
    /* position: relative; */
    margin-left: 5px; }
  .header-component img {
    height: auto;
    width: 20px;
    margin-right: 10px; }
    .header-component img:focus {
      outline: none !important; }
  .header-component .notifications {
    float: left;
    padding-top: 5px;
    height: auto;
    padding-left: 33%;
    display: inline-block; }
    .header-component .notifications .alert-box {
      border-radius: 50%;
      height: 28px;
      width: 30px; }
      .header-component .notifications .alert-box:hover {
        background-color: #D41F22; }
  .header-component .info-box {
    width: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #CB7909;
    overflow: hidden;
    padding-top: 9px;
    margin: 0;
    display: inline-block;
    transition: width 0.3s; }
  .header-component .counter-container {
    height: 43px;
    max-height: 43px;
    right: 0;
    float: right;
    width: auto;
    max-height: 43px; }
    .header-component .counter-container:hover {
      border: 1px solid #CB7909; }
      .header-component .counter-container:hover .info-box {
        height: auto;
        width: 100px; }
    .header-component .counter-container .data-box {
      float: right;
      display: inline-block;
      margin: 8px 10px; }
      .header-component .counter-container .data-box span.counter {
        float: left;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        height: auto; }
  .header-component .sami-icon {
    margin-left: 8px;
    margin-top: 2px;
    font-weight: 500; }

.middle-column {
  max-height: 2.5vmin !important;
  margin: auto 0 !important; }

.ant-wrapper {
  background-color: #4f4f51;
  width: 100%;
  overflow: hidden;
  max-height: 34px !important;
  /* Move it (define the animation) */ }
  .ant-wrapper .ant-container {
    margin-top: -5.8px; }
  .ant-wrapper md-menu button {
    border-color: none !important; }
  .ant-wrapper .spacer {
    height: 5vh;
    width: 100vw; }
  .ant-wrapper .ant-text-wrapper {
    position: absolute;
    left: 8%;
    z-index: 4 !important;
    padding: 0 15%;
    margin-right: 100px; }
  .ant-wrapper .ant-controls {
    z-index: 10;
    height: 35px; }
    .ant-wrapper .ant-controls button {
      border-radius: 2px;
      text-align: center;
      height: 100%;
      font-size: 22px !important;
      font-weight: 500;
      border: 1px solid orange !important;
      min-width: 2.4vw; }
    .ant-wrapper .ant-controls .ant-controls-primary {
      padding: 5px 15px; }
      .ant-wrapper .ant-controls .ant-controls-primary button {
        border-radius: 2px;
        height: 3vmin;
        margin: 0.3vmin 0 0 -100px; }
  .ant-wrapper .ant-clip {
    z-index: 20; }
    .ant-wrapper .ant-clip .ant-controls-secondary .md-button.md-icon-button {
      margin: 0; }
    .ant-wrapper .ant-clip .ant-controls-secondary .playPause {
      background-color: #4f4f51; }
    .ant-wrapper .ant-clip .ant-controls-secondary button {
      border-radius: 2px;
      text-align: center;
      height: 100%;
      font-size: 22px !important;
      font-weight: 500;
      border: 1px solid orange !important;
      min-width: 2.4vw; }
    .ant-wrapper .ant-clip .ant-controls-secondary i {
      vertical-align: middle; }
  .ant-wrapper .outer {
    position: absolute;
    left: 10vw;
    z-index: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 36px; }
  .ant-wrapper .left__inner {
    padding: 0px 4vmin;
    flex: 1;
    transform: skew(45deg);
    display: flex;
    justify-content: flex-end; }
  .ant-wrapper .right__inner {
    padding: 0px 4vmin;
    flex: 1;
    transform: skew(45deg); }
  .ant-wrapper .left__inner,
  .ant-wrapper .right__inner {
    white-space: nowrap; }
    .ant-wrapper .left__inner.off,
    .ant-wrapper .right__inner.off {
      display: none; }
    .ant-wrapper .left__inner.on,
    .ant-wrapper .right__inner.on {
      display: block; }
  .ant-wrapper .left__text,
  .ant-wrapper .right__text {
    transform: skew(-45deg); }
    .ant-wrapper .left__text span,
    .ant-wrapper .right__text span {
      color: white;
      font-size: 1.5em; }
  .ant-wrapper .left__text {
    color: #3c3c3c; }
  .ant-wrapper .right__text {
    color: Goldenrod; }
  .ant-wrapper .ant-text-wrapper {
    height: 5vh;
    position: relative;
    white-space: nowrap; }
  .ant-wrapper .ant-text-wrapper .outer,
  .ant-wrapper .ant-text-wrapper.single-remove-active .outer {
    height: 100%;
    margin: 0;
    text-align: center;
    /* Starting position */
    -moz-transform: translateX(14.3%);
    -webkit-transform: translateX(14.3%);
    transform: translateX(14.3%);
    /* Apply animation to this element */
    -moz-animation: ant-text-wrapper 180s 0s linear infinite;
    -webkit-animation: ant-text-wrapper 180s 0s linear infinite;
    animation: ant-text-wrapper 180s 0s linear infinite; }

@-moz-keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(14.3%); }
  100% {
    -moz-transform: translateX(-100%); } }

@-webkit-keyframes ant-text-wrapper {
  0% {
    -webkit-transform: translateX(14.3%); }
  100% {
    -webkit-transform: translateX(-100%); } }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(14.3%);
    /* Firefox bug fix */
    -webkit-transform: translateX(14.3%);
    /* Firefox bug fix */
    transform: translateX(14.3%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }
  .ant-wrapper .ant-text-wrapper .single {
    /* Move it (define the animation) */ }
    .ant-wrapper .ant-text-wrapper .single.short {
      -moz-animation: ant-text-wrapper 63s 0s linear infinite;
      -webkit-animation: ant-text-wrapper 63s 0s linear infinite;
      animation: ant-text-wrapper 63s 0s linear infinite; }
      .ant-wrapper .ant-text-wrapper .single.short .left__inner,
      .ant-wrapper .ant-text-wrapper .single.short .right__inner {
        white-space: nowrap;
        margin: 0 30vw 0 40vw; }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(100%);
    /* Firefox bug fix */
    transform: translateX(100%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }
    .ant-wrapper .ant-text-wrapper .single.long {
      -moz-animation: ant-text-wrapper 160s 0s linear infinite;
      -webkit-animation: ant-text-wrapper 160s 0s linear infinite;
      animation: ant-text-wrapper 160s 0s linear infinite; }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(100%);
    /* Firefox bug fix */
    transform: translateX(100%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }

@-moz-keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(25%); }
  100% {
    -moz-transform: translateX(-105%); } }

@-webkit-keyframes ant-text-wrapper {
  0% {
    -webkit-transform: translateX(25%); }
  100% {
    -webkit-transform: translateX(-105%); } }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(25%);
    /* Firefox bug fix */
    -webkit-transform: translateX(25%);
    /* Firefox bug fix */
    transform: translateX(25%); }
  100% {
    -moz-transform: translateX(-105%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-105%);
    /* Firefox bug fix */
    transform: translateX(-105%); } }

.paused,
.outer:hover,
.outer.single:hover {
  -webkit-animation-play-state: paused !important;
  -moz-animation-play-state: paused !important;
  -o-animation-play-state: paused !important;
  animation-play-state: paused !important; }

.ant-text-wrapper.closed {
  min-width: 0 !important;
  max-width: 0 !important;
  overflow: hidden !important;
  display: none; }

.ant-clip {
  margin: 0;
  transition: all 1.2s; }

.ant-wrapper {
  transition: all 1.2s;
  transition-delay: 1.2s; }

.sami-icon:focus,
.md-icon:focus,
span:focus,
button:focus {
  outline: none;
  border: 0; }

.ant-clip.closed {
  padding-right: 85.88vw;
  transform: scale(2); }
  .ant-clip.closed .ant-controls-secondary {
    display: none !important; }

.ant-wrapper.closed {
  overflow: hidden;
  background: none; }
  .ant-wrapper.closed md-menu {
    opacity: 1; }

.rta-box {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  border-radius: 0; }
  .rta-box .counters {
    max-height: 100px;
    margin: 0 !important; }
  .rta-box div {
    margin: 0; }
  .rta-box h1 {
    background-color: #404040;
    color: #fff;
    font-size: xlarge;
    /* width: 100%; */
    margin: 0px;
    padding: 1% 0 0 1%;
    text-transform: capitalize;
    /* height: 35px; */
    display: block;
    padding-bottom: 45px; }

.rta-box-title {
  height: 10px !important;
  padding: 0;
  font-size: calc(1vw + 1.5vmin); }

.rta-box-controls {
  background-color: #404040;
  padding-right: 5px; }
  .rta-box-controls a,
  .rta-box-controls a:hover {
    text-decoration: none;
    color: #FFF;
    float: right;
    padding: 8px;
    margin: 0;
    cusor: pointer; }
  .rta-box-controls md-icon {
    float: right;
    color: #FFF; }

.rta-box .flow-box,
.rta-box .pressure-box {
  margin: 0 5px;
  padding: 0; }
  .rta-box .flow-box h4,
  .rta-box .pressure-box h4 {
    color: #FFF;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder;
    letter-spacing: 0.8px; }
  .rta-box .flow-box span,
  .rta-box .pressure-box span {
    margin: 0;
    font-size: calc(0.35rem + 2.75vmin);
    text-align: left;
    letter-spacing: 1px;
    text-align: center; }
    .rta-box .flow-box span.demand,
    .rta-box .pressure-box span.demand {
      overflow: hidden;
      height: auto; }
      .rta-box .flow-box span.demand .odometer.odometer-theme-minimal,
      .rta-box .pressure-box span.demand .odometer.odometer-theme-minimal {
        display: inherit;
        height: 1vh; }
        .rta-box .flow-box span.demand .odometer.odometer-theme-minimal .odometer-value,
        .rta-box .pressure-box span.demand .odometer.odometer-theme-minimal .odometer-value {
          font-size: calc(1.4rem + 4.45vmin);
          width: auto;
          display: inline; }
    .rta-box .flow-box span.units,
    .rta-box .pressure-box span.units {
      display: block;
      color: white;
      font-size: calc(0.6rem + 0.75vmin);
      font-weight: bolder;
      height: auto;
      padding: 0 0 10px 0; }

.flow-box h4 {
  background-color: #313941; }

.flow-box span.demand {
  display: block;
  text-align: center;
  background-color: rgba(3, 48, 75, 0.03);
  padding: 0; }

.pressure-box h4 {
  background-color: #CB7909; }

.pressure-box span.demand {
  background-color: rgba(203, 121, 9, 0.04);
  display: block;
  text-align: center; }

.pressure-box .units {
  background-color: #CB7909;
  height: 100%; }

.rta-box .panel-pumps {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 13vmin; }
  .rta-box .panel-pumps .wrapper {
    height: 63%; }
    .rta-box .panel-pumps .wrapper button {
      background-color: #1D2E41;
      border-radius: 50%;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      font-family: Arial;
      font-size: 10px;
      text-transform: capitalize;
      /*   padding: 5px 10px; */
      text-align: center;
      text-decoration: none;
      width: 25px;
      height: 25px;
      padding: 2px 1px;
      margin: 10px 0 0 18px; }
  .rta-box .panel-pumps .panel-pumps-title {
    height: 32%;
    font-size: calc(0.35rem + 1.45vmin);
    display: block;
    width: 100%;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    background-color: #313941; }

.rta-box .panel-cost {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: calc(1rem + 1vmin);
  width: 100%;
  height: 11vmin;
  color: #FFF;
  text-align: center;
  background-color: #1D2E41; }
  .rta-box .panel-cost .wrapper {
    height: 75%; }
    .rta-box .panel-cost .wrapper .odometer.odometer-theme-minimal {
      margin-bottom: 25%;
      font-size: calc(1.4rem + 4.45vmin);
      text-align: center;
      height: 100%;
      min-width: 50%; }
  .rta-box .panel-cost .panel-pumps-title {
    height: 35%;
    display: block;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    background-color: #313941; }

.nav-component {
  background-color: #4f4f51;
  position: absolute;
  z-index: 999;
  color: #FFF;
  width: 15vw;
  height: 100vh;
  padding-top: 0;
  float: left;
  display: inline-block;
  -webkit-box-shadow: 12px 0 20px -2px #888;
  box-shadow: 12px 0 20px -2px #888; }
  .nav-component div {
    width: 100%;
    padding-top: 15px; }
  .nav-component md-icon {
    margin: 0 5px;
    color: #FFF; }
  .nav-component md-list-item {
    padding: 0; }
    .nav-component md-list-item div {
      padding: 0;
      width: 15vw;
      height: 48px; }
  .nav-component .navlist a:hover,
  .nav-component .navlist a:focus div {
    text-decoration: none; }
  .nav-component .navlist a:focus md-icon,
  .nav-component .navlist a:hover md-icon,
  .nav-component .navlist a:focus md-icon {
    color: #6A7B8E; }
  .nav-component .navlist a:hover div,
  .nav-component .navlist a:focus div {
    background-color: #FFF !important;
    cursor: pointer; }
  .nav-component .navlist a.active .nav-title,
  .nav-component .navlist a:hover .nav-title,
  .nav-component .navlist a.active .nav-title {
    font-size: 1.2em;
    font-weight: 700;
    color: #6A7B8E !important; }
  .nav-component .navlist .sub-nav {
    padding: 0;
    padding-top: 8px;
    padding-left: 40px;
    width: 15vw; }
  .nav-component .logo {
    padding: 0; }
    .nav-component .logo h1,
    .nav-component .logo p {
      margin: 0;
      text-align: center; }
    .nav-component .logo h1 {
      font-size: 1.5em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      background-color: #4f4f51;
      padding: 10px 0; }
    .nav-component .logo p,
    .nav-component .logo md-input-container {
      padding: 0;
      background-color: #404040;
      color: #FFF;
      text-align: center;
      margin: 0; }
  .nav-component .nav-footer {
    background-color: #1D2E41;
    position: absolute;
    bottom: 5vh;
    padding-top: 0;
    width: 15vw; }
    .nav-component .nav-footer p {
      margin: 0; }
  .nav-component .md-menu-bar {
    display: inline-flex; }
  .nav-component .nav-title {
    text-transform: uppercase;
    font-size: 0.95em;
    margin: auto 0; }
  .nav-component i {
    font-size: 1.6em;
    margin-right: 10px; }
  .nav-component br {
    display: none; }
  .nav-component md-divider {
    background-color: #FFF; }
  .nav-component a {
    color: #FFF; }
    .nav-component a img {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      margin-right: 10px; }

.menu-nav {
  position: absolute;
  left: 15vw; }

.view-container {
  height: calc(100vh - 45px);
  background-color: #758185;
  overflow-y: auto; }
  .view-container .content-container {
    max-height: 99%;
    padding-top: 1vh;
    background-color: #758185 !important; }

.footer-component {
  background-color: #fd6811;
  color: #FFF;
  width: 100vw;
  min-height: 45px;
  text-align: center;
  z-index: 75; }
  .footer-component .logo {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    margin-left: 16px; }
  .footer-component .plug {
    font-size: 12px;
    font-weight: 500; }
  .footer-component span {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    letter-spacing: 0.7px; }
  .footer-component .plug-logo {
    margin-right: 8px;
    font-weight: 500; }

.leaflet-top,
.leaflet-bottom {
  z-index: 75; }

.md-select-menu-container {
  z-index: 1001; }

.sys-eff img {
  width: 3vw;
  height: 3vw;
  margin: 5px;
  max-height: 40px;
  max-width: 40px; }

.legend {
  text-transform: capitalize; }

.site-select {
  text-transform: capitalize; }

.site-option {
  text-transform: capitalize; }

.onpeak-icon {
  width: 100%;
  position: relative;
  background-color: #313941;
  color: #FFF;
  display: block;
  text-align: right;
  padding-right: 3%;
  font-weight: 500;
  padding: 0.5% 2% 2% 2%; }

.xtick {
  font-size: 10px !important; }

.tabsdemoDynamicHeight md-content {
  background-color: transparent !important; }

.tabsdemoDynamicHeight md-content md-tabs {
  background: #f6f6f6;
  border: 1px solid #e1e1e1; }

md-tabs md-tabs-wrapper {
  background: #3d4345 !important; }
  md-tabs md-tabs-wrapper span {
    color: #FFF; }

.tabsdemoDynamicHeight md-content md-tabs md-tabs-wrapper {
  background: #3d4345 !important; }

.tabsdemoDynamicHeight md-content h1:first-child {
  margin-top: 0; }

.toolbardemoBasicUsage md-toolbar md-icon.md-default-theme {
  color: white; }

.rta-box-control {
  padding-right: 5px; }
  .rta-box-control a,
  .rta-box-control a:hover {
    text-decoration: none;
    color: #FFF;
    float: right;
    padding: 8px;
    margin: 0;
    cusor: pointer; }
  .rta-box-control md-icon {
    float: right;
    color: #FFF; }

.md-padding {
  padding: 0; }

md-toolbar.md-hue-2:not(.md-menu-toolbar) {
  background-color: #2d3133;
  min-height: 0; }

.md-toolbar-tools {
  height: 40px; }

.menu-list {
  background-color: #17749A;
  min-height: fit-content;
  max-height: unset; }
  .menu-list span {
    color: #FFF;
    font-size: calc(0.35rem + 1.75vmin);
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    text-shadow: 1px 1px 1px #4f4f51; }
  .menu-list a:hover {
    text-decoration: none !important;
    border-bottom: 1.5px solid white; }
  .menu-list .logout-btn {
    border-top: #D41F22 solid 2px;
    color: #fff;
    font-size: calc(0.35rem + 1.75vmin);
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    text-shadow: 1px 1px 1px #4f4f51; }
    .menu-list .logout-btn:hover {
      background-color: #D41F22;
      border-top: none; }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon {
  display: block; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-car .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0); }

.odometer.odometer-auto-theme
.odometer-digit
.odometer-value.odometer-last-value,
.odometer.odometer-theme-car
.odometer-digit
.odometer-value.odometer-last-value {
  position: absolute; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s; }

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up.odometer-animating
.odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
.odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  -moz-border-radius: 0.34em;
  -webkit-border-radius: 0.34em;
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #4371a0;
  color: #FFF; }

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  -moz-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #6A7B8E), color-stop(40%, #6A7B8E), color-stop(60%, #1D2E41), color-stop(80%, #6A7B8E), color-stop(100%, #6A7B8E));
  background-image: -moz-linear-gradient(top, #658085 0%, #658085 40%, #1D2E41 60%, #658085 80%, #658085 100%);
  background-image: -webkit-linear-gradient(top, #658085 0%, #658085 40%, #1D2E41 60%, #658085 80%, #658085 100%);
  background-image: linear-gradient(to bottom, #658085 0%, #658085 40%, #1D2E41 60%, #658085 80%, #658085 100%);
  padding: 0 0.15em; }

.odometer.odometer-auto-theme .odometer-digit:first-child,
.odometer.odometer-theme-car .odometer-digit:first-child {
  -moz-border-radius: 0.2em 0 0 0.2em;
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em 0 0 0.2em; }

.odometer.odometer-auto-theme .odometer-digit:last-child,
.odometer.odometer-theme-car .odometer-digit:last-child {
  -moz-border-radius: 0 0.2em 0.2em 0;
  -webkit-border-radius: 0;
  border-radius: 0 0.2em 0.2em 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjZWVlMGQzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiNiYmFhOWEiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #6A7B8E), color-stop(40%, #6A7B8E), color-stop(60%, #1D2E41), color-stop(80%, #6A7B8E), color-stop(100%, #6A7B8E));
  background-image: -moz-linear-gradient(top, #658085 0%, #658085 40%, #1D2E41 60%, #658085 80%, #658085 100%);
  background-image: -webkit-linear-gradient(top, #658085 0%, #658085 40%, #1D2E41 60%, #658085 80%, #658085 100%);
  background-image: linear-gradient(to bottom, #658085 0%, #658085 40%, #1D2E41 60%, #658085 80%, #658085 100%);
  color: #FFF; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0.15em; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
.odometer-ribbon-inner {
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear; }

.counter-data {
  font-size: calc(1rem + 0.4vmin);
  font-weight: 500; }
  .counter-data .counter-name {
    font-size: calc(1rem + 0.4vmin);
    font-weight: 500; }

.info-dialog .info-title {
  background-color: #fd6811; }
  .info-dialog .info-title .info-ava {
    margin-left: 10px;
    padding: 7px 0;
    font-size: 24px;
    color: white;
    text-shadow: 1px 1px 1px #b04506;
    text-align: center;
    font-weight: 500; }
    .info-dialog .info-title .info-ava sup {
      font-size: 5px;
      vertical-align: super;
      letter-spacing: 0.5px;
      color: black;
      text-shadow: none;
      top: -1.5em; }
  .info-dialog .info-title .content-title {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 500;
    color: #FFF; }
  .info-dialog .info-title .info-btn {
    background-color: #17749A; }
    .info-dialog .info-title .info-btn span {
      text-shadow: 2px 2px 2px #000;
      font-size: 1.2em; }

.info-dialog .md-dialog-content {
  background-color: #FFF;
  color: #4f4f51; }
  .info-dialog .md-dialog-content li {
    font-size: 20px;
    font-weight: 500;
    list-style-type: square;
    letter-spacing: 0.5px; }

md-content {
  background-color: #FFF; }

.toggled {
  transform: rotate(0deg) !important; }

.sami-list {
  background: none !important;
  padding-bottom: 0; }

.report-popup-dialog {
  width: 60%;
  height: 90%;
  max-height: 100%; }
  .report-popup-dialog .report-app-frame {
    height: 100%; }
  .report-popup-dialog md-icon {
    margin: auto; }
  .report-popup-dialog .report-header {
    background-color: #313941; }
    .report-popup-dialog .report-header .report-close {
      color: white; }
    .report-popup-dialog .report-header .header-text {
      color: white;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px; }
  .report-popup-dialog .md-nav-bar .md-button.md-accent {
    color: #fd6811 !important; }
  .report-popup-dialog .md-nav-bar md-nav-ink-bar {
    background-color: #fd6811;
    color: #fd6811; }
  .report-popup-dialog .pdf-view {
    background-color: #3f3f3f; }
  .report-popup-dialog .report-week__nav {
    padding-right: 20px; }

.certi-popup-dialog {
  height: 70vh;
  width: 60vw; }

.quiz-popup-dialog {
  width: 60%;
  height: 80%;
  max-height: 100%; }
  .quiz-popup-dialog .quiz-header {
    background-color: #313941; }
    .quiz-popup-dialog .quiz-header .quiz-close {
      color: white; }
    .quiz-popup-dialog .quiz-header .header-text {
      color: white;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px; }
  .quiz-popup-dialog .isCorrect_true {
    background-color: green;
    color: white; }
  .quiz-popup-dialog .isCorrect_false {
    background-color: red;
    color: white; }
  .quiz-popup-dialog .isCorrectAns_true {
    background-color: green;
    color: white; }
  .quiz-popup-dialog .elv-review__block {
    padding: 16px; }
    .quiz-popup-dialog .elv-review__block ol {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.3px;
      margin-left: 10px; }
    .quiz-popup-dialog .elv-review__block .options-block {
      font-size: 14px;
      font-weight: 400; }

.elv-icon {
  margin: 0 16px 0 20px; }

.login-container {
  height: calc(100vh - 45px);
  padding-top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

@keyframes spinner {
  0% {
    transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(359deg); } }
  .login-container * {
    box-sizing: border-box; }
  .login-container .logo-container {
    display: block;
    max-width: 450px;
    min-height: 100px;
    max-height: 100px; }
    .login-container .logo-container .logo {
      width: 290px;
      height: auto;
      margin: 0 auto;
      display: block; }
  .login-container .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%; }
  .login-container .login, .login-container .reset {
    border-radius: 2px 2px 5px 5px;
    padding: 10px 20px 20px 20px;
    width: 90%;
    max-width: 450px;
    background: #ffffff;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3); }
    .login-container .login.loading button, .login-container .reset.loading button {
      max-height: 100%;
      padding-top: 50px; }
      .login-container .login.loading button .spinner, .login-container .reset.loading button .spinner {
        opacity: 1;
        top: 40%; }
    .login-container .login.ok button, .login-container .reset.ok button {
      background-color: #8bc34a; }
      .login-container .login.ok button .spinner, .login-container .reset.ok button .spinner {
        border-radius: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        height: 20px;
        animation: none;
        transform: rotateZ(-45deg); }
    .login-container .login input, .login-container .reset input {
      display: block;
      padding: 20px 20px;
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      transition: border-width 0.2s ease;
      border-radius: 2px;
      color: #ccc; }
      .login-container .login input + i.fa, .login-container .reset input + i.fa {
        color: #fff;
        font-size: 1.8em;
        position: absolute;
        margin-top: -70px;
        opacity: 0;
        left: 0;
        transition: all 0.1s ease-in; }
      .login-container .login input:focus, .login-container .reset input:focus {
        outline: none;
        color: #444;
        border-color: #fd6811;
        border-left-width: 40px; }
        .login-container .login input:focus + i.fa, .login-container .reset input:focus + i.fa {
          opacity: 1;
          left: 30px;
          transition: all 0.25s ease-out; }
    .login-container .login a, .login-container .reset a {
      font-size: 0.8em;
      color: #fd6811;
      text-decoration: none; }
    .login-container .login .title, .login-container .reset .title {
      color: #444;
      font-size: 1.2em;
      font-weight: bold;
      margin: 10px 0 40px 0;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      text-align: center; }
    .login-container .login button, .login-container .reset button {
      width: 100%;
      height: 100%;
      padding: 10px 10px;
      background: #fd6811;
      color: #fff;
      display: block;
      border: none;
      margin-top: 20px;
      position: absolute;
      left: 0;
      bottom: 0;
      max-height: 60px;
      border: 0px solid rgba(0, 0, 0, 0.1);
      border-radius: 0 0 2px 2px;
      transform: rotateZ(0deg);
      transition: all 0.1s ease-out;
      border-bottom-width: 7px; }
      .login-container .login button .spinner, .login-container .reset button .spinner {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        border: 4px solid #ffffff;
        border-top-color: rgba(255, 255, 255, 0.3);
        border-radius: 100%;
        left: 50%;
        top: 0;
        opacity: 0;
        margin-left: -20px;
        margin-top: -20px;
        animation: spinner 0.6s infinite linear;
        transition: top 0.3s 0.3s ease, opacity 0.3s 0.3s ease, border-radius 0.3s ease;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2); }
    .login-container .login:not(.loading) button:hover, .login-container .reset:not(.loading) button:hover {
      box-shadow: 0px 1px 3px #fd6811; }
    .login-container .login:not(.loading) button:focus, .login-container .reset:not(.loading) button:focus {
      border-bottom-width: 4px; }
  .login-container footer {
    display: block;
    padding-top: 15px;
    text-align: center;
    color: #ddd;
    font-weight: normal;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
    font-size: 1.5em; }
    .login-container footer a, .login-container footer a:link {
      display: inline-block;
      width: 45%; }
      .login-container footer a:first-child, .login-container footer a:link:first-child {
        text-align: left; }
      .login-container footer a:last-child, .login-container footer a:link:last-child {
        text-align: right; }
  .login-container .button-title {
    color: white;
    font-size: 24px; }

.notification-container .pagination-table {
  color: white !important;
  background-color: #404040 !important;
  font-size: 16px; }
  .notification-container .pagination-table md-icon {
    margin: 0;
    color: white; }
  .notification-container .pagination-table md-select.md-table-select {
    margin: 20px 0 26px 0; }
  .notification-container .pagination-table .md-button[disabled] md-icon {
    color: red !important; }

.notification-container table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
  background-color: #e6e5e5 !important; }

.notification-container table.md-table tbody.md-body > tr.md-row > td {
  font-weight: 500; }

.notification-container table.md-table th.md-column {
  color: #4f4f51;
  font-size: 16px;
  text-transform: uppercase; }

.notification-container .no-results {
  padding: 0 16px; }

.notification-container .analysis-chart iframe {
  height: 60vh; }

.notification-container .notification-header {
  background-color: #404040;
  color: white;
  height: 40px; }
  .notification-container .notification-header .expand-icon {
    margin-left: 10px; }
  .notification-container .notification-header .notification-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .2px;
    margin-left: 5px; }

.notification-container .catalog-icon {
  margin-right: 10px; }

md-select-menu md-option {
  background-color: white !important;
  color: black; }

.ss-container {
  width: 85vw;
  padding-top: 50px; }
  .ss-container md-card md-input-container.md-block {
    margin: 0; }
    .ss-container md-card md-input-container.md-block md-select {
      width: 6vw; }
  .ss-container md-card.table-container {
    padding: 0;
    overflow: hidden; }
    .ss-container md-card.table-container td.table-cell-heading {
      font-weight: bolder; }
      .ss-container md-card.table-container td.table-cell-heading span {
        text-transform: none; }
  .ss-container md-card a.md-button.md-primary.md-raised,
  .ss-container md-card a.md-button.md-primary.md-fab,
  .ss-container md-card .md-button.md-primary.md-raised,
  .ss-container md-card .md-button.md-primary.md-fab {
    background-color: #D17E3E; }
  .ss-container md-card a.md-button.md-primary.md-raised:not([disabled]):hover,
  .ss-container md-card a.md-button.md-primary.md-fab:not([disabled]):hover,
  .ss-container md-card .md-button.md-primary.md-raised:not([disabled]):hover,
  .ss-container md-card .md-button.md-primary.md-fab:not([disabled]):hover {
    background-color: #E55921; }
  .ss-container md-card md-card-content.content-table {
    padding: 0;
    width: 100%; }
  .ss-container md-card .table-heading,
  .ss-container md-card tbody td {
    text-transform: capitalize; }
  .ss-container md-card md-toolbar {
    text-align: center;
    text-transform: capitalize; }
  .ss-container md-card:nth-child(1) .card-heading {
    background-color: #878628; }
  .ss-container md-card:nth-child(2) .card-heading {
    background-color: #DB6161; }
  .ss-container md-card:nth-child(3) .card-heading {
    background-color: #499ED4; }
  .ss-container md-card:nth-child(4) .card-heading {
    background-color: #A19F27; }
  .ss-container md-card:nth-child(5) .card-heading {
    background-color: #216087; }

.frame3 md-radio-group md-radio-button checked .md-ink-ripple {
  color: #878628; }

.frame3 md-radio-group md-radio-button .md-off {
  border-color: #878628; }

.permit-counter-container {
  padding: 0 !important;
  margin: 16px 16px 16px 0 !important; }
  .permit-counter-container .pra-counter-title {
    font-size: calc(0.8rem + 1vh);
    font-weight: 500;
    height: 40px;
    background-color: #313638;
    color: #FFF;
    text-align: center; }
  .permit-counter-container .pra-counter-value {
    font-size: calc(0.6rem + 3vw);
    font-weight: 400; }
  .permit-counter-container .pra-counter-unit {
    font-size: calc(0.6rem + 1vh);
    font-weight: 500;
    height: 40px;
    background-color: #fd6811;
    color: #FFF;
    margin-bottom: 5px; }
  .permit-counter-container .pra-counter-unit-1 {
    font-size: calc(0.6rem + 1vh);
    font-weight: 500;
    height: 40px;
    background-color: #fd6811;
    color: #FFF; }

.setting-container {
  width: 100%; }
  .setting-container md-card {
    padding: 0 !important;
    box-shadow: 0px 0px 0px; }
    .setting-container md-card a.md-button.md-primary.md-raised,
    .setting-container md-card a.md-button.md-primary.md-fab,
    .setting-container md-card .md-button.md-primary.md-raised,
    .setting-container md-card .md-button.md-primary.md-fab {
      background-color: #D17E3E;
      float: right; }
    .setting-container md-card a.md-button.md-primary.md-raised:not([disabled]):hover,
    .setting-container md-card a.md-button.md-primary.md-fab:not([disabled]):hover,
    .setting-container md-card .md-button.md-primary.md-raised:not([disabled]):hover,
    .setting-container md-card .md-button.md-primary.md-fab:not([disabled]):hover {
      background-color: #E55921; }
    .setting-container md-card md-card-content {
      padding: 0;
      width: 100%; }
      .setting-container md-card md-card-content .alert-header {
        height: 40px;
        background-color: #404040;
        color: white; }
        .setting-container md-card md-card-content .alert-header .alert-name {
          font-size: 20px;
          text-transform: uppercase;
          letter-spacing: 0.2px;
          font-weight: 500; }
  .setting-container .process-name {
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    font-weight: 500;
    vertical-align: middle;
    line-height: 3; }
  .setting-container .card-title {
    background-color: #658085 !important; }
  .setting-container .card-out {
    background-color: #FFF; }
  .setting-container md-checkbox.md-checked .md-icon {
    background-color: #fd6811; }
  .setting-container md-select .md-select-value {
    text-align: center; }
  .setting-container .card-height {
    max-height: 40vh;
    overflow-y: scroll; }
  .setting-container .icon-md {
    margin-top: 30px;
    font-size: 18px !important;
    margin-left: 10px; }
  .setting-container md-checkbox[disabled].md-checked .md-icon {
    background-color: #fd6811; }
  .setting-container md-input-container.md-block {
    text-transform: capitalize; }
  .setting-container .alert-catalog {
    padding-top: 0; }
    .setting-container .alert-catalog .alert-catalog-header {
      height: 40px;
      background-color: #404040;
      color: white; }
      .setting-container .alert-catalog .alert-catalog-header .alert-catalog-title {
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: 500; }
    .setting-container .alert-catalog table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5 !important; }
    .setting-container .alert-catalog table.md-table tbody.md-body > tr.md-row > td {
      font-weight: 500; }
    .setting-container .alert-catalog table.md-table:not(.md-row-select) td.md-cell:first-child, .setting-container .alert-catalog td.md-cell:last-child {
      text-transform: capitalize; }
    .setting-container .alert-catalog table.md-table th.md-column {
      color: #4f4f51;
      font-size: 16px;
      text-transform: uppercase; }
  .setting-container .daily-check {
    line-height: 2.8;
    font-size: 18px;
    font-weight: 500; }

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.system-dashboard-container {
  padding: 0 1em;
  height: 100vh;
  display: block;
  padding-top: 5.4vh;
  background-color: #758185 !important; }
  .system-dashboard-container .blue-bg {
    background-image: linear-gradient(to bottom, #56c1ff, #9ccdff, #ffffff); }
    .system-dashboard-container .blue-bg .unit {
      font-weight: 500 !important; }
  .system-dashboard-container .tick {
    display: none; }
  .system-dashboard-container .has-dmg .odometer-inside {
    text-align: center;
    padding-right: 4vmin; }
    .system-dashboard-container .has-dmg .odometer-inside:first-of-type:before {
      content: '$';
      font-size: 22px;
      font-weight: bold; }
  .system-dashboard-container .has-polarity .odometer-inside {
    text-align: center;
    padding-right: 4vmin; }
    .system-dashboard-container .has-polarity .odometer-inside:first-of-type:before {
      content: '+';
      font-size: 22px;
      font-weight: bold; }
  .system-dashboard-container .odometer-formatting-mark {
    font-weight: bolder; }
  .system-dashboard-container .odometer-digit {
    font-size: 3vmax; }
  .system-dashboard-container md-fab-trigger {
    height: 65px;
    margin: -.60vh 1vw 0vh 0vw; }
  .system-dashboard-container md-fab-trigger > button {
    margin: 0;
    padding: 0;
    margin-top: 1vh;
    max-width: 20px !important;
    min-height: 5px !important;
    max-height: 20px !important; }
    .system-dashboard-container md-fab-trigger > button i.material-icons {
      font-size: 1.1em;
      display: block;
      margin: 0;
      padding: 0; }
  .system-dashboard-container figure {
    padding-top: 2vh;
    height: 100%;
    width: 100%;
    border: 1px solid #758185;
    background-color: #758185;
    background-color: #fff;
    border-radius: 4px; }
  .system-dashboard-container figcaption {
    width: 100%; }
  .system-dashboard-container .aq-tile-container {
    background-image: linear-gradient(to bottom, #56c1ff, #9ccdff, #ffffff, #ffffff, #ffffff);
    height: 100%;
    padding-top: 1%; }
    .system-dashboard-container .aq-tile-container:hover .blue-bg {
      background-image: linear-gradient(to bottom, #9ccdff, #56c1ff, #ffffff); }
      .system-dashboard-container .aq-tile-container:hover .blue-bg .unit {
        font-weight: 500 !important; }

.gray {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }

.widget-block {
  height: 50%;
  width: 100% !important; }
  .widget-block .subtitle {
    font-size: 2rem; }
  .widget-block .title {
    text-align: center; }
    .widget-block .title span {
      font-weight: 600;
      vertical-align: middle;
      padding: 0 1vw; }
    .widget-block .title .unit {
      font-size: calc(0.6rem + 1vmin);
      font-weight: normal;
      margin-left: -1vw;
      font-weight: 500 !important; }
  .widget-block:hover .title, .widget-block:hover .counter {
    color: #fd6811; }
  .widget-block .flatten {
    height: 0 !important;
    display: none; }
  .widget-block .title, .widget-block .chart, .widget-block .counter, .widget-block .switch {
    font-size: 2.4rem;
    max-height: 15vh;
    padding-top: 10px;
    height: 55%;
    height: auto; }
  .widget-block .switch span {
    display: block;
    text-align: center;
    vertical-align: middle;
    height: 90%;
    font-size: 4rem; }
  .widget-block .switch.red-text span {
    color: red; }
  .widget-block .counter {
    text-align: center;
    padding: 0;
    min-height: 4vmin; }
  .widget-block .chart {
    padding-top: 0vmin;
    margin: 0;
    min-height: 7vh; }
    .widget-block .chart div.chart-counter {
      font-size: 1.5rem; }

md-menu .subicon {
  margin-top: -3px; }

md-menu-item > .md-button p {
  text-transform: capitalize; }

.widget-block-header {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .widget-block-header h3 {
    display: inline-block;
    font-size: large;
    font-weight: bolder; }

.widget-block-footer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0.5vmin; }
  .widget-block-footer h3 {
    margin: 0;
    padding: 0;
    font-size: 1em !important; }

.widget-block-footer, .widget-block-header {
  max-height: 4vh !important;
  background-color: #313941;
  color: #fff;
  text-transform: uppercase; }
  .widget-block-footer .fab-container, .widget-block-header .fab-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999; }

.woba {
  display: block;
  position: absolute;
  background: #fff;
  border-radius: 100%;
  transform: scale(0);
  opacity: .5; }

.woba.animate {
  animation: ripple .5s linear forwards; }

.shine {
  background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
  -webkit-background-size: 150px;
  color: rgba(255, 255, 255, 0.3);
  -webkit-background-clip: text;
  -webkit-animation-name: shine;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.8); }

.chrome {
  background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
  background-image: -webkit-linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
  -webkit-background-size: 200px;
  color: rgba(255, 255, 255, 0.3);
  -webkit-background-clip: text;
  -webkit-animation-name: shine;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.8); }

@-webkit-keyframes shine {
  0%, 10% {
    background-position: -1000px; }
  20% {
    background-position: top left; }
  90% {
    background-position: top right; }
  100% {
    background-position: 1000px; } }

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5); } }

.sym-gauge {
  margin-bottom: -30px; }
  .sym-gauge b {
    font-size: 2.4rem !important; }
  .sym-gauge span {
    font-size: 2.4rem !important;
    font-weight: 500; }

.ss-container {
  width: 85vw;
  padding-top: 50px; }
  .ss-container md-tab-content {
    background-color: #758185 !important; }
  .ss-container md-card {
    padding: 0 !important; }
    .ss-container md-card md-input-container.md-block {
      margin: 0; }
      .ss-container md-card md-input-container.md-block md-select {
        width: 6vw; }
    .ss-container md-card.table-container {
      padding: 0;
      overflow: hidden; }
      .ss-container md-card.table-container td.table-cell-heading {
        font-weight: bolder; }
        .ss-container md-card.table-container td.table-cell-heading span {
          text-transform: none; }
    .ss-container md-card a.md-button.md-primary.md-raised,
    .ss-container md-card a.md-button.md-primary.md-fab,
    .ss-container md-card .md-button.md-primary.md-raised,
    .ss-container md-card .md-button.md-primary.md-fab {
      background-color: #D17E3E; }
    .ss-container md-card a.md-button.md-primary.md-raised:not([disabled]):hover,
    .ss-container md-card a.md-button.md-primary.md-fab:not([disabled]):hover,
    .ss-container md-card .md-button.md-primary.md-raised:not([disabled]):hover,
    .ss-container md-card .md-button.md-primary.md-fab:not([disabled]):hover {
      background-color: #E55921; }
    .ss-container md-card md-card-content.content-table {
      padding: 0;
      width: 100%; }
    .ss-container md-card .table-heading,
    .ss-container md-card tbody td {
      text-transform: capitalize; }
    .ss-container md-card md-toolbar {
      text-align: center;
      text-transform: capitalize; }
  .ss-container md-card:nth-child(1) .card-heading {
    background-color: #878628; }
  .ss-container md-card:nth-child(2) .card-heading {
    background-color: #DB6161; }
  .ss-container md-card:nth-child(3) .card-heading {
    background-color: #499ED4; }
  .ss-container md-card:nth-child(4) .card-heading {
    background-color: #A19F27; }
  .ss-container md-card:nth-child(5) .card-heading {
    background-color: #216087; }

.frame3 md-radio-group md-radio-button checked .md-ink-ripple {
  color: #878628; }

.frame3 md-radio-group md-radio-button .md-off {
  border-color: #878628; }

.wha-container {
  width: 85vw; }
  .wha-container #chart-wha1 md-card md-card-content {
    padding: 0; }
  .wha-container #chart-wha1 {
    padding-bottom: 10px; }
    .wha-container #chart-wha1 md-content {
      overflow-y: visible; }
  .wha-container md-card md-toolbar {
    text-align: center; }
  .wha-container md-card table.table {
    margin-bottom: 0; }
    .wha-container md-card table.table .table-heading {
      text-transform: capitalize;
      text-align: center !important; }
      .wha-container md-card table.table .table-heading span.unit {
        text-transform: lowercase; }
    .wha-container md-card table.table tr {
      text-align: center; }

.as-dashboard-container {
  margin: 0; }
  .as-dashboard-container md-toolbar {
    margin: 0; }
  .as-dashboard-container md-card {
    overflow: hidden;
    max-height: 35vh; }
  .as-dashboard-container .md-off {
    background-color: #fff;
    color: #fff; }
  .as-dashboard-container .md-on {
    color: #fff;
    border-color: #658085; }
  .as-dashboard-container .chart-filled {
    fill: steelblue; }
  .as-dashboard-container .chart-empty {
    fill: green; }
  .as-dashboard-container .dc_current h4,
  .as-dashboard-container .dc_current .flow-unit {
    background-color: #CB7909 !important; }
  .as-dashboard-container md-card iframe {
    height: 44vh; }
  .as-dashboard-container md-card iframe.controlled-frame {
    height: 44vh;
    width: 100%;
    margin-top: 0px; }
  .as-dashboard-container .unit-symbol {
    vertical-align: -webkit-baseline-middle; }
  .as-dashboard-container .positive-symbol {
    font-size: calc(0.8rem + 1vw);
    vertical-align: middle; }
  .as-dashboard-container .single-icon {
    position: absolute;
    color: white;
    left: 85%;
    top: 8.4vmin;
    font-size: 16px; }
  .as-dashboard-container .as-card {
    text-align: center; }
    .as-dashboard-container .as-card .bg-0,
    .as-dashboard-container .as-card .bg-7 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card .bg-2,
    .as-dashboard-container .as-card .bg-5 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card .bg-3,
    .as-dashboard-container .as-card .bg-4 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card .bg-6,
    .as-dashboard-container .as-card .bg-1 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card.short .bg-0 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card.short .bg-1 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card.short .bg-2 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card.short .bg-3 {
      background-color: #4f575a;
      opacity: .95; }
    .as-dashboard-container .as-card .flow-text,
    .as-dashboard-container .as-card .flow-unit {
      color: #fff;
      text-align: center;
      margin: 0;
      padding: 0.5vmin 0;
      font-size: calc(0.35rem + 1.5vmin);
      font-weight: bolder; }
    .as-dashboard-container .as-card .flow-unit {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
    .as-dashboard-container .as-card h4.flow-text {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: calc(0.35rem + 1.95vmin); }
    .as-dashboard-container .as-card .pressure-text,
    .as-dashboard-container .as-card .pressure-unit {
      color: #fff;
      background-color: #CB7909;
      text-align: center;
      margin: 0;
      padding: 0.5vmin 0;
      font-size: calc(0.35rem + 1.15vmin);
      font-weight: bolder; }
    .as-dashboard-container .as-card .flow-value,
    .as-dashboard-container .as-card .pressure-value {
      font-size: calc(0.8rem + 1.74vw);
      letter-spacing: 2px;
      color: #4d5322; }
      .as-dashboard-container .as-card .flow-value .odometer.odometer-theme-minimal .odometer-value,
      .as-dashboard-container .as-card .pressure-value .odometer.odometer-theme-minimal .odometer-value {
        font-size: calc(1.4rem + 5.45vmin); }
    .as-dashboard-container .as-card .value-box {
      background-color: rgba(3, 48, 75, 0.03); }
      .as-dashboard-container .as-card .value-box .color-positive .flow-value {
        color: red !important; }
      .as-dashboard-container .as-card .value-box .color-negitive .flow-value {
        color: #1cd200 !important; }
  .as-dashboard-container .middle {
    text-align: center; }
  .as-dashboard-container .last {
    margin: 0;
    margin-left: 2%;
    text-align: center; }
  .as-dashboard-container .gauge {
    width: 100%; }
  .as-dashboard-container .gauge-container {
    height: 100%; }
    .as-dashboard-container .gauge-container .chart-gauge {
      width: 400px;
      margin: 1% auto; }
    .as-dashboard-container .gauge-container .chart-first {
      fill: #9FBD35; }
    .as-dashboard-container .gauge-container .chart-second {
      fill: #D7FF48; }
    .as-dashboard-container .gauge-container .chart-third {
      fill: #FFFF1F; }
    .as-dashboard-container .gauge-container .chart-fourth {
      fill: #FC7C7E; }
    .as-dashboard-container .gauge-container .chart-fifth {
      fill: #FB3033; }
    .as-dashboard-container .gauge-container .needle,
    .as-dashboard-container .gauge-container .needle-center {
      fill: #3A3B46; }
    .as-dashboard-container .gauge-container .text {
      color: "#112864";
      font-size: 24px; }
    .as-dashboard-container .gauge-container svg {
      font: 10px sans-serif; }
    .as-dashboard-container .gauge-container .flow-value {
      font-size: calc(2rem + 4vw); }
  .as-dashboard-container .card-title {
    background-color: #3A3B46;
    color: #fff;
    font-size: 24px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
    max-height: 32px; }
    .as-dashboard-container .card-title .toggle-icon {
      margin-left: auto;
      vertical-align: middle;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
    .as-dashboard-container .card-title h2 {
      margin: 0;
      font-weight: 400;
      font-size: 24px; }
    .as-dashboard-container .card-title .card-expand {
      margin: 4px;
      color: #fff; }
    .as-dashboard-container .card-title .right {
      float: right;
      font-size: 12px;
      padding-right: 20px; }
      .as-dashboard-container .card-title .right md-checkbox {
        box-sizing: border-box;
        display: inline-block;
        margin-bottom: 7px;
        white-space: nowrap;
        cursor: pointer;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;
        min-width: 20px;
        min-height: 20px;
        margin-left: 0;
        margin-right: 16px;
        margin-top: 7px; }
    .as-dashboard-container .card-title .left {
      float: left;
      height: 100%; }
      .as-dashboard-container .card-title .left md-radio-button .md-label {
        font-size: 12px; }
    .as-dashboard-container .card-title:focus {
      outline: none; }
  .as-dashboard-container .dashboard-tab {
    width: 100vw;
    display: block; }
  .as-dashboard-container .dc-savings-card .unit-symbol {
    height: 100%;
    font-size: 1.5em;
    vertical-align: -webkit-baseline-middle; }
  .as-dashboard-container .dc-savings-card .flow-value {
    height: 100%;
    padding-top: 3%;
    font-size: 4em; }
  .as-dashboard-container .dc-savings-card .as-list {
    margin: 4vh !important;
    margin-right: 80px !important; }
  .as-dashboard-container tackle-meter .ani-value {
    font-size: 30px;
    overflow: hidden; }
  .as-dashboard-container tackle-meter #ani {
    height: 310px;
    margin: 0 auto;
    overflow: hidden; }
  .as-dashboard-container .motor-btn {
    background-color: grey;
    -webkit-border-radius: 10px;
    border-radius: 50%;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: calc(0.5rem + 1.1vmin);
    font-weight: 800;
    text-shadow: 3px 3px 1px black;
    text-transform: capitalize;
    /*   padding: 5px 10px; */
    text-decoration: none;
    width: 60px;
    height: 60px;
    padding: 2px 1px; }
    .as-dashboard-container .motor-btn .motor-title {
      font-style: calc(0.25rem + .55vmin);
      display: block; }
  .as-dashboard-container .color-green {
    background-color: green; }
  .as-dashboard-container .color-green-blink {
    background-color: green; }
  .as-dashboard-container .color-green-blink {
    -webkit-animation: blinking 2000ms infinite;
    -moz-animation: blinking 2000ms infinite;
    -o-animation: blinking 2000ms infinite;
    animation: blinking 2000ms infinite; }

@keyframes blinking {
  0% {
    box-shadow: 0 0 -1.5px #FF0000; }
  50% {
    box-shadow: 0 0 30px #FF0000; }
  100% {
    box-shadow: 0 0 -1.5px #FF0000; } }

.ilp-gauge-sync-container {
  overflow-y: hidden; }
  .ilp-gauge-sync-container span {
    color: #fff; }
  .ilp-gauge-sync-container .md-active {
    background-image: linear-gradient(to right bottom, #4964ae, #395b93, #325077, #31455b, #313941);
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.4px; }
    .ilp-gauge-sync-container .md-active span {
      color: #fff !important; }
  .ilp-gauge-sync-container md-tabs.md-dynamic-height md-tab-content {
    padding-top: 20px; }
  .ilp-gauge-sync-container md-tabs-canvas {
    background-color: #313941;
    border: none; }

.unit-list {
  color: #4f4f51;
  background-color: #fff; }

.livechart-select {
  padding: 2px;
  line-height: 1; }

.ani-info-icon {
  font-size: 18px !important;
  float: right;
  margin: 6px 5px 0 0; }

.adv-time {
  background-color: #fd6811 !important;
  color: white !important;
  border-radius: 5px;
  font-size: 14px !important; }

.o2-signal,
.vent-signal {
  text-shadow: 2px 2px 5px red; }

.live-title {
  background-color: #3A3B46;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
  width: 100%; }
  .live-title h2 {
    margin: 0;
    font-weight: 400;
    font-size: 24px; }
  .live-title .card-expand {
    margin: 4px;
    color: #fff; }
  .live-title .multi-expand {
    margin: 5px 20px 0 0; }
  .live-title .right {
    float: right;
    font-size: 18px;
    font-weight: 500;
    padding-right: 20px; }
    .live-title .right md-checkbox {
      box-sizing: border-box;
      display: inline-block;
      margin-bottom: 7px;
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      min-width: 20px;
      min-height: 20px;
      margin-left: 0;
      margin-right: 16px;
      margin-top: 7px; }
  .live-title .left {
    padding-left: 10px;
    font-weight: 500; }
    .live-title .left md-radio-button .md-label {
      font-size: 18px; }
  .live-title:focus {
    outline: none; }

.tg-list {
  display: inline-block;
  font-size: 16px;
  margin: 7px;
  margin-right: 50px; }

.tg-list-item {
  list-style: none; }

.tgl {
  display: none; }
  .tgl, .tgl:after, .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box; }
    .tgl::selection, .tgl:after::selection, .tgl:before::selection,
    .tgl *::selection,
    .tgl *:after::selection,
    .tgl *:before::selection,
    .tgl + .tgl-btn::selection {
      background: none; }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 10.5em;
    height: 30px;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }
    .tgl + .tgl-btn span {
      letter-spacing: 0.5px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }
    .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
      position: relative;
      display: block;
      content: "";
      width: 16%;
      height: 100%; }
    .tgl + .tgl-btn:after {
      left: 0; }
    .tgl + .tgl-btn:before {
      display: none; }
  .tgl:checked + .tgl-btn:after {
    left: 85%; }

.tgl-light {
  padding: 0; }
  .tgl-light + .tgl-btn {
    background: green;
    overflow: hidden;
    border-radius: 0.5em;
    padding: 2px;
    transition: all .4s ease; }
    .tgl-light + .tgl-btn span {
      margin-top: 8px;
      width: 59%;
      text-align: left;
      font-size: 11px;
      letter-spacing: 0px;
      float: right;
      vertical-align: middle;
      line-height: 9px; }
    .tgl-light + .tgl-btn:after {
      top: 0;
      left: 0;
      width: 15%;
      border-radius: 6px;
      background: #fff;
      transition: all .2s ease; }
  .tgl-light:checked + .tgl-btn {
    background: red; }
    .tgl-light:checked + .tgl-btn span {
      margin-left: 5px;
      margin-top: 7px;
      display: block;
      text-align: left; }

md-tabs-wrapper md-next-button md-icon, md-tabs-wrapper md-prev-button md-icon {
  display: none; }

.all-deck-content {
  padding-top: 5px; }
  .all-deck-content md-toolbar {
    height: 40px;
    background-image: linear-gradient(to top, #2d3133, #2f414a, #2d5263, #26637e, #17749a) !important; }
  .all-deck-content .deck-header .deck-title {
    font-size: calc(0.35rem + 1.85vmin);
    color: #fff;
    font-weight: 500; }
  .all-deck-content .deck-header .deck-icon {
    text-align: right;
    display: block !important; }
    .all-deck-content .deck-header .deck-icon .toggle-icon {
      margin-left: auto;
      vertical-align: middle;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
    .all-deck-content .deck-header .deck-icon:focus {
      outline: none; }
  .all-deck-content .hour-card-param {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder;
    display: inline-block;
    letter-spacing: 0.5px; }
    .all-deck-content .hour-card-param .info-icon {
      float: right;
      font-size: 18px;
      margin-right: 8px; }
  .all-deck-content .hour-card-value {
    font-size: calc(1.4rem + 3vh);
    text-align: center; }
    .all-deck-content .hour-card-value .value-symbol {
      margin: 25px 7px 0 0;
      font-size: calc(0.45rem + 1.3vmin); }
  .all-deck-content .hour-card-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder; }
  .all-deck-content .block-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder; }
  .all-deck-content .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin); }
  .all-deck-content .deck-block {
    background-color: #313941;
    padding: 0.45vmin 0; }
  .all-deck-content .deck-block-1 {
    background-color: #CB7909;
    padding: 0.45vmin 0; }
  .all-deck-content .d-0 {
    background-color: #4f4f51; }
  .all-deck-content .d-1 {
    background-color: #FF792B; }
  .all-deck-content .d-2 {
    background-color: #313941; }
  .all-deck-content .d-3 {
    background-color: #B04506; }
  .all-deck-content .d-4 {
    background-color: #00B0B0; }

.view-container {
  width: 100vw; }
  .view-container .md-dialog-container {
    z-index: -80; }
  .view-container .bg-blue {
    background-color: steelblue; }
  .view-container .bg-green {
    background-color: #2ca02c; }
  .view-container .bg-darkgreen {
    background-color: #01584a; }
  .view-container .bg-orange {
    background-color: #ff7f0e; }
  .view-container .bg-red {
    background-color: #d62728; }
  .view-container .bg-yellow {
    background-color: #F0D705; }
  .view-container .bg-purple {
    background-color: #9467bd; }
  .view-container .bg-blue {
    background-color: steelblue; }
  .view-container .hide {
    display: none !important; }

.draglayer {
  margin-top: -20px !important; }

td.pvtTotal.rowTotal {
  display: none; }

td.pvtTotal.colTotal {
  display: none; }

th.pvtTotalLabel {
  display: none; }

td.pvtGrandTotal {
  display: none; }

.todayschart {
  width: 80vw;
  height: 80vh; }

.gc {
  max-height: 20vh;
  height: 50%; }
  .gc .ilp-gauge {
    text-align: center;
    height: 200px; }
    .gc .ilp-gauge u {
      font-weight: 300 !important; }
    .gc .ilp-gauge b {
      font-weight: 500 !important; }

.system-efficiency-container .system-efficiency-heading {
  height: 40px;
  margin: 0;
  background-color: #313941; }
  .system-efficiency-container .system-efficiency-heading h2 {
    text-align: center;
    background-color: #313941;
    color: white;
    font-size: 20px;
    margin: 0;
    padding-top: 0;
    letter-spacing: 1px; }
  .system-efficiency-container .system-efficiency-heading md-radio-button.md-checked .md-off {
    border-color: #fff; }
  .system-efficiency-container .system-efficiency-heading md-radio-button .md-on {
    background-color: #CB7909; }
  .system-efficiency-container .system-efficiency-heading md-radio-group {
    margin-right: 30px; }
  .system-efficiency-container .system-efficiency-heading md-radio-group.layout-row md-radio-button {
    margin: 0 20px 0 20px; }

.dashboard-container {
  z-index: 3 !important;
  overflow-y: scroll !important; }
  .dashboard-container .dashboard-charts-container {
    margin: 0;
    height: auto; }
  .dashboard-container .chart-gauge {
    width: 100%; }
  .dashboard-container #FlowValue-gague .chart-filled {
    fill: #1f77b4; }
  .dashboard-container #SpeedValue-gague .chart-filled {
    fill: #2ca02c; }
  .dashboard-container #TanklevelValue-gague .chart-filled {
    fill: #2ca02c; }
  .dashboard-container #Discharge_GateValue-gague .chart-filled,
  .dashboard-container #Wet_WellValue-gague .chart-filled,
  .dashboard-container #Well_LevelValue-gague .chart-filled {
    fill: #ff7f0e; }
  .dashboard-container #PressureValue-gague .chart-filled {
    fill: #ff7f0e; }
  .dashboard-container #HeadValue-gague .chart-filled {
    fill: #d62728; }
  .dashboard-container #CostValue-gague .chart-filled {
    fill: #9467bd; }
  .dashboard-container .chart-filled {
    fill: steelblue; }
  .dashboard-container .chart-empty {
    fill: #dedede; }
  .dashboard-container .needle,
  .dashboard-container .needle-center {
    fill: #464A4F; }
  .dashboard-container svg {
    font: 10px sans-serif; }
  .dashboard-container g.c3-chart-arcs {
    width: 70%;
    position: relative; }
  .dashboard-container text.c3-gauge-value {
    font-size: 2em !important;
    display: none; }
  .dashboard-container .nv-legend-symbol {
    display: none !important; }
  .dashboard-container .legend-color-guide {
    display: none !important; }
    .dashboard-container .legend-color-guide div {
      background-color: none !important; }
  .dashboard-container .dashboard-gauge-sync-container {
    overflow-y: hidden; }
    .dashboard-container .dashboard-gauge-sync-container span {
      color: #4f4f51; }
    .dashboard-container .dashboard-gauge-sync-container .md-active {
      background-color: #392f4e;
      color: #fff; }
      .dashboard-container .dashboard-gauge-sync-container .md-active span {
        color: #fff !important; }
    .dashboard-container .dashboard-gauge-sync-container md-tabs-canvas {
      background-color: #313941;
      border: none; }
    .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container {
      height: 35vh; }
      .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container .gauges {
        height: 35vh; }
        .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container .gauges .gauge-view {
          max-height: 5vh; }
          .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container .gauges .gauge-view span {
            display: block;
            text-align: center;
            line-height: 2px;
            padding: 10%; }
    .dashboard-container .dashboard-gauge-sync-container .gauge {
      color: #fff;
      height: auto;
      text-align: center; }
      .dashboard-container .dashboard-gauge-sync-container .gauge .needle,
      .dashboard-container .dashboard-gauge-sync-container .gauge .needle-center {
        fill: #fff; }
      .dashboard-container .dashboard-gauge-sync-container .gauge span:first-child {
        padding: 2%; }
      .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label {
        margin-bottom: 18px;
        display: block;
        text-align: center;
        font-size: 1.2vmin;
        font-weight: 400; }
        .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span {
          display: inline-block;
          text-align: center;
          padding: 0;
          letter-spacing: -.5px;
          height: 0;
          font-weight: bolder;
          width: 33%; }
          .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span.left {
            text-align: left;
            text-transform: uppercase; }
          .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span.center {
            text-align: center; }
          .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span.right {
            text-align: right; }
    .dashboard-container .dashboard-gauge-sync-container .gauge-units {
      display: block;
      width: 100%;
      text-align: center;
      font-weight: 400;
      padding: 15% 0 15% 0;
      color: #fff; }
  .dashboard-container .rta md-card {
    display: inline-block;
    margin: 0 5px; }
    .dashboard-container .rta md-card .rta-box {
      width: 50%;
      float: left; }
    .dashboard-container .rta md-card .rta-box .flow-box,
    .dashboard-container .rta md-card .rta-box .pressure-box {
      width: 100%;
      margin: 0;
      float: left;
      padding: 0; }
      .dashboard-container .rta md-card .rta-box .flow-box h4,
      .dashboard-container .rta md-card .rta-box .pressure-box h4 {
        color: #fff;
        text-align: center;
        margin: 0;
        padding: 0.5vmin 0;
        font-size: calc(0.35rem + 1.45vmin);
        font-weight: bolder; }
      .dashboard-container .rta md-card .rta-box .flow-box span,
      .dashboard-container .rta md-card .rta-box .pressure-box span {
        margin: 0;
        font-size: calc(1.4rem + 3.45vmin);
        text-align: left;
        letter-spacing: 1px;
        text-align: center; }
        .dashboard-container .rta md-card .rta-box .flow-box span.demand,
        .dashboard-container .rta md-card .rta-box .pressure-box span.demand {
          overflow: hidden; }
          .dashboard-container .rta md-card .rta-box .flow-box span.demand .odometer.odometer-theme-minimal,
          .dashboard-container .rta md-card .rta-box .pressure-box span.demand .odometer.odometer-theme-minimal {
            display: inherit;
            height: 1vh; }
            .dashboard-container .rta md-card .rta-box .flow-box span.demand .odometer.odometer-theme-minimal .odometer-value,
            .dashboard-container .rta md-card .rta-box .pressure-box span.demand .odometer.odometer-theme-minimal .odometer-value {
              font-size: calc(1.4rem + 4.45vmin);
              width: auto;
              display: inline; }
        .dashboard-container .rta md-card .rta-box .flow-box span.units,
        .dashboard-container .rta md-card .rta-box .pressure-box span.units {
          display: block;
          color: white;
          font-size: calc(1rem + 1vmin);
          font-weight: bolder;
          padding: 0 0 4.5vmin 0.5vmin 0; }
    .dashboard-container .rta md-card .flow-box {
      min-width: 60% !important; }
      .dashboard-container .rta md-card .flow-box h4 {
        background-color: #313941; }
      .dashboard-container .rta md-card .flow-box span.demand {
        display: block;
        text-align: center;
        background-color: rgba(3, 48, 75, 0.03);
        padding: 0;
        padding-left: 10%; }
      .dashboard-container .rta md-card .flow-box .units {
        background-color: #313941; }
    .dashboard-container .rta md-card .pressure-box h4 {
      background-color: #CB7909; }
    .dashboard-container .rta md-card .pressure-box span.demand {
      background-color: rgba(203, 121, 9, 0.04);
      display: block;
      text-align: center; }
    .dashboard-container .rta md-card .pressure-box .units {
      background-color: #CB7909;
      height: 100%; }
    .dashboard-container .rta md-card .rta-box .panel-pumps {
      width: 100%;
      display: inline-block;
      padding: 0;
      margin: 0;
      height: 12.8vmin; }
      .dashboard-container .rta md-card .rta-box .panel-pumps .wrapper {
        height: 69%; }
        .dashboard-container .rta md-card .rta-box .panel-pumps .wrapper button {
          background-color: #1D2E41;
          border-radius: 50%;
          border: none;
          color: #FFFFFF;
          cursor: pointer;
          display: inline-block;
          font-family: Arial;
          font-size: 10px;
          text-transform: capitalize;
          /*   padding: 5px 10px; */
          text-align: center;
          text-decoration: none;
          width: 25px;
          height: 25px;
          padding: 2px 1px;
          margin: 10px 0 0 18px; }
      .dashboard-container .rta md-card .rta-box .panel-pumps .panel-pumps-title {
        height: 32%;
        font-size: calc(0.35rem + 1.45vmin);
        display: block;
        width: 100%;
        color: #fff;
        text-align: center;
        font-weight: bold;
        background-color: #313941; }
    .dashboard-container .rta md-card .rta-box .panel-cost {
      display: inline-block;
      padding: 0;
      margin: 0;
      font-size: calc(1rem + 1vmin);
      width: 100%;
      height: 11.8vmin;
      color: #fff;
      text-align: center;
      background-color: #1D2E41; }
      .dashboard-container .rta md-card .rta-box .panel-cost .wrapper .odometer.odometer-theme-minimal {
        margin-bottom: 25%;
        font-size: calc(1.2rem + 3.5vmin);
        text-align: center;
        height: 100%;
        min-width: 50%; }
      .dashboard-container .rta md-card .rta-box .panel-cost .panel-pumps-title {
        height: 35%;
        display: block;
        color: #fff;
        text-align: center;
        font-weight: bold;
        background-color: #313941; }
  .dashboard-container md-card .rotate {
    border: none !important;
    height: 50px;
    /* Safari */
    -webkit-transform: rotate(45deg);
    /* Firefox */
    -moz-transform: rotate(45deg);
    /* IE */
    -ms-transform: rotate(45deg);
    /* Opera */
    -o-transform: rotate(45deg);
    /* Internet Explorer */ }
  .dashboard-container md-card .card-heading {
    height: 20%; }
    .dashboard-container md-card .card-heading h3 {
      height: auto;
      margin: 0;
      padding: 0.8vmin 0;
      text-align: center;
      color: #fff;
      font-weight: bolder;
      font-size: calc(0.35rem + 1.45vmin);
      padding: 0.5vmin;
      text-transform: capitalize; }
  .dashboard-container md-card .card-heading.blue {
    background-color: #6A7B8E; }
  .dashboard-container md-card .card-heading.dark-blue {
    background-color: #313941; }
  .dashboard-container md-card .card-heading.orange {
    background-color: #CB7909; }
  .dashboard-container md-card md-icon {
    float: left;
    color: white; }
  .dashboard-container md-card td.table-heading {
    background-color: #658085 !important;
    color: white;
    text-transform: uppercase;
    font-style: 1em;
    max-height: 8vh; }

#history-view-system-efficiency h2 {
  background-color: #4E361B;
  color: #fff;
  text-align: center;
  margin: 0; }

#myStaticDialog-sc-0 .js-plotly-plot,
#myStaticDialog-sc-1 .js-plotly-plot,
#myStaticDialog-sc-2 .js-plotly-plot {
  overflow: hidden; }
  #myStaticDialog-sc-0 .js-plotly-plot .plotly,
  #myStaticDialog-sc-1 .js-plotly-plot .plotly,
  #myStaticDialog-sc-2 .js-plotly-plot .plotly {
    margin-top: 25px; }

.button-on {
  background-color: #089201;
  -webkit-border-radius: 10px;
  border-radius: 50%;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 12px;
  text-transform: capitalize;
  /*   padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  width: 25px;
  height: 25px;
  padding: 2px 1px;
  margin: 5px 10px; }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-minimal {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-minimal .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0); }

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
  position: absolute; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s; }

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.flow-prediction-container {
  max-height: 970px; }
  .flow-prediction-container .flow-prediction-heading {
    height: 40px;
    margin: 0;
    background-color: #313941; }
    .flow-prediction-container .flow-prediction-heading h2 {
      text-align: center;
      background-color: #313941;
      color: white;
      font-size: 20px;
      margin: 0;
      padding-top: 8px;
      letter-spacing: 0.5px; }
    .flow-prediction-container .flow-prediction-heading .ado-info {
      float: right;
      color: #fff;
      margin: 8px 5px 0; }

.status-toast .md-toast-content {
  background-color: #D41F22;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-top: 32px;
  z-index: 1; }

.md-center {
  left: 50%;
  transform: translate3d(-50%, 0, 0); }

.md-whiteframe-12dp {
  box-shadow: 0px 15px 16px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.inner-wrapper {
  margin-left: 1.3px; }

.padbottom {
  padding-bottom: 79px; }

.md-toolbar-tools .md-select-value {
  border-bottom-color: #3f51b5;
  color: white !important; }

.rta-card {
  height: auto;
  min-height: 2vh; }

.rta-load {
  font-size: larger;
  font-weight: 700; }

.info-bar {
  background-color: #313941 !important;
  min-height: 45px !important; }

.info-h1 {
  margin: 0;
  text-align: center;
  font-size: 24px;
  padding-top: 12px; }

.info-data {
  font-size: 13px;
  color: #EEF0F3;
  margin-left: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 18px; }

.info-toolbar {
  min-height: 0; }

.info-content {
  background-color: #17749A !important; }

.info-heading {
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
  font-size: large;
  font-weight: 700; }

.close-info {
  background-image: linear-gradient(to right, #fd6811, #fe6c19, #fe711f, #ff7525, #ff792b);
  width: 95%;
  color: #fff; }
  .close-info:hover {
    background-color: #FF792B !important;
    color: #fff !important; }

.rta-text {
  background-color: white;
  font-size: larger;
  font-weight: 700;
  max-height: 12vh;
  color: black;
  text-align: center;
  line-height: 11vh;
  height: auto;
  text-transform: capitalize;
  letter-spacing: 0.2px; }

.area {
  animation: blur .3s ease-out 1; }

@keyframes blur {
  from {
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 50px #fff, 0px 0px 50px #fff, 0px 0px 50px #7B96B8, 0px 0px 150px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px -10px 100px #7B96B8, 0px -10px 100px #7B96B8; } }

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 0px;
    min-width: 0px;
    border-radius: 0; } }

.first-card {
  text-align: center;
  max-height: fit-content; }
  .first-card .flow-text,
  .first-card .flow-unit {
    background-color: #313941;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .first-card .pressure-text,
  .first-card .pressure-unit {
    color: #fff;
    background-color: #CB7909;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .first-card .flow-value,
  .first-card .pressure-value {
    font-size: calc(1.4rem + 2.5vw);
    letter-spacing: 2px;
    color: #4d5322; }
    .first-card .flow-value .odometer.odometer-theme-minimal .odometer-value,
    .first-card .pressure-value .odometer.odometer-theme-minimal .odometer-value {
      font-size: calc(1.4rem + 5.45vmin); }
  .first-card .value-box {
    background-color: rgba(3, 48, 75, 0.03); }

.second-card {
  max-height: fit-content; }
  .second-card .second-card-heading {
    text-align: center;
    color: #fff;
    font-weight: bolder;
    font-size: calc(0.35rem + 1.45vmin);
    text-transform: capitalize;
    background-color: #313941;
    margin: 0;
    padding: 0.5vmin 0; }
  .second-card .value-box {
    background-color: #17749A;
    color: #fff;
    text-align: center; }
    .second-card .value-box .dmg-value {
      font-size: calc(1.4rem + 2.5vw);
      letter-spacing: 2px;
      margin: 0 2%; }
    .second-card .value-box .dmg-unit {
      font-size: calc(0.35rem + 1.75vmin);
      position: relative;
      top: 20%; }
  .second-card .pump-btn {
    background-color: #1D2E41;
    -webkit-border-radius: 10px;
    border-radius: 50%;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: calc(0.15rem + 1.1vmin);
    text-transform: uppercase;
    font-weight: 700;
    /*   padding: 5px 10px; */
    text-align: center;
    text-decoration: none;
    width: 34px;
    height: 34px;
    padding: 2px 1px;
    margin: 5px 10px; }
  .second-card .pump-btn-on {
    background-color: #089201; }

@keyframes glowing {
  0% {
    background-color: #089201;
    box-shadow: 0 0 1.5px #089201; }
  50% {
    background-color: #089201;
    box-shadow: 0 0 20px #089201; }
  100% {
    background-color: #089201;
    box-shadow: 0 0 1.5px #089201; } }
  .second-card .pump-btn-on {
    -webkit-animation: glowing 2000ms infinite;
    -moz-animation: glowing 2000ms infinite;
    -o-animation: glowing 2000ms infinite;
    animation: glowing 2000ms infinite; }
  .second-card .no-cpu {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0); }
  .second-card .btn-flex {
    margin-left: 6%; }
  .second-card .second-card-footer {
    background-color: #313941;
    color: #fff;
    padding: 9px; }
    .second-card .second-card-footer .update-time {
      text-align: center; }

.third-card {
  min-height: 2vh !important;
  max-height: fit-content; }
  .third-card .third-card-heading {
    height: auto;
    text-align: center;
    color: #fff;
    font-weight: bolder;
    font-size: calc(0.35rem + 1.45vmin);
    text-transform: capitalize;
    background-color: #313941;
    margin: 0;
    padding: 0.4vmin 0; }
    .third-card .third-card-heading .rta-info {
      font-size: calc(0.35rem + 1.45vmin) !important;
      float: right; }
  .third-card .op-manual {
    background-color: #313941; }
    .third-card .op-manual h6 {
      background-color: #17749A;
      margin: 0;
      color: #fff;
      text-align: center;
      font-size: calc(0.30rem + 1.3vmin);
      font-weight: 500; }
    .third-card .op-manual span {
      margin: 20px 30px 31px 30px;
      display: block;
      font-weight: 500;
      color: #fff;
      text-align: center; }
  .third-card .bg-rta {
    background-color: #17749A; }
    .third-card .bg-rta table.md-table thead.md-head > tr.md-row {
      height: 8px !important;
      text-transform: capitalize !important; }
    .third-card .bg-rta table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
    .third-card .bg-rta table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
      padding: 0; }
    .third-card .bg-rta table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      background-color: #313941 !important;
      padding: 0 !important; }
    .third-card .bg-rta table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2) {
      padding: 0 !important; }
    .third-card .bg-rta table.md-table th.md-column {
      color: #fff !important;
      font-weight: 600 !important;
      text-align: center !important; }
    .third-card .bg-rta td.md-cell:nth-child(even),
    .third-card .bg-rta th.md-column:nth-child(even) {
      background-color: #17749A !important;
      color: #fff;
      text-align: center; }
    .third-card .bg-rta td.md-cell:nth-child(odd),
    .third-card .bg-rta th.md-column:nth-child(odd) {
      background-color: #17749A !important;
      color: #fff !important; }
    .third-card .bg-rta .md-row .md-column,
    .third-card .bg-rta .md-row .md-cell {
      text-align: center !important;
      padding: 0 !important;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700; }
    .third-card .bg-rta table.md-table tbody.md-body > tr.md-row,
    .third-card .bg-rta table.md-table tfoot.md-foot > tr.md-row {
      height: 2.3vh !important;
      text-transform: capitalize;
      text-align: left; }
  .third-card .rta2-table .bg-rta2 table.md-table tbody.md-body > tr.md-row,
  .third-card .rta2-table .bg-rta2 table.md-table tfoot.md-foot > tr.md-row {
    height: 2.3vh !important;
    text-transform: capitalize; }
  .third-card .rta2-table .bg-rta2 table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
    padding: 0; }
  .third-card .rta2-table .bg-rta2 table > thead > tr > th {
    text-align: center; }
  .third-card .rta2-table .bg-rta2 table.md-table td.md-cell {
    text-align: center;
    padding: 0 !important; }
  .third-card .rta2-table .bg-rta2 table.md-table thead.md-head > tr.md-row {
    height: 8px !important;
    text-transform: capitalize !important;
    background-color: #313941 !important;
    color: #fff !important; }
  .third-card .rta2-table .bg-rta2 table.md-table th.md-column {
    color: #fff !important;
    padding: 0; }
  .third-card .rta2-table .bg-rta2 .row-color {
    background-color: #17749A; }
  .third-card .rta2-table .bg-rta2 table.md-table td.md-cell {
    color: #fff;
    font-size: calc(0.27rem + 1vmin);
    font-weight: 600; }
  .third-card .rta2-table .bg-rta2 .Blink {
    animation: blinker 2s cubic-bezier(0.9, 0, 1, 1) infinite alternate; }

@keyframes blinker {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }
  .third-card .rta2-table .bg-rta2 .text-danger {
    color: red !important; }
  .third-card .rta2-table .bg-rta2 .row-colorGreen {
    font-size: 16px;
    color: #17749A; }
  .third-card .rta2-table .bg-rta2 .row-colorRed {
    color: red !important;
    font-size: 16px; }
  .third-card .rta2-table .bg-rta2 .row-colorYellow {
    color: yellow !important;
    font-size: 16px; }
  .third-card .rta2-table .open-arrow {
    text-align: center;
    font-weight: 500;
    line-height: 0;
    font-size: 18px;
    background-color: #313941;
    color: #fff; }

.process-heading {
  font-size: calc(0.35rem + 1.75vmin) !important;
  font-weight: 500 !important;
  margin-top: 3px !important;
  text-transform: capitalize; }

.rta-comment {
  position: absolute;
  margin: 3% 0 0 1%;
  font-weight: 500;
  color: #FFF;
  text-align: center;
  width: 15%;
  background-color: #17749A; }
  .rta-comment .rta-comment-text {
    background-color: #17749A;
    text-transform: capitalize; }

.ptdashboard-container .hour-content .hour-card-param {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0.5vmin 0;
  font-size: calc(0.35rem + 1.45vmin);
  font-weight: bolder;
  display: inline-block;
  letter-spacing: 0.5px; }
  .ptdashboard-container .hour-content .hour-card-param .info-icon {
    float: right;
    font-size: 18px;
    margin-right: 8px; }

.ptdashboard-container .hour-content .hour-card-value {
  font-size: calc(1.4rem + 3vh);
  text-align: center; }
  .ptdashboard-container .hour-content .hour-card-value .value-symbol {
    margin: 25px 7px 0 0;
    font-size: calc(0.45rem + 1.3vmin); }

.ptdashboard-container .hour-content .hour-card-unit {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0.5vmin 0;
  font-size: calc(0.35rem + 1.45vmin);
  font-weight: bolder; }

.ptdashboard-container .hour-content .block-unit {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0.45vmin 0;
  font-size: calc(0.35rem + 1.45vmin);
  font-weight: bolder; }

.ptdashboard-container .hour-content .unit-symbol {
  font-size: calc(0.45rem + 1.3vmin); }

.ptdashboard-container .day-content md-toolbar {
  height: 40px;
  border-radius: 4px; }

.ptdashboard-container .day-content .day-forecast-header .day-forecast-title {
  font-size: calc(0.35rem + 1.85vmin);
  color: #fff;
  font-weight: 500; }

.ptdashboard-container .day-content .day-forecast-header .day-forecast-icon {
  text-align: right;
  display: block !important; }
  .ptdashboard-container .day-content .day-forecast-header .day-forecast-icon .toggle-icon {
    margin-left: auto;
    vertical-align: middle;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }

.ptdashboard-container .day-content .day-forecast-header:focus {
  outline: none; }

.ptdashboard-container .day-content .day-card-param {
  font-size: calc(0.35rem + 1.25vmin);
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0.5vmin 0;
  font-weight: bolder;
  letter-spacing: 0.3px; }

.ptdashboard-container .day-content .day-card-value {
  font-size: calc(1rem + 3vh);
  text-align: center; }

.ptdashboard-container .day-content .day-card-unit {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0.5vmin 0;
  font-size: calc(0.35rem + 1.25vmin);
  font-weight: bolder; }

.ptdashboard-container .day-content .unit-symbol {
  font-size: calc(0.45rem + 1.3vmin); }

.ptdashboard-container .day-content .block-unit {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0.45vmin 0;
  font-size: calc(0.35rem + 1.25vmin);
  font-weight: bolder; }

.ptdashboard-container .pr-actual md-card iframe {
  height: 44vh; }

.ptdashboard-container .pr-actual md-icon {
  float: left;
  color: white; }

.ptdashboard-container .pr-actual .pr-flow-prediction-container {
  max-height: 970px; }
  .ptdashboard-container .pr-actual .pr-flow-prediction-container .pr-flow-prediction-heading {
    height: 40px;
    margin: 0;
    background-color: #17749A; }
    .ptdashboard-container .pr-actual .pr-flow-prediction-container .pr-flow-prediction-heading h2 {
      text-align: center;
      background-color: #17749A;
      color: white;
      font-size: calc(1.2rem + 1vh);
      margin: 0;
      padding-top: 8px;
      letter-spacing: 1px; }
    .ptdashboard-container .pr-actual .pr-flow-prediction-container .pr-flow-prediction-heading h4 {
      text-align: center;
      background-color: #17749A;
      color: white;
      font-size: calc(0.35rem + 1.45vmin);
      margin: 0;
      letter-spacing: 0.5px; }
    .ptdashboard-container .pr-actual .pr-flow-prediction-container .pr-flow-prediction-heading .tp-info {
      color: white;
      text-align: right;
      position: absolute;
      right: 12px;
      font-size: 18px; }
  .ptdashboard-container .pr-actual .pr-flow-prediction-container .chart-val {
    display: inline-block;
    margin-top: 17vh;
    margin-bottom: 17vh;
    text-align: center;
    font-size: calc(1.2rem + 4vh);
    font-weight: 500; }

.ptdashboard-container .hd-0,
.ptdashboard-container .dd-0 {
  background-color: #17749A; }

.ptdashboard-container .hd-1,
.ptdashboard-container .dd-1 {
  background-color: #FF792B; }

.ptdashboard-container .hd-2,
.ptdashboard-container .dd-2 {
  background-color: #313941; }

.ptdashboard-container .hd-3,
.ptdashboard-container .dd-3 {
  background-color: #B04506; }

.ptdashboard-container .hd-4 {
  background-color: #00B0B0; }

.sc-container .first-card {
  text-align: center;
  max-height: fit-content; }
  .sc-container .first-card .flow-text,
  .sc-container .first-card .flow-unit {
    background-color: #313941;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .sc-container .first-card .info-1 {
    background-color: #313941;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 5px;
    font-size: calc(0.45rem + 1.55vmin); }
  .sc-container .first-card .pressure-text,
  .sc-container .first-card .pressure-unit {
    color: #fff;
    background-color: #CB7909;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .sc-container .first-card .flow-value,
  .sc-container .first-card .pressure-value {
    font-size: calc(1.4rem + 2.5vw);
    letter-spacing: 2px;
    color: #4d5322; }
    .sc-container .first-card .flow-value .odometer.odometer-theme-minimal .odometer-value,
    .sc-container .first-card .pressure-value .odometer.odometer-theme-minimal .odometer-value {
      font-size: calc(1.4rem + 5.45vmin); }
  .sc-container .first-card .or-value {
    font-size: calc(1.4rem + 2vw);
    letter-spacing: 2px;
    color: #4d5322; }
  .sc-container .first-card .value-box {
    background-color: rgba(3, 48, 75, 0.03); }
    .sc-container .first-card .value-box .symbol {
      font-size: calc(0.35rem + 1.75vmin);
      position: relative;
      top: 20%;
      margin-right: 16px; }
  .sc-container .first-card .tss-flag {
    background-color: red;
    color: white;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-size: calc(0.35rem + 1.2vmin);
    margin-right: 2px;
    border-radius: 2px; }

.sc-container .sr-card {
  text-align: center;
  max-height: fit-content; }
  .sc-container .sr-card .flow-text {
    background-color: #313941;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .sc-container .sr-card .info-1 {
    background-color: #313941;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 5px;
    font-size: calc(0.45rem + 1.55vmin); }
  .sc-container .sr-card .pressure-text,
  .sc-container .sr-card .pressure-unit {
    color: #fff;
    background-color: #CB7909;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .sc-container .sr-card .flow-value,
  .sc-container .sr-card .pressure-value {
    font-size: calc(1.4rem + 2.5vw);
    letter-spacing: 2px;
    color: #4d5322; }
    .sc-container .sr-card .flow-value .odometer.odometer-theme-minimal .odometer-value,
    .sc-container .sr-card .pressure-value .odometer.odometer-theme-minimal .odometer-value {
      font-size: calc(1.4rem + 1vmin); }
  .sc-container .sr-card .flow-unit {
    background-color: #313941;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.45rem + 1.55vmin);
    font-weight: bolder; }
  .sc-container .sr-card .footer-color {
    background-color: #313941;
    color: #fff; }
  .sc-container .sr-card .value-box {
    background-color: rgba(3, 48, 75, 0.03); }
    .sc-container .sr-card .value-box .sl-box, .sc-container .sr-card .value-box .re-box {
      color: #fff; }
  .sc-container .sr-card .sb-flag-yellow {
    background-color: yellow;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.2px;
    font-weight: 400;
    font-size: calc(0.45rem + 1.1vmin);
    margin-right: 2px;
    border-radius: 2px; }
  .sc-container .sr-card .sb-flag-red {
    background-color: red;
    color: white;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-size: calc(0.45rem + 1.1vmin);
    margin-right: 2px;
    border-radius: 2px; }

.sc-container .cp-card .cp-heading {
  background-color: #313941;
  font-size: 22px;
  font-weight: 500;
  padding-top: 3px;
  letter-spacing: 0.2px; }
  .sc-container .cp-card .cp-heading .toggle-icon {
    margin-left: auto;
    vertical-align: middle;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .sc-container .cp-card .cp-heading .info-3 {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    vertical-align: middle;
    font-size: calc(0.45rem + 1.55vmin); }

.sc-container .cp-card .error-content {
  display: inherit; }
  .sc-container .cp-card .error-content .error-icon {
    color: red;
    font-size: 20px; }
  .sc-container .cp-card .error-content .error-text {
    text-transform: capitalize;
    font-size: larger;
    font-weight: 700;
    color: black;
    margin-left: 5px; }

.sc-container .cp-card .cp-toggle {
  margin-right: 20px; }
  .sc-container .cp-card .cp-toggle:focus {
    outline: none; }

.sc-container .cp-card .raspump-btn {
  background-color: green;
  font-size: calc(0.5rem + 1.1vmin);
  font-weight: 800; }
  .sc-container .cp-card .raspump-btn:hover {
    background-color: green; }

.sc-container .cp-card .ras-pump-name {
  font-size: calc(0.5rem + 1.1vmin);
  font-weight: 500; }

.sc-container .cp-card .raspump-btn-grey {
  background-color: grey; }
  .sc-container .cp-card .raspump-btn-grey:hover {
    background-color: grey; }

.sc-container .cp-card .pumpbtn-blink {
  -webkit-animation: blinking 2000ms infinite;
  -moz-animation: blinking 2000ms infinite;
  -o-animation: blinking 2000ms infinite;
  animation: blinking 2000ms infinite; }

@keyframes blinking {
  0% {
    box-shadow: 0 0  -1.5px #FF0000; }
  50% {
    box-shadow: 0  0 30px #FF0000; }
  100% {
    box-shadow: 0 0 -1.5px #FF0000; } }

.sc-container .rank-card .rank-header {
  background-color: #313941;
  font-size: 22px;
  font-weight: 500; }
  .sc-container .rank-card .rank-header .info-4 {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    vertical-align: middle;
    font-size: calc(0.45rem + 1.55vmin); }
  .sc-container .rank-card .rank-header .rank-expand {
    margin-left: 5px;
    line-height: 1px; }
    .sc-container .rank-card .rank-header .rank-expand .ras-expand {
      margin-left: 15px; }
    .sc-container .rank-card .rank-header .rank-expand .ras-history {
      margin-left: 15px; }

.cp-dialog .cp-heading {
  background-color: #313941;
  color: #fff;
  font-size: calc(1.2rem + 1.55vmin);
  font-weight: 700; }

.cp-dialog .param-name {
  font-size: calc(0.6rem + 1vmin);
  font-weight: 500;
  margin-top: 20px; }

.cp-dialog .cp-value {
  font-size: calc(1.8rem + 1vmin);
  color: #fd6811;
  font-weight: 700;
  margin-bottom: 20px; }
  .cp-dialog .cp-value .ut-symbol {
    font-size: 16px; }

.animate-if {
  background: #fff; }

.animate-if.ng-enter, .animate-if.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s; }

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity: 0.3; }

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity: 1; }

[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer; }

.sp-dashboard-container {
  margin: 0; }
  .sp-dashboard-container md-toolbar {
    margin: 0; }
  .sp-dashboard-container .dashboard-nav {
    width: 100vw !important;
    display: block; }
  .sp-dashboard-container md-nav-bar .md-nav-bar {
    background-color: #3d4345; }
  .sp-dashboard-container md-nav-bar .md-button._md-nav-button.md-unselected {
    color: #fff !important; }
  .sp-dashboard-container .md-button.md-accent {
    color: #fff; }
  .sp-dashboard-container md-nav-bar md-nav-ink-bar {
    color: #17749A;
    background: #17749A; }
  .sp-dashboard-container .loading-shade {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 81vh; }
  .sp-dashboard-container .dewatering-container .odo-content .odo-card-param {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder;
    display: inline-block;
    letter-spacing: 0.5px; }
  .sp-dashboard-container .dewatering-container .odo-content .odo-card-value {
    font-size: calc(1.4rem + 3vh);
    text-align: center; }
    .sp-dashboard-container .dewatering-container .odo-content .odo-card-value .value-symbol {
      margin: 25px 7px 0 0;
      font-size: calc(0.45rem + 1.3vmin); }
  .sp-dashboard-container .dewatering-container .odo-content .odo-card-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder; }
  .sp-dashboard-container .dewatering-container .odo-content .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin); }
  .sp-dashboard-container .dewatering-container .od-0 {
    background-color: #313941; }
  .sp-dashboard-container .dewatering-container .od-1 {
    background-color: #CB7909; }
  .sp-dashboard-container .dewatering-container .od-2 {
    background-color: #313941; }
  .sp-dashboard-container .dewatering-container .od-3 {
    background-color: #CB7909; }
  .sp-dashboard-container .dewatering-container .od-4 {
    background-color: #313941; }
  .sp-dashboard-container .dewatering-container .dt-header {
    font-size: 18px;
    font-weight: 500;
    padding: 4px; }
  .sp-dashboard-container .dewatering-container .dw-advisor-chart md-card iframe {
    height: 35vh; }
  .sp-dashboard-container .dewatering-container .dw-advisor-chart .adv-chart-header {
    text-align: center;
    background-color: #313941;
    color: #fff; }
    .sp-dashboard-container .dewatering-container .dw-advisor-chart .adv-chart-header md-icon {
      color: #fff;
      font-size: 24px;
      margin: 0; }
    .sp-dashboard-container .dewatering-container .dw-advisor-chart .adv-chart-header span {
      font-size: calc(1.2rem + 1vh);
      margin: 0;
      letter-spacing: 0.2px;
      font-weight: 500;
      padding: 4px; }
  .sp-dashboard-container .management-container md-card {
    margin: 6px; }
  .sp-dashboard-container .management-container .odo-content .odo-card-param {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: 17.5795px;
    font-weight: bolder;
    letter-spacing: 0.5px; }
    .sp-dashboard-container .management-container .odo-content .odo-card-param md-icon {
      margin: 0;
      padding-right: 100px;
      color: white; }
    .sp-dashboard-container .management-container .odo-content .odo-card-param .adv-text {
      padding-right: 10px; }
  .sp-dashboard-container .management-container .odo-content .odo-card-value {
    font-size: 45.072px;
    text-align: center; }
    .sp-dashboard-container .management-container .odo-content .odo-card-value .value-symbol {
      margin: 25px 7px 0 0;
      font-size: calc(0.45rem + 1.3vmin); }
  .sp-dashboard-container .management-container .odo-content .odo-card-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: 14.6665px;
    font-weight: bolder; }
  .sp-dashboard-container .management-container .odo-content .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin); }
  .sp-dashboard-container .management-container .od-0 {
    background-color: #313941; }
  .sp-dashboard-container .management-container .od-1 {
    background-color: #CB7909; }
  .sp-dashboard-container .management-container .od-2 {
    background-color: #313941; }
  .sp-dashboard-container .management-container .od-3 {
    background-color: #CB7909; }
  .sp-dashboard-container .management-container .od-4 {
    background-color: #313941; }
  .sp-dashboard-container .management-container .dt-header {
    font-size: 18px;
    font-weight: 500;
    padding: 4px; }
  .sp-dashboard-container .management-container .sm-advisor-chart md-card iframe {
    height: 35vh; }
  .sp-dashboard-container .management-container .sm-advisor-chart .adv-chart-header {
    text-align: center;
    background-color: #313941;
    color: #fff;
    font-size: calc(0.35rem + 1.85vmin);
    padding: 4px;
    font-weight: 500; }
    .sp-dashboard-container .management-container .sm-advisor-chart .adv-chart-header md-icon {
      color: #fff;
      margin: 0; }
    .sp-dashboard-container .management-container .sm-advisor-chart .adv-chart-header span {
      margin: 0;
      letter-spacing: 0.2px;
      font-weight: 500; }
  .sp-dashboard-container .management-container .solids-planning-header .solids-planning-title {
    font-size: calc(0.35rem + 1.85vmin);
    color: #fff;
    font-weight: 500; }
  .sp-dashboard-container .management-container .solids-planning-header .solids-planning-icon md-icon {
    margin: 0;
    vertical-align: sub; }
  .sp-dashboard-container .management-container .solids-planning-header:focus {
    outline: none; }
  .sp-dashboard-container .management-container .sm-planning .live-ops-header {
    background-color: #313941;
    color: #fff;
    font-size: calc(0.35rem + 1.85vmin);
    padding: 4px;
    font-weight: 500; }
  .sp-dashboard-container .management-container .sm-planning .live-gauge-block {
    padding-top: 16px;
    background-image: linear-gradient(to right, #f4f5f8, #f7f7fa, #fafafb, #fcfcfd, #ffffff); }
  .sp-dashboard-container .incineration-container md-icon {
    margin: 0;
    color: #fff; }
  .sp-dashboard-container .incineration-container .field-content .field-param-header {
    background-color: #313941;
    color: #fff; }
  .sp-dashboard-container .incineration-container .field-content .info-icon {
    padding-left: 4px; }
  .sp-dashboard-container .incineration-container .field-content .field-unit-footer {
    background-color: #313941;
    color: #fff; }
  .sp-dashboard-container .incineration-container .field-content .field-card-param {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: 500;
    display: inline-block;
    letter-spacing: 0.5px; }
    .sp-dashboard-container .incineration-container .field-content .field-card-param .info-icon {
      float: right;
      font-size: 18px;
      margin-right: 8px; }
  .sp-dashboard-container .incineration-container .field-content .field-value {
    font-size: calc(1.4rem + 3vh);
    text-align: center; }
    .sp-dashboard-container .incineration-container .field-content .field-value .value-symbol {
      margin: 25px 7px 0 0;
      font-size: calc(0.45rem + 1.3vmin); }
  .sp-dashboard-container .incineration-container .field-content .field-card-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: 500; }
  .sp-dashboard-container .incineration-container .field-content .dry-unit {
    font-size: 18px;
    font-weight: 500; }
  .sp-dashboard-container .incineration-container .cm-content .cm-header {
    background-color: #313941;
    color: #fff;
    padding: 4px;
    font-weight: 500;
    font-size: calc(0.35rem + 1.85vmin); }
  .sp-dashboard-container .incineration-container .cm-content .in-block {
    padding-top: 12px;
    background-image: linear-gradient(to top, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada); }
    .sp-dashboard-container .incineration-container .cm-content .in-block .md-fab {
      width: 56px;
      height: 56px;
      line-height: 20px;
      background-color: green; }
      .sp-dashboard-container .incineration-container .cm-content .in-block .md-fab .in-val-unit {
        display: block; }
    .sp-dashboard-container .incineration-container .cm-content .in-block .on-color {
      background-color: green;
      color: white; }
    .sp-dashboard-container .incineration-container .cm-content .in-block .stand-by-color {
      background-color: #ff4f17;
      color: white; }
    .sp-dashboard-container .incineration-container .cm-content .in-block .off-color {
      background-color: grey; }
    .sp-dashboard-container .incineration-container .cm-content .in-block .progress {
      margin: 10px 30px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .sp-dashboard-container .incineration-container .cm-content .in-block .in-name {
      font-weight: 500;
      letter-spacing: 0;
      font-size: calc(0.35rem + 1.65vmin); }
    .sp-dashboard-container .incineration-container .cm-content .in-block .gas-status {
      background-color: #ffeb3b;
      color: black;
      font-size: 10px;
      font-weight: 500;
      -webkit-transition: width 1.5s ease;
      transition: width 1.5s ease; }
    .sp-dashboard-container .incineration-container .cm-content .in-block .energy-status {
      background-color: red;
      color: white;
      font-size: 10px;
      font-weight: 500;
      -webkit-transition: width 1.5s ease;
      transition: width 1.5s ease; }
  .sp-dashboard-container .incineration-container .cm-content .cm-legend {
    background-image: linear-gradient(to bottom, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada);
    padding: 8px; }
    .sp-dashboard-container .incineration-container .cm-content .cm-legend .gas-text {
      padding: 4px;
      font-weight: 500; }
      .sp-dashboard-container .incineration-container .cm-content .cm-legend .gas-text .gas-legend {
        height: 10px;
        width: 10px;
        background-color: #ffeb3b;
        display: inline-block;
        vertical-align: middle; }
      .sp-dashboard-container .incineration-container .cm-content .cm-legend .gas-text .energy-legend {
        height: 10px;
        width: 10px;
        background-color: red;
        display: inline-block;
        vertical-align: middle; }
      .sp-dashboard-container .incineration-container .cm-content .cm-legend .gas-text .green-legend {
        height: 10px;
        width: 10px;
        background-color: green;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle; }
      .sp-dashboard-container .incineration-container .cm-content .cm-legend .gas-text .yellow-legend {
        height: 10px;
        width: 10px;
        background-color: #ff4f17;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle; }
      .sp-dashboard-container .incineration-container .cm-content .cm-legend .gas-text .gray-legend {
        height: 10px;
        width: 10px;
        background-color: gray;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle; }
  .sp-dashboard-container .incineration-container .pm-content .pm-header {
    background-color: #313941;
    color: #fff;
    font-size: calc(0.35rem + 1.85vmin);
    padding: 4px;
    font-weight: 500; }
  .sp-dashboard-container .incineration-container .pm-content .in-block {
    padding: 0 0 1vh 0;
    background-image: linear-gradient(to top, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada); }
    .sp-dashboard-container .incineration-container .pm-content .in-block .in-name-block {
      padding: 1vh 0; }
      .sp-dashboard-container .incineration-container .pm-content .in-block .in-name-block .in-name {
        font-weight: 500;
        font-size: calc(0.35rem + 1.5vmin); }
      .sp-dashboard-container .incineration-container .pm-content .in-block .in-name-block .in-unit {
        font-weight: 400; }
    .sp-dashboard-container .incineration-container .pm-content .in-block ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      border: 1px black solid; }
      .sp-dashboard-container .incineration-container .pm-content .in-block ul li {
        float: left;
        display: block;
        color: white;
        background-color: black;
        border: 1px white solid;
        text-align: center;
        padding: 12px 12px;
        text-decoration: none; }
      .sp-dashboard-container .incineration-container .pm-content .in-block ul .violation-color {
        background-color: red;
        color: white; }
      .sp-dashboard-container .incineration-container .pm-content .in-block ul .warning-color {
        background-color: #fd6811;
        color: white; }
      .sp-dashboard-container .incineration-container .pm-content .in-block ul .compliant-color {
        background-color: green;
        color: white; }
      .sp-dashboard-container .incineration-container .pm-content .in-block ul .non-applicable-color {
        background-color: gray;
        color: white; }
  .sp-dashboard-container .incineration-container .pm-content .pm-legend {
    background-image: linear-gradient(to bottom, #f7f7f7, #f0f0f0, #e8e8e8, #e1e1e1, #dadada);
    padding: 8px; }
    .sp-dashboard-container .incineration-container .pm-content .pm-legend .violation-text {
      padding: 4px;
      font-weight: 500; }
      .sp-dashboard-container .incineration-container .pm-content .pm-legend .violation-text .violation-legend {
        height: 10px;
        width: 10px;
        background-color: red;
        display: inline-block;
        vertical-align: middle; }
    .sp-dashboard-container .incineration-container .pm-content .pm-legend .warning-text {
      padding: 4px;
      font-weight: 500; }
      .sp-dashboard-container .incineration-container .pm-content .pm-legend .warning-text .warning-legend {
        height: 10px;
        width: 10px;
        background-color: #fd6811;
        display: inline-block;
        vertical-align: middle; }
    .sp-dashboard-container .incineration-container .pm-content .pm-legend .compliant-text {
      padding: 4px;
      font-weight: 500; }
      .sp-dashboard-container .incineration-container .pm-content .pm-legend .compliant-text .compliant-legend {
        height: 10px;
        width: 10px;
        background-color: green;
        display: inline-block;
        vertical-align: middle; }
    .sp-dashboard-container .incineration-container .pm-content .pm-legend .non-app-text {
      padding: 4px;
      font-weight: 500; }
      .sp-dashboard-container .incineration-container .pm-content .pm-legend .non-app-text .non-app-legend {
        height: 10px;
        width: 10px;
        background-color: gray;
        display: inline-block;
        vertical-align: middle; }
  .sp-dashboard-container .incineration-container .ta-content .ta-header {
    background-color: #313941;
    color: #fff;
    font-size: calc(0.35rem + 1.85vmin);
    padding: 4px;
    font-weight: 500; }
    .sp-dashboard-container .incineration-container .ta-content .ta-header .toggle-icon {
      margin-left: auto;
      vertical-align: middle;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
    .sp-dashboard-container .incineration-container .ta-content .ta-header .toggled {
      transform: rotate(0deg) !important; }
    .sp-dashboard-container .incineration-container .ta-content .ta-header:focus {
      outline: none; }
  .sp-dashboard-container .incineration-container .ta-content table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
    background-color: #e6e5e5 !important; }
  .sp-dashboard-container .incineration-container .ta-content table.md-table tbody.md-body > tr.md-row > td {
    font-weight: 500; }
  .sp-dashboard-container .incineration-container .ta-content table.md-table:not(.md-row-select) td.md-cell:first-child,
  .sp-dashboard-container .incineration-container .ta-content td.md-cell:last-child {
    text-transform: capitalize; }
  .sp-dashboard-container .incineration-container .ta-content table.md-table th.md-column {
    color: #4f4f51;
    font-size: 16px;
    text-transform: uppercase; }

.frame-adv {
  background: url("https://d2a5hmbghmbgtd.cloudfront.net/webfonts/atlas_spinner.svg") center center no-repeat; }

.advisor-expand-dialog {
  background-image: linear-gradient(to bottom, #d1d2d5, #d9d6da, #e2dadc, #e9dfdd, #ece5de);
  max-width: fit-content;
  height: fit-content; }
  .advisor-expand-dialog .sadv-header {
    background-color: #313941;
    min-height: 50px; }
    .advisor-expand-dialog .sadv-header .icon-close {
      text-align: center; }
    .advisor-expand-dialog .sadv-header .icon-info {
      text-align: center; }
    .advisor-expand-dialog .sadv-header .adv-plan-title {
      font-size: calc(0.35rem + 2vmin);
      font-weight: 500;
      color: #fff; }
    .advisor-expand-dialog .sadv-header md-icon {
      color: #fff; }
  .advisor-expand-dialog .forecast-block {
    background-color: #4f4f51;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    margin: 8px; }
    .advisor-expand-dialog .forecast-block .forecast-name {
      font-size: calc(0.35rem + 1.4vmin);
      padding: 8px; }
    .advisor-expand-dialog .forecast-block .counter-span {
      font-size: calc(0.35rem + 1.4vmin);
      vertical-align: sub; }
    .advisor-expand-dialog .forecast-block .forecast-date {
      margin: 30px 5px 0 20px;
      font-size: 12px; }
  .advisor-expand-dialog .form-block {
    padding-top: 0; }
    .advisor-expand-dialog .form-block .plan-select {
      padding: 8px;
      margin: 0;
      background: #FF792B;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: white;
      font-weight: 600;
      font-size: calc(0.35rem + 1.4vmin);
      text-transform: uppercase; }
      .advisor-expand-dialog .form-block .plan-select .md-on {
        background-color: #fff; }
      .advisor-expand-dialog .form-block .plan-select md-radio-button.md-checked .md-off {
        border-color: #fff; }
      .advisor-expand-dialog .form-block .plan-select .md-ink-ripple {
        color: #fff; }
    .advisor-expand-dialog .form-block .adv-form {
      background-color: #fff;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px; }
      .advisor-expand-dialog .form-block .adv-form md-input-container label {
        color: #4f4f51; }
      .advisor-expand-dialog .form-block .adv-form md-input-container .md-input {
        color: #4f4f51;
        font-weight: 500;
        font-size: calc(0.35rem + 1.5vmin); }
      .advisor-expand-dialog .form-block .adv-form md-input-container:not(.md-input-invalid).md-input-focused label {
        color: #FF792B; }
      .advisor-expand-dialog .form-block .adv-form md-input-container:not(.md-input-invalid).md-input-focused .md-input {
        color: #4f4f51;
        font-weight: 500;
        font-size: calc(0.35rem + 2vmin);
        border-color: #FF792B; }
      .advisor-expand-dialog .form-block .adv-form md-checkbox.md-checked .md-icon {
        background-color: #fd6811; }
      .advisor-expand-dialog .form-block .adv-form md-select .md-select-value {
        text-align: center; }
      .advisor-expand-dialog .form-block .adv-form md-checkbox[disabled].md-checked .md-icon {
        background-color: #fd6811; }
      .advisor-expand-dialog .form-block .adv-form md-input-container.md-block {
        text-transform: capitalize; }
      .advisor-expand-dialog .form-block .adv-form md-checkbox {
        font-weight: 500;
        font-size: calc(0.35rem + 1.2vmin); }
      .advisor-expand-dialog .form-block .adv-form .form-footer .submit-button {
        background-color: #fd6811; }
      .advisor-expand-dialog .form-block .adv-form .form-footer .reset-form {
        background-color: #4f4f51; }
  .advisor-expand-dialog .adv-table .adv-table-header {
    padding: 4px;
    margin: 0;
    background: #313941;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: calc(0.35rem + 1.4vmin);
    text-transform: uppercase; }
  .advisor-expand-dialog .adv-table .table-card {
    background-color: white !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .advisor-expand-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row {
      height: 20px !important;
      text-transform: capitalize !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    .advisor-expand-dialog .adv-table .table-card table.md-table:not(.md-row-select)
th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px; }
    .advisor-expand-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      padding: 0 !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table th.md-column {
      font-weight: 600 !important;
      text-align: center !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table th.md-column {
      color: #4f4f51;
      font-size: 13px;
      text-transform: uppercase; }
    .advisor-expand-dialog .adv-table .table-card .md-row .md-column,
    .advisor-expand-dialog .adv-table .table-card .md-row .md-cell {
      text-align: center !important;
      padding: 5px !important;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700; }
    .advisor-expand-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row,
    .advisor-expand-dialog .adv-table .table-card table.md-table tfoot.md-foot > tr.md-row {
      height: 28px !important;
      text-transform: capitalize;
      text-align: left; }
    .advisor-expand-dialog .adv-table .table-card table.md-table th.md-column md-icon {
      font-size: 12px !important;
      min-width: 15px; }
    .advisor-expand-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5; }
    .advisor-expand-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row {
      height: 20px !important;
      text-transform: capitalize !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    .advisor-expand-dialog .adv-table .table-card table.md-table:not(.md-row-select)
th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px; }
    .advisor-expand-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      padding: 0 !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table th.md-column {
      font-weight: 600 !important;
      text-align: center !important; }
    .advisor-expand-dialog .adv-table .table-card table.md-table th.md-column {
      color: #4f4f51;
      font-size: 13px;
      text-transform: uppercase; }
    .advisor-expand-dialog .adv-table .table-card .md-row .md-column,
    .advisor-expand-dialog .adv-table .table-card .md-row .md-cell {
      text-align: center !important;
      padding: 5px !important;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700; }
    .advisor-expand-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row,
    .advisor-expand-dialog .adv-table .table-card table.md-table tfoot.md-foot > tr.md-row {
      height: 28px !important;
      text-transform: capitalize;
      text-align: left; }
    .advisor-expand-dialog .adv-table .table-card table.md-table th.md-column md-icon {
      font-size: 12px !important;
      min-width: 15px; }
    .advisor-expand-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5; }
    .advisor-expand-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row:last-child(odd) {
      background-color: #e6e5e5 !important; }
    .advisor-expand-dialog .adv-table .table-card .color-Infeasible {
      text-align: center;
      background-color: red !important;
      color: #fff;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700;
      padding-top: 5px; }
    .advisor-expand-dialog .adv-table .table-card .color-Optimal {
      text-align: center;
      background-color: green !important;
      color: #fff;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700;
      padding-top: 5px; }
  .advisor-expand-dialog .table-actions .table-hide {
    background-image: linear-gradient(to left bottom, #d2d0d0, #dddcdc, #e8e7e8, #f3f3f3, #ffffff); }
    .advisor-expand-dialog .table-actions .table-hide .toggle-icon {
      margin-left: auto;
      vertical-align: middle;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .advisor-expand-dialog .table-actions .apollo-accept {
    padding: 4px; }
  .advisor-expand-dialog .table-actions .sup-accept {
    padding: 4px; }
  .advisor-expand-dialog .table-actions .md-button {
    min-width: fit-content;
    min-height: fit-content;
    line-height: unset; }
  .advisor-expand-dialog md-icon {
    margin: 0; }
  .advisor-expand-dialog md-input-container {
    margin: 0; }

.accept-dialog {
  background-image: linear-gradient(to bottom, #09203f, #1b3454, #2d4a69, #3f607f, #537895);
  color: #fff; }
  .accept-dialog .accept-confirm-text {
    font-weight: 500;
    font-size: calc(0.35rem + 1.5vmin);
    padding: 20px 40px 30px 40px; }
  .accept-dialog .show-close:hover {
    background-color: #D41F22 !important;
    color: white; }
  .accept-dialog .okay-Apollo {
    background-color: #ff5722 !important;
    color: white; }
  .accept-dialog .okay-Supervisor {
    background-color: #283593 !important;
    color: white; }

.advisor-history-dialog {
  background-image: linear-gradient(to bottom, #d1d2d5, #d9d6da, #e2dadc, #e9dfdd, #ece5de);
  max-width: fit-content;
  height: fit-content; }
  .advisor-history-dialog .adv-history-header {
    min-width: 500px;
    background-color: #313941;
    min-height: 50px; }
    .advisor-history-dialog .adv-history-header .icon-close {
      text-align: center; }
    .advisor-history-dialog .adv-history-header .icon-info {
      text-align: center; }
    .advisor-history-dialog .adv-history-header .adv-plan-title {
      font-size: calc(0.35rem + 2vmin);
      font-weight: 500;
      color: #fff; }
      .advisor-history-dialog .adv-history-header .adv-plan-title .md-datepicker-triangle-button .md-datepicker-expand-triangle {
        border-top-color: #fff; }
      .advisor-history-dialog .adv-history-header .adv-plan-title .md-datepicker-input {
        color: #fff; }
    .advisor-history-dialog .adv-history-header .adv-plan-text {
      vertical-align: text-top; }
    .advisor-history-dialog .adv-history-header md-icon {
      color: #fff; }
  .advisor-history-dialog .forecast-block {
    background-color: #4f4f51;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    margin: 8px; }
    .advisor-history-dialog .forecast-block .forecast-name {
      font-size: calc(0.35rem + 1.4vmin);
      padding: 8px; }
    .advisor-history-dialog .forecast-block .counter-span {
      font-size: calc(0.35rem + 1.4vmin);
      vertical-align: sub; }
    .advisor-history-dialog .forecast-block .forecast-date {
      margin: 30px 5px 0 20px;
      font-size: 12px; }
  .advisor-history-dialog .plan-select {
    padding: 8px;
    margin: 0;
    background: #FF792B;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: calc(0.35rem + 1.4vmin);
    text-transform: uppercase; }
    .advisor-history-dialog .plan-select .md-on {
      background-color: #fff; }
    .advisor-history-dialog .plan-select md-radio-button.md-checked .md-off {
      border-color: #fff; }
    .advisor-history-dialog .plan-select .md-ink-ripple {
      color: #fff; }
  .advisor-history-dialog .adv-table .adv-table-header {
    padding: 4px;
    margin: 0;
    background: #313941;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: calc(0.35rem + 1.4vmin);
    text-transform: uppercase; }
  .advisor-history-dialog .adv-table .table-card {
    background-color: white !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .advisor-history-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row {
      height: 20px !important;
      text-transform: capitalize !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    .advisor-history-dialog .adv-table .table-card table.md-table:not(.md-row-select)
th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px; }
    .advisor-history-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      padding: 0 !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table th.md-column {
      font-weight: 600 !important;
      text-align: center !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table th.md-column {
      color: #4f4f51;
      font-size: 13px;
      text-transform: uppercase; }
    .advisor-history-dialog .adv-table .table-card .md-row .md-column,
    .advisor-history-dialog .adv-table .table-card .md-row .md-cell {
      text-align: center !important;
      padding: 5px !important;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700; }
    .advisor-history-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row,
    .advisor-history-dialog .adv-table .table-card table.md-table tfoot.md-foot > tr.md-row {
      height: 28px !important;
      text-transform: capitalize;
      text-align: left; }
    .advisor-history-dialog .adv-table .table-card table.md-table th.md-column md-icon {
      font-size: 12px !important;
      min-width: 15px; }
    .advisor-history-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5; }
    .advisor-history-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row {
      height: 20px !important;
      text-transform: capitalize !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    .advisor-history-dialog .adv-table .table-card table.md-table:not(.md-row-select)
th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px; }
    .advisor-history-dialog .adv-table .table-card table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      padding: 0 !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table th.md-column {
      font-weight: 600 !important;
      text-align: center !important; }
    .advisor-history-dialog .adv-table .table-card table.md-table th.md-column {
      color: #4f4f51;
      font-size: 13px;
      text-transform: uppercase; }
    .advisor-history-dialog .adv-table .table-card .md-row .md-column,
    .advisor-history-dialog .adv-table .table-card .md-row .md-cell {
      text-align: center !important;
      padding: 5px !important;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700; }
    .advisor-history-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row,
    .advisor-history-dialog .adv-table .table-card table.md-table tfoot.md-foot > tr.md-row {
      height: 28px !important;
      text-transform: capitalize;
      text-align: left; }
    .advisor-history-dialog .adv-table .table-card table.md-table th.md-column md-icon {
      font-size: 12px !important;
      min-width: 15px; }
    .advisor-history-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5; }
    .advisor-history-dialog .adv-table .table-card table.md-table tbody.md-body > tr.md-row:last-child(odd) {
      background-color: #e6e5e5 !important; }
    .advisor-history-dialog .adv-table .table-card .color-Infeasible {
      text-align: center;
      background-color: red !important;
      color: #fff;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700;
      padding-top: 5px; }
    .advisor-history-dialog .adv-table .table-card .color-Optimal {
      text-align: center;
      background-color: green !important;
      color: #fff;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700;
      padding-top: 5px; }
  .advisor-history-dialog .table-actions .table-hide {
    background-image: linear-gradient(to left bottom, #d2d0d0, #dddcdc, #e8e7e8, #f3f3f3, #ffffff); }
    .advisor-history-dialog .table-actions .table-hide .toggle-icon {
      margin-left: auto;
      vertical-align: middle;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .advisor-history-dialog .table-actions .apollo-accept {
    padding: 4px; }
  .advisor-history-dialog .table-actions .sup-accept {
    padding: 4px; }
  .advisor-history-dialog .table-actions .md-button {
    min-width: fit-content;
    min-height: fit-content;
    line-height: unset; }
  .advisor-history-dialog md-icon {
    margin: 0; }
  .advisor-history-dialog md-input-container {
    margin: 0; }
  .advisor-history-dialog .pagination-table {
    color: white !important;
    background-color: #404040 !important;
    font-size: 16px; }
    .advisor-history-dialog .pagination-table md-select.md-table-select > .md-select-value {
      margin: 0; }
    .advisor-history-dialog .pagination-table md-icon {
      margin: 0;
      color: white; }
    .advisor-history-dialog .pagination-table .md-button[disabled] md-icon {
      color: grey !important; }
  .advisor-history-dialog .no-plan {
    background-image: linear-gradient(to bottom, #cfdbfc, #c6d5f2, #becee8, #b7c8dd, #b0c1d3); }
    .advisor-history-dialog .no-plan .error-text {
      color: red; }
    .advisor-history-dialog .no-plan .select-text {
      color: #4f4f51;
      font-weight: 500;
      font-size: 16px; }
    .advisor-history-dialog .no-plan .plan-select {
      background-color: #313941; }
    .advisor-history-dialog .no-plan md-icon {
      margin-bottom: 4px; }

.active-show {
  transition: all 0.45s cubic-bezier(0.35, 0, 0.25, 1); }

.odo-red {
  text-shadow: 2px 2px 5px red; }

.odo-green {
  text-shadow: 2px 2px 5px green; }

.odo-black {
  text-shadow: 2px 2px 5px black; }

.chart-expand-dialog .chart-unit {
  font-size: 12px; }

.tt-container {
  display: block;
  overflow: scroll;
  min-height: 100vh; }
  .tt-container .hd-0,
  .tt-container .dd-0 {
    background-color: #17749A; }
  .tt-container .hd-1,
  .tt-container .dd-1 {
    background-color: #FF792B; }
  .tt-container .hd-2,
  .tt-container .dd-2 {
    background-color: #313941; }
  .tt-container .hd-3,
  .tt-container .dd-3 {
    background-color: #B04506; }
  .tt-container .hd-4 {
    background-color: #00B0B0; }
  .tt-container .hour-content .hour-card-param {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder;
    display: inline-block;
    letter-spacing: 0.5px; }
    .tt-container .hour-content .hour-card-param .info-icon {
      float: right;
      font-size: 18px;
      margin-right: 8px; }
  .tt-container .hour-content .hour-card-value {
    font-size: calc(1.4rem + 3vh);
    text-align: center; }
    .tt-container .hour-content .hour-card-value .value-symbol {
      margin: 25px 7px 0 0;
      font-size: calc(0.45rem + 1.3vmin); }
  .tt-container .hour-content .hour-card-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder; }
  .tt-container .hour-content .block-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.45vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder; }
  .tt-container .hour-content .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin); }
  .tt-container .second-content .hour-card-param {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder;
    display: inline-block;
    letter-spacing: 0.5px; }
  .tt-container .second-content .hour-card-unit {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.45vmin);
    font-weight: bolder; }
  .tt-container .second-content .hour-card-value {
    font-size: calc(1.4rem + 3vh);
    text-align: center;
    display: inline-block;
    margin-top: 8vh;
    margin-bottom: 10vh;
    text-align: center;
    font-size: calc(1.2rem + 4vh); }
  .tt-container .second-content .ct-card {
    text-align: center;
    color: white !important; }
    .tt-container .second-content .ct-card .ct-header {
      background-color: #17749A;
      color: white;
      font-size: 20px;
      font-weight: 500; }
    .tt-container .second-content .ct-card .ct-footer {
      background-color: #313941;
      color: white;
      font-size: 14px;
      font-weight: 500; }
    .tt-container .second-content .ct-card .ct-gauge {
      background-color: #313941; }
      .tt-container .second-content .ct-card .ct-gauge .ct-0 {
        padding-top: 20px; }
      .tt-container .second-content .ct-card .ct-gauge .permits-0 {
        padding-top: 20px; }
      .tt-container .second-content .ct-card .ct-gauge span {
        color: white !important; }
      .tt-container .second-content .ct-card .ct-gauge b {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: white !important; }
  .tt-container .second-content .dis-flow-prediction-container md-card iframe {
    height: 370px; }
  .tt-container .second-content .dis-flow-prediction-container .chart-label {
    text-align: center;
    background-color: #17749A;
    color: #fff; }
    .tt-container .second-content .dis-flow-prediction-container .chart-label md-icon {
      color: #fff;
      font-size: 24px;
      margin: 0; }
    .tt-container .second-content .dis-flow-prediction-container .chart-label span {
      font-size: calc(1.2rem + 1vh);
      margin: 0;
      letter-spacing: 1px;
      font-weight: 500;
      padding: 4px; }
  .tt-container .dl-content .dl-header {
    height: 40px;
    border-radius: 4px;
    background-color: #2D3133; }
    .tt-container .dl-content .dl-header .dl-title {
      font-size: calc(0.35rem + 1.85vmin);
      color: #fff;
      font-weight: 500; }
    .tt-container .dl-content .dl-header .dl-icon {
      text-align: right;
      display: block !important;
      color: #fff; }
      .tt-container .dl-content .dl-header .dl-icon .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
      .tt-container .dl-content .dl-header .dl-icon:focus {
        outline: none; }
  .tt-container .dl-content .dl-data .first-row {
    height: 60px;
    font-weight: 500;
    text-transform: capitalize; }
    .tt-container .dl-content .dl-data .first-row .date-message {
      background-image: linear-gradient(to right, #ac2f2f, #b62929, #c02222, #c9181a, #d20910);
      color: #fff; }
    .tt-container .dl-content .dl-data .first-row .date-msg-orange {
      background-image: linear-gradient(to left, #e63a04, #e14004, #dc4505, #d74a07, #d24e09);
      color: #fff; }
    .tt-container .dl-content .dl-data .first-row .date-msg-green {
      background-image: linear-gradient(to left, #346718, #367019, #39791b, #3a831c, #3c8c1e);
      color: #fff; }
  .tt-container .dl-content .dl-data .shift-text {
    margin-right: 10px; }
  .tt-container .multi-chart .dis-frame {
    height: 35vh; }
