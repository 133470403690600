.ptdashboard-container {
    .hour-content {
        .hour-card-param {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
            display: inline-block;
            letter-spacing: 0.5px;
            .info-icon{
                float: right;
                font-size: 18px;
                margin-right: 8px;
            }
        }
        .hour-card-value {
            font-size: calc(1.4rem + 3vh);
            // letter-spacing: 2px;
            text-align: center;
            .value-symbol{
                margin: 25px 7px 0 0;
                font-size: calc(0.45rem + 1.3vmin);
            }
        }
        .hour-card-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
        }
        .block-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.45vmin 0;
            font-size: calc(0.35rem + 1.45vmin);
            font-weight: bolder;
        }
        .unit-symbol{
            font-size: calc(0.45rem + 1.3vmin);
        }
    }    
    .day-content {

        md-toolbar{
            height:40px;
            border-radius:4px;
        }
        .day-forecast-header{
            .day-forecast-title{
                font-size: calc(0.35rem + 1.85vmin);
                color: $white;
                font-weight:500;
                // text-shadow: 2px 2px 5px Black;
            }
            .day-forecast-icon{
                text-align: right;
                display: block !important;
                .toggle-icon{
                    margin-left: auto;
                    vertical-align: middle;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                    -webkit-transition: -webkit-transform 0.6s ease-in-out;
                    transition: -webkit-transform 0.6s ease-in-out;
                    transition: transform 0.6s ease-in-out;
                    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
                    }
            }
            &:focus{
                outline: none;
            }            
        }
        .day-card-param {
            font-size: calc(0.35rem + 1.25vmin);
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-weight: bolder;
            letter-spacing: 0.3px;
        }
        .day-card-value {
            font-size: calc(1rem + 3vh); // letter-spacing: 2px;
            text-align: center;
        }
        .day-card-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.5vmin 0;
            font-size: calc(0.35rem + 1.25vmin);
            font-weight: bolder;
        }
        .unit-symbol{
            font-size: calc(0.45rem + 1.3vmin);
        }
        
        .block-unit {
            color: $white;
            text-align: center;
            margin: 0;
            padding: 0.45vmin 0;
            font-size: calc(0.35rem + 1.25vmin);
            font-weight: bolder;
        }
    }
    .pr-actual {
        md-card iframe {
            height: 44vh;
        }
        md-icon {
            float: left;
            color: white
        }
        .pr-flow-prediction-container {
            max-height: 970px;
            .pr-flow-prediction-heading {
                height: 40px;
                margin: 0;
                background-color: $light-blue;
                h2 {
                    text-align: center;
                    background-color: $light-blue;
                    color: white;
                    font-size: calc(1.2rem + 1vh);
                    margin: 0;
                    padding-top: 8px;
                    letter-spacing: 1px;
                }
                h4 {
                    text-align: center;
                    background-color: $light-blue;
                    color: white;
                    font-size: calc(0.35rem + 1.45vmin);
                    margin: 0;
                    letter-spacing: 0.5px;
                }
                .tp-info{
                    color: white;
                    text-align: right;
                    position: absolute;
                    right: 12px;
                    font-size: 18px;
                }
            }
            .chart-val {
                display: inline-block;
                margin-top: 17vh; 
                margin-bottom: 17vh;
                text-align: center;
                font-size: calc(1.2rem + 4vh);
                font-weight:500;
            }
        }
    }
    .hd-0,
    .dd-0 {
        
        background-color: $light-blue;
    }
    .hd-1,
    .dd-1 {
        background-color: $logo-primary;
    }
    .hd-2,
    .dd-2 {
        background-color: $darkblue;
    }
    .hd-3,
    .dd-3 {
        background-color: $logo-secondary;
    }
    .hd-4 {
        background-color: $logo-blue;
    }
}